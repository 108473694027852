import { axiosInstance } from "@/plugins/axiosPlugin";
import { useStore } from 'vuex';

export async function clearNotifications(type) {
    const store = useStore();

    await axiosInstance.post("notifications/clear", {
        userId: store.state.user.id,
        type: type,
    });
}
