import { createApp } from "vue";
import App from "./App.vue";

// Axios
import axiosPlugin from "./plugins/axiosPlugin";

// Store
import store from "./store";

// Router
import router from "./router";

// Utils
import { formatDate, convertSmileysToEmojis } from "./utils/formatters";

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "@mdi/font/css/materialdesignicons.css";
import { VTreeview } from "vuetify/labs/VTreeview";

// Locales
import { createVueI18nAdapter } from "vuetify/locale/adapters/vue-i18n";
import { createI18n, useI18n } from "vue-i18n";
import { en, sl } from "vuetify/locale";
import slovenian from "./locales/sl.json";
import english from "./locales/en.json";

// Vue scroll-to
import VueScrollTo from 'vue-scrollto';

const messages = {
    en: {
        $vuetify: {
            ...en,
        },
        ...english,
    },
    sl: {
        $vuetify: {
            ...sl,
        },
        ...slovenian,
    },
};

const i18n = createI18n({
    legacy: false,
    locale: "en",
    messages,
});

const vuetify = createVuetify({
    components: {
        ...components,
        VTreeview,
    },
    directives,
    locale: {
        adapter: createVueI18nAdapter({ i18n, useI18n }),
    },
});

const app = createApp(App);

app.config.globalProperties.$filters = {
    date: formatDate,
    smileys: convertSmileysToEmojis,
};

app.use(store);
app.use(router);
app.use(i18n);
app.use(vuetify);
app.use(axiosPlugin);
app.use(VueScrollTo, {
    duration: 800,
    easing: "ease",
});
app.mount("#app");
