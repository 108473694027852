<template>
    <v-list>
        <v-list-item v-for="item in props.category.items" :key="item.id">
            <template v-slot:default>
                <p class="faq-item-title">{{ item.title }}</p>
                <div class="faq-item-description" v-html="item.description"></div>
            </template>
        </v-list-item>
    </v-list>
</template>

<script setup>
import { defineProps } from 'vue';

const props = defineProps({
    category: {
        type: Object,
        required: true,
    },
});
</script>

<style lang="scss" scoped>
.faq-item-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
}

.faq-item-description {
    font-size: 16px;
    border-bottom: thin solid #dfdfdf;
    padding-bottom: 12px;
    margin-bottom: 20px;
}
</style>