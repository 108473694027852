<template>
    <div class="auth-layout">
        <div class="content">
            <main>
                <AuthHeader />
                <router-view />
                <AuthFooter />
            </main>
        </div>
    </div>
</template>

<script>
// @component { name: 'AuthLayout' }
import { useStore } from "vuex";
import AuthHeader from "./AuthHeader.vue";
import AuthFooter from "./AuthFooter.vue";

export default {
    components: {
        AuthHeader,
        AuthFooter,
    },
    setup() {
        const store = useStore();
        return { store };
    },
};
</script>

<style lang="scss">
    @import "@/styles/layout/auth/auth.scss";
</style>
