<template>

    <!-- Image -->
    <v-card class="library-asset" :class="{ 'image-asset': asset.volumeId === 3, 'sound-asset': asset.volumeId === 4 }">

        <!-- Remove button -->
        <div class="remove-button-wrapper" v-if="props.removable && checkboxSelected">
            <v-btn icon @click="checkboxSelected = false" class="remove-button" variant="flat" color="red">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>
        
        <!-- Toolbar -->
        <v-toolbar density="compact" color="#f6f6f6" v-if="enableEditing">
            <v-btn icon @click="editAsset = { ...asset }; showEditAsset = true;" v-if="user && (asset.uploader.id === user.id || user.userType === 'admin')">
                <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-checkbox density="compact" v-model="checkboxSelected" v-if="props.selectable" hide-details></v-checkbox>
        </v-toolbar>
        
        <!-- Image -->
        <div 
            v-if="asset.volumeId === 3"
            class="image-wrapper" 
            @click="openImage(asset)" 
            :class="{ 'opening': openingImage, 'cover': props.cover, 'unclickable': props.unclickable }"
        >
            <v-img
                :src="asset.url"
                :width="300"
                aspect-ratio="1/1"
                :class="{ 'cover-image': props.cover }"
            ></v-img>
        </div>

        <!-- Loading indicator -->
        <v-progress-linear
            v-if="openingImage"
            color="#24a8cb"
            height="6"
            indeterminate
            rounded
        ></v-progress-linear>

        <!-- Audio play button -->
        <div class="audio-play-button pt-4 pb-5">
            <v-btn 
                v-if="asset.volumeId === 4"
                class="sound-asset-icon" 
                variant="text" 
                @click="playSound(asset)"
                icon
            >
                <v-icon :icon="asset.playing ? 'mdi-pause' : 'mdi-play'"></v-icon>
            </v-btn>
        </div>

        <!-- Title and subtitle -->
        <p class="asset-title py-0 px-2 pb-2" v-if="(!hideTitle && !hideInfo) || asset.volumeId === 4">{{ asset.title }}</p>
        <p class="asset-alt pt-0 pb-2 mt-1 px-2 d-none" v-if="!hideInfo && !hideAlt">{{ asset.alt && asset.alt.length > 0 ? asset.alt : $t('pages.library.noDescription') }}</p>

        <!-- Audio timline -->
        <v-slider 
            v-if="asset.volumeId === 4"
            :min="0" 
            :max="100" 
            :model-value="asset.playTime" 
            :thumb-size="10" 
            :color="`#24a8cb`" 
            @update:modelValue="updatePlayTime(asset, $event)"
            hide-details
            class="px-2"
        >
            <template v-slot:append>
                <span class="sound-asset-slider-time">
                    {{ asset.audio && asset.audio.currentTime ? Math.floor(asset.audio.currentTime / 60) + ':' + ('0' + Math.floor(asset.audio.currentTime % 60)).slice(-2) : '0:00' }}
                </span>
            </template>
        </v-slider>

    </v-card>

    <!-- Display image dialog -->
    <v-dialog max-width="800" v-model="displayImage">
        <template v-slot:default>
            <v-card>

                <v-card-title>
                    <div class="d-flex align-center justify-space-between image-title-wrapper">
                        <span class="image-title ms-2">{{ openedImage.title }}</span>
                        <UserAvatar :user="openedImage.uploader" reverse></UserAvatar>
                    </div>
                </v-card-title>

                <v-card-text>
                    <v-img :src="openedImage.url" :width="800">
                        <template #placeholder>
                            <v-progress-circular
                                indeterminate
                                color="primary"
                            ></v-progress-circular>
                        </template>
                    </v-img>
                    <p v-if="openedImage.alt" class="mt-4 text-center">{{ openedImage.alt }}</p>

                    <div class="image-category-wrapper mt-5" v-if="openedImage.imageCategories && openedImage.imageCategories.length > 0">
                        <p class="mb-3">{{ $t('pages.library.imageCategories') }}</p>

                        <div class="image-categories">
                            <v-chip variant="tonal" color="primary" v-for="category in openedImage.imageCategories" :key="category.id" class="image-category">{{ category.title }}</v-chip>
                        </div>
                    </div>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-checkbox 
                        v-model="checkboxSelected" 
                        @click="displayImage = false"
                        :label="$t('actions.select')"
                        class="me-2"
                        style="position: relative; top: -1px;"
                        hide-details
                    ></v-checkbox>

                    <v-btn
                        :text="$t('actions.edit')"
                        variant="tonal"
                        @click="editAsset = { ...openedImage }; showEditAsset = true;"
                    ></v-btn>

                    <v-btn
                        :text="$t('actions.close')"
                        variant="flat"
                        color="primary"
                        @click="displayImage = false"
                    ></v-btn>
                </v-card-actions>
            </v-card>
        </template>
    </v-dialog>
        
    <!-- Update asset dialog -->
    <v-dialog max-width="600" v-model="showEditAsset">
        <template v-slot:default>
            <v-form ref="editForm" @submit.prevent="updateAsset(editAsset)" v-if="editAsset">
                <v-card>
                    <v-card-title>
                        {{ $t('pages.library.editAsset') }}
                    </v-card-title>
                    <v-card-text>

                        <div v-if="editAsset && editAsset.volumeId === 3" class="mb-5">
                            <v-img
                                :src="editAsset.url"
                                :width="160"
                            ></v-img>
                        </div>

                        <v-text-field
                            v-model="editAsset.title"
                            :label="$t('pages.library.upload.title')"
                            :rules="[v => !!v || $t('validation.required')]"
                            prepend-icon="mdi-text"
                            required
                        ></v-text-field>

                        <v-textarea
                            v-model="editAsset.alt"
                            :label="$t('pages.library.upload.description')"
                            prepend-icon="mdi-text"
                        ></v-textarea>

                        <v-select
                            v-if="editAsset.volumeId === 3"
                            v-model="editAsset.imageCategories"
                            :items="imageCategories"
                            item-value="id"
                            item-title="title"
                            :label="$t('pages.library.imageCategories')"
                            chips
                            multiple
                        ></v-select>

                        <v-select
                            v-if="editAsset.volumeId === 4"
                            v-model="editAsset.soundCategories"
                            :items="soundCategories"
                            item-value="id"
                            item-title="title"
                            :label="$t('pages.library.soundCategories')"
                            chips
                            multiple
                        ></v-select>

                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            @click="showEditAsset = false; editAsset=null;"
                            :disabled="updatingAsset"
                            variant="tonal"
                            class="me-2"
                        >{{ $t('actions.cancel') }}</v-btn>
                        <v-btn
                            color="red"
                            @click="deleteSingleAsset = editAsset;confirmingDeletion = true;"
                            v-if="user && (editAsset.uploader.id === user.id || user.userType === 'admin')"
                            class="me-2"
                            variant="flat"
                        >{{ $t('actions.delete') }}</v-btn>
                        <v-btn
                            color="primary"
                            :loading="updatingAsset"
                            :disabled="updatingAsset"
                            variant="flat"
                            type="submit"
                        >{{ $t('actions.update') }}</v-btn>
                    </v-card-actions>
                </v-card>

            </v-form>
        </template>
    </v-dialog>
 
    <!-- Confirm deletion dialog -->
    <v-dialog max-width="600" v-model="confirmingDeletion">
        <template v-slot:default>
            <v-card>
                <v-card-title>
                    {{ $t('pages.library.confirmDeletion') }}
                </v-card-title>
                <v-card-text>
                    <span v-if="!deleteSingleAsset && selectedCategoryGroup === 'imageGroups'">{{ $t('pages.library.confirmDeletionMessageImages') }}</span>
                    <span v-if="!deleteSingleAsset && selectedCategoryGroup === 'soundGroups'">{{ $t('pages.library.confirmDeletionMessageSounds') }}</span>
                    <span v-if="deleteSingleAsset && selectedCategoryGroup === 'imageGroups'">{{ $t('pages.library.confirmDeletionMessageSingleAssetImages') }}</span>
                    <span v-if="deleteSingleAsset && selectedCategoryGroup === 'soundGroups'">{{ $t('pages.library.confirmDeletionMessageSingleAssetSounds') }}</span>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="confirmingDeletion = false;" variant="flat" class="me-2">{{ $t('actions.cancel') }}</v-btn>
                    <v-btn @click="deleteAssets();" variant="flat" color="red">{{ $t('actions.delete') }}</v-btn>
                </v-card-actions>
            </v-card>
        </template>
    </v-dialog>

</template>


<script setup>
import { ref, defineProps, onBeforeUnmount, watch, computed, defineEmits } from 'vue';
import UserAvatar from '@/components/misc/UserAvatar.vue';
import { gql } from "graphql-request";
import { graphqlClient } from "@graphql/client";
import { useStore } from "vuex";
import { axiosInstance } from '@/plugins/axiosPlugin';

const siteId = computed(() => store.state.site.value);
const user = computed(() => store.state.user);
const store = useStore();
const editForm = ref(null);
const updatingAsset = ref(false);
const showEditAsset = ref(false);
const confirmingDeletion = ref(false);
const deletingAssets = ref(false);
const editAsset = ref(null);
const deleteSingleAsset = ref(false);

const props = defineProps({
    asset: {
        type: Object,
        required: true,
    },
    hideInfo: {
        type: Boolean,
        default: false,
    },
    hideUploader: {
        type: Boolean,
        default: false,
    },
    hideTitle: {
        type: Boolean,
        default: false,
    },
    hideAlt: {
        type: Boolean,
        default: false,
    },
    cover: {
        type: Boolean,
        default: false,
    },
    unclickable: {
        type: Boolean,
        default: false,
    },
    enableEditing: {
        type: Boolean,
        default: false,
    },
    selectable: {
        type: Boolean,
        default: false,
    },
    selectedAsset: {
        type: Boolean,
        default: false,
    },
    removable: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['selected', 'updated', 'playSound']);
const asset = ref(props.asset);
const openingImage = ref(false);
const displayImage = ref(false);
const openedImage = ref(null);
const selectedAsset = computed(() => props.selectedAsset);
const dontEmit = ref(false);
const checkboxSelected = ref(props.selectedAsset ? props.selectedAsset : false);
const imageCategories = ref([]);
const soundCategories = ref([]);


watch(selectedAsset, (newValue, oldValue) => {
    if (newValue !== oldValue) {
        dontEmit.value = true;
        checkboxSelected.value = newValue;
        setTimeout(() => {
            dontEmit.value = false;
        }, 50);
    }
}, { deep: true });

watch(checkboxSelected, (newValue, oldValue) => {
    if (newValue !== oldValue && !dontEmit.value) {
        emit('selected', asset.value.id);
    }
}, { deep: true });

function playSound(asset) {
    if (asset.playing) {
        asset.audio.pause();
        asset.playing = false;
        clearInterval(asset.intervalId);

    } else {

        if (!asset.audio) {
            asset.audio = new Audio(asset.url);
            asset.audio.addEventListener('timeupdate', () => {
                asset.playTime = (asset.audio.currentTime / asset.audio.duration) * 100;
            });
            asset.audio.addEventListener('ended', () => {
                asset.playing = false;
                asset.playTime = 0;
                clearInterval(asset.intervalId); // Clear the interval when ended
            });

            if (asset.playTime && asset.playTime > 0) {
                asset.audio.addEventListener('loadedmetadata', () => {
                    asset.audio.currentTime = (asset.playTime / 100) * asset.audio.duration;
                });
            }
        }

        asset.audio.play();
        asset.playing = true;
        asset.intervalId = setInterval(() => {
            asset.playTime = (asset.audio.currentTime / asset.audio.duration) * 100;
        }, 1000);

        emit('playSound', asset);
    }
}

function updatePlayTime(asset, newValue) {
    asset.playTime = newValue;

    if (asset.audio) {
        asset.audio.currentTime = (newValue / 100) * asset.audio.duration;
    }
}

async function openImage(asset) {
    if(openingImage.value || props.unclickable) {
        return;
    }

    openingImage.value = true;

    const query = gql`
        query GetImage($id: [QueryArgument]!, $siteId: [QueryArgument]!) {
            assets(id: $id, siteId: $siteId) {
                id
                title
                alt
                volumeId
                uploader {
                    id
                    fullName
                    ... on User {
                        id
                        photo {
                            url(transform: "customUserAvatar")
                        }
                    }
                    
                }
                ... on images_Asset {
                    id
                    imageCategories {
                        id
                        title
                    }
                    url(transform: "assetsLibraryFull")
                }
            }
        }
    `;

    const variables = {
        id: [asset.id],
        siteId: [siteId.value]
    };

    try {
        const response = await graphqlClient.request(query, variables);
        const { assets } = response;

        openedImage.value = assets[0];
        displayImage.value = true;

    } catch (error) {
        console.error("Error loading asset", error);
    } finally {
        openingImage.value = false;
    }
}

async function updateAsset() {
    if(!editForm.value.validate()) {
        return;
    }

    updatingAsset.value = true;

    try {
        const response = await axiosInstance.post("/assets/update", 
            {
                assetId: editAsset.value.id,
                title: editAsset.value.title,
                alt: editAsset.value.alt,
                categories: (editAsset.value.volumeId === 3 ? editAsset.value.imageCategories : editAsset.value.soundCategories),
                userId: user.value.id,
                siteId: siteId.value,                
            }
        );

        const { success } = response.data;

        if(success) {
            if(editAsset.value.volumeId === 3 && displayImage.value) {
                await openImage(editAsset.value);
            }

            emit('updated');

            showEditAsset.value = false;
            editAsset.value = null;
        }

    } catch (error) {
        console.error("Error updating asset", error);

    } finally {
        updatingAsset.value = false;
    }
}

async function deleteAssets() {
    deletingAssets.value = true;

    try {
        const response = await axiosInstance.post("/assets/delete", 
            {
                assets: [deleteSingleAsset.value.id]
            }
        );

        const { success } = response.data;

        if(success) {
            emit('updated');
            confirmingDeletion.value = false;
            showEditAsset.value = false;
            editAsset.value = null;
            deleteSingleAsset.value = null;
        }

    } catch (error) {
        console.error("Error deleting assets", error);

    } finally {
        deletingAssets.value = false;
    }
}

async function fetchImageCategories() {
    if(imageCategories.value.length > 0) {
        return;
    }

    const query = gql`
        query GetImageCategories($siteId: [QueryArgument]!) {
            categories(group: "imageGroups", siteId: $siteId) {
                ... on imageGroups_Category {
                    id
                    title
                }
            }
        }
    `;

    const variables = {
        siteId: [siteId.value]
    };

    try {
        const response = await graphqlClient.request(query, variables);
        const { categories } = response;
        imageCategories.value = categories;
    } catch (error) {
        console.error("Error fetching image categories", error);
    }
}

async function fetchSoundCategories() {
    if(soundCategories.value.length > 0) {
        return;
    }

    const query = gql`
        query GetSoundCategories($siteId: [QueryArgument]!) {
            categories(group: "soundGroups", siteId: $siteId) {
                ... on soundGroups_Category {
                    id
                    title
                }
            }
        }
    `;

    const variables = {
        siteId: [siteId.value]
    };

    try {
        const response = await graphqlClient.request(query, variables);
        const { categories } = response;
        soundCategories.value = categories;
    } catch (error) {
        console.error("Error fetching sound categories", error);
    }
}

watch(editAsset, () => {
    if(!editAsset.value) {
        return;
    }

    if(editAsset.value.volumeId === 3) {
        fetchImageCategories();
    } else {
        fetchSoundCategories();
    }
}, { deep: true });

onBeforeUnmount(() => {
    if (asset.value.audio && asset.value.playing) {
        asset.value.audio.pause();
        asset.value.playing = false;
        clearInterval(asset.value.intervalId);
    }
});

</script>


<style lang="scss">
    .image-title-wrapper {
        gap: 40px;

        .image-title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .image-categories {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }

    .library-asset {

        &:hover {

            .remove-button-wrapper {
                opacity: 1;

                button {
                    transform: translate(-50%,-50%) scale(1);
                }
            }
        }

        .remove-button-wrapper {
            opacity: 0;
            transition: opacity 0.15s ease-in-out;
            background-color: rgba(0,0,0,0.4);
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 10;

            button {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%) scale(0.7);
                transition: transform 0.15s ease-in-out;
            }
        }

        .asset-title {
            font-size: 13px;
            line-height: 1.2;
            opacity: 0.8;
        }

        .image-wrapper {
            aspect-ratio: 1 / 1;
            display: flex;
            align-items: center;

            &.cover {
                width: 100%;
                height: 100%;
                object-fit: cover;

                .cover-image,
                .cover-image img {
                    width: 100% !important;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &:not(.unclickable) {
                cursor: pointer;
            }

            &.opening {
                opacity: 0.5;
            }
        }

        .sound-asset-icon {
            opacity: 0.2;
            width: 90px;
            height: auto;
            aspect-ratio: 1 / 1;
            margin: 10px auto 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 60px;
        }

        .sound-asset-slider-time {
            display: inline-block;
            min-width: 30px;
            text-align: right;
        }
    }
</style>