<template>
    <div class="auth-wrapper">
        <div class="auth-form">
            <v-form ref="form" @submit.prevent="login" autocomplete="on" name="takatuka-admin-login">
                <h1>{{ $t("auth.login") }}</h1>

                <v-text-field
                    :label="$t('auth.yourEmail')"
                    placeholder="johndoe@gmail.com"
                    v-model="email"
                    :rules="emailRules"
                    type="email"
                    autocomplete="on"
                    name="email"
                    required
                ></v-text-field>

                <v-text-field
                    :label="$t('auth.yourPassword')"
                    v-model="password"
                    :rules="passwordRules"
                    type="password"
                    required
                    autocomplete="on"
                    name="password"
                ></v-text-field>

                <div class="form-actions">
                     <v-fade-transition mode="out-in">
                        <v-btn
                            :color="'primary'"
                            :text="$t('auth.login')"
                            class="text-none"
                            variant="flat"
                            type="submit"
                            :loading="formSubmitted"
                            :disabled="formSubmitted"
                            flat
                        ></v-btn>
                    </v-fade-transition>
                </div>

                <div class="extra-actions">
                    <p><router-link :to="{ name: 'UserForgotPassword' }" class="text-primary">{{ $t("auth.forgotPassword") }}</router-link></p>
                    <p class="mt-4">{{ $t("auth.dontHaveAccount") }} <br><router-link :to="{ name: 'UserRegister' }" class="text-primary">{{ $t("auth.signUp") }}</router-link></p>
                </div>
            </v-form>
        </div>

        <v-dialog v-model="dialog" max-width="500" :class="['login-failed-dialog']">
            <template v-slot:default>
                <v-card :title="$t('auth.loginFailed')">
                    <v-card-text>{{ $t("auth.loginFailedMessage") }}</v-card-text>

                    <v-card-actions>
                        <v-btn color="primary" @click="dialog = false">{{ $t("actions.close") }}</v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>

    </div>
</template>


<script setup>
// @component { name: 'UserLogin' }

import { useStore } from "vuex";
import { ref } from "vue";
import { useRouter } from "vue-router";

import { gql } from "graphql-request";
import { graphqlClient } from "@graphql/client";

const store = useStore();
const router = useRouter();

const email = ref("");
const password = ref("");
const form = ref(false);
const dialog = ref(false);
const formSubmitted = ref(false);

const emailRules = [
    (v) => !!v || "Email is required",
    (v) => /.+@.+\..+/.test(v) || "Email must be valid",
];

const passwordRules = [
    (v) => !!v || "Password is required",
];

async function login() {
     const { valid } = await form.value.validate();

    if (!valid || formSubmitted.value) {
        return;
    }

    formSubmitted.value = true;

    const query = gql`
        mutation Authenticate($email: String!, $password: String!) {
            authenticate(email: $email, password: $password) {
                jwt
                jwtExpiresAt
                refreshToken
                refreshTokenExpiresAt
                user {
                    id
                    fullName
                    preferences
                    photo {
                        id
                        url(transform: "customUserAvatar")
                    }
                    ... on User {
                        messagingAllowed
                        userType
                    }
                }
            }
        }
    `;

    const variables = {
        email: email.value,
        password: password.value,
    };

    try {
        const response = await graphqlClient.request(query, variables);
        const { jwt, user, refreshToken } = response.authenticate;

        store.dispatch("setUser", user);
        store.dispatch("setToken", jwt);
        store.dispatch("setRefreshToken", refreshToken);
        router.push("/dashboard");

    } catch (error) {
        console.error("Error logging in:", error);
        dialog.value = true;

    } finally {
        formSubmitted.value = false;
    }
}
</script>

<style lang="scss">
</style>