<template>

    <div class="generic-wrapper">

        <div class="generic-wrapper__header">
            <h1>{{ $t("pages.messages.title") }}</h1>
        </div>

        <div class="generic-wrapper__space columns-2">

            <div class="generic-wrapper__space__left">

                <!-- Progress -->
                <v-progress-linear
                    v-if="loading"
                    color="#24a8cb"
                    height="6"
                    indeterminate
                    rounded
                ></v-progress-linear>
                
                <!-- Inbox -->
                <v-card>

                    <v-card-title class="headline d-flex align-center">
                        {{ $t("pages.messages.allMessages") }}
                        <v-spacer></v-spacer>
                        <v-tooltip
                            v-if="searchQuery.length > 0"
                            :text="$t('actions.clearFilters')"
                        >
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    variant="flat"
                                    v-bind="props"
                                    @click="searchQuery = ''; loadMessages()"
                                    :disabled="loading"
                                >
                                    {{ $t("actions.clearFilters") }}
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-btn icon @click="searchDialog = true" :disabled="loading">
                            <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                        <v-btn icon @click="newMessageDialog = true;" :disabled="loading">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn icon @click="loadMessages" :disabled="loading">
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </v-card-title>


                    <v-list
                        class="py-0"
                        lines="three"
                        item-props
                        v-model="selectedMessage"
                    >
                        
                        <v-list-item
                            v-for="message in messages"
                            :key="message.id"
                            selectable="true"
                            :value="message"
                            @click="openMessage(message)"
                        >
                            <template v-slot:prepend>
                                <v-icon class="mr-4" :color="message.notificationForUser.find(user => user.id === userId) ? '#24a8cb' : 'default'" v-if="message.authors[0].id === userId" v-tooltip="$t('pages.messages.sentMessage')">mdi-email-fast-outline</v-icon>
                                <v-icon class="mr-4" :color="message.notificationForUser.find(user => user.id === userId) ? '#24a8cb' : 'default'" v-else v-tooltip="$t('pages.messages.receivedMessage')">mdi-email-arrow-left-outline</v-icon>

                                <v-avatar v-if="message.authors[0]">
                                    <v-img v-if="message.nonAuthorRecipients.length === 1" :src="message.authors[0].id === userId ? message.nonAuthorRecipients[0].photo.url: message.authors[0].photo.url"></v-img>
                                    <v-icon v-else>mdi-account-group</v-icon>
                                </v-avatar>
                            </template>

                            <template v-slot:title>
                                <div class="position-relative">
                                    <p class="d-flex align-center justify-space-between">
                                        <strong>{{ message.authors[0].fullName }}</strong> 
                                        <span class="text-muted">{{ $filters.date(message.dateCreated, "DD.MM.YYYY HH:mm") }}</span>
                                    </p>

                                    <p class="text-truncate message-inbox-title">{{ $filters.smileys(message.title) }}</p>

                                    <div class="multiple-recipients" v-if="message.nonAuthorRecipients.length > 1">
                                        <UserAvatar width="30px" :user="recipient" v-for="recipient in message.nonAuthorRecipients" :key="recipient.id" avatar-only />
                                    </div>
                                </div>
                            </template>
                        </v-list-item>

                    </v-list>

                    <!-- Pagination -->
                    <v-pagination
                        rounded="circle"
                        v-if="totalMessages > limit"
                        v-model="page"
                        :length="Math.ceil(totalMessages / limit)"
                        :total-visible="5"
                        class="my-4"
                    ></v-pagination>

                </v-card>
                
            </div>

            <div class="generic-wrapper__space__right">
                <MessageView v-if="openedMessage" :message="openedMessage" />
            </div>
        </div>

        <!-- New Message -->
        <NewPrivateMessage :show="newMessageDialog" @message-sent="loadMessages(); newMessageDialog = false;" @close="newMessageDialog = false;" />

        <!-- Search -->
        <v-dialog v-model="searchDialog" max-width="600" persistent>
            <v-form ref="searchForm" @submit.prevent="searchMessages">
                <v-card
                    prepend-icon="mdi-magnify"
                    :title="$t('pages.messages.searchMessages')"
                >
                    <v-card-text>
                        <v-row dense>
                            <v-col cols="12">
                                <v-text-field
                                    :label="$t('pages.messages.searchMessagesInput')"
                                    v-model="searchQuery"
                                    required
                                    autofocus
                                    :rules="[(v) => !!v || $t('pages.messages.searchMessagesInputRequired')]"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                            :text="$t('actions.close')"
                            variant="flat"
                            @click="searchForm.reset(); searchDialog = false; searchQuery = '';"
                        ></v-btn>

                        <v-btn
                            color="primary"
                            :text="$t('actions.search')"
                            variant="flat"
                            :loading="searching || loading"
                            :disabled="searching || loading"
                            type="submit"
                        ></v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>

    </div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from "vue";
import { gql } from "graphql-request";
import { graphqlClient } from "@graphql/client";
import { useStore } from "vuex";
import MessageView from "@/components/pages/messages/MessageView.vue";
import NewPrivateMessage from "@/components/modules/NewPrivateMessage.vue";
import UserAvatar from "@/components/misc/UserAvatar.vue";
import { axiosInstance } from '@/plugins/axiosPlugin';

const store = useStore();

const messages = ref([]);
const loading = ref(false);
const totalMessages = ref(0);
const limit = ref(10);
const page = ref(1);
const hasNextPage = ref(false);
const newMessageDialog = ref(false);
const hasPreviousPage = ref(false);
const userId = computed(() => store.state.user ? store.state.user.id : null);

const openedMessage = ref(null);
const selectedMessage = ref(null);
const searchDialog = ref(false);
const searchQuery = ref("");
const searching = ref(false);
const searchForm = ref(null);

watch(page, () => {
    loadMessages();
});

async function searchMessages() {
    searching.value = true;
    await loadMessages();
    searching.value = false;
    searchDialog.value = false;
}

async function openMessage(message) {
    openedMessage.value = message;
    selectedMessage.value = message;

    message.notificationForUser = message.notificationForUser.filter(user => user.id !== userId.value);

    try {
        await axiosInstance.post("/messages/mark-as-seen", {
            messageId: message.id,
            userId: userId.value,
        });
    } catch (error) {
        console.error("Error clearing new message notification", error);
    }
}

async function getEntryCount() {
    const query = gql`
        query GetEntryCount($messageRecipients: [QueryArgument]!, $searchQuery: String) {
            entryCount(section: "messages", status: "enabled", messageRecipients: $messageRecipients, siteId: ["*"], type: "userMessage", search: $searchQuery)
        }
    `;

    const variables = {
        messageRecipients: [userId.value],
        searchQuery: searchQuery.value,
    };

    const response = await graphqlClient.request(query, variables);
    return response.entryCount;
}

async function loadMessages() {
    loading.value = true;
    messages.value = [];
    totalMessages.value = 0;

    const totalMessagesCount = await getEntryCount();

    const query = gql`
        query GetPaginatedUserMessages($limit: Int!, $offset: Int!, $messageRecipients: [QueryArgument]!, $searchQuery: String) {
            entries(limit: $limit, offset: $offset, section: "messages", status: "enabled", messageRecipients: $messageRecipients, siteId: ["*"], unique: true, type: "userMessage", search: $searchQuery, orderBy: "dateCreated desc") {
                ... on userMessage_Entry {
                    id
                    title
                    description
                    dateCreated
                    notificationForUser {
                        id
                    }
                    messageRecipients {
                        ... on User {
                            id
                            email
                            fullName
                            photo {
                                url(transform: "customUserAvatar")
                            }
                        }
                    }
                    authors {
                        ... on User {
                            id
                            email
                            fullName
                            photo {
                                url(transform: "customUserAvatar")
                            }
                        }
                    }
                }
            }
        }
    `;

    const variables = {
        limit: limit.value,
        offset: (page.value - 1) * limit.value,
        messageRecipients: [userId.value],
        searchQuery: searchQuery.value,
    };

    try {
        const response = await graphqlClient.request(query, variables);
        response.entries.forEach(messageEntry => {
            messageEntry.nonAuthorRecipients = messageEntry.messageRecipients.filter(recipient => recipient.id !== userId.value);
        });

        messages.value = response.entries;
        totalMessages.value = totalMessagesCount;
        hasNextPage.value = (page.value * limit.value) < totalMessages.value;
        hasPreviousPage.value = page.value > 1;

        console.log('messages.value :>> ', messages.value);

    } catch (error) {
        console.error("Error loading messages", error);

    } finally {
        loading.value = false;
        clearNewMessageNotification();
    }
}

async function clearNewMessageNotification() {
    try {
        await axiosInstance.post("/notifications/clear", {
            type: "newMessage",
            userId: userId.value,
        });
    } catch (error) {
        console.error("Error clearing new message notification", error);
    }
}

onMounted(() => {
    loadMessages();
});

</script>


<style lang="scss" scoped>
.generic-wrapper .generic-wrapper__space.columns-2 {
    grid-template-columns: 500px 1fr;
}

.multiple-recipients {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 5px;
    flex-wrap: wrap;
}

p.text-truncate.message-inbox-title {
    max-width: 300px;
    font-size: 15px;
}
</style>