<template>
    <v-card v-if="message">
        <v-card-title class="headline">
            <div class="d-flex align-start justify-space-between px-2 pt-3 head-signature">
                <div class="message-meta">
                    <h3 class="my-2">{{ $filters.smileys(message.title) }}</h3>
                    <p class="mb-0 text-muted">
                        <span class="mr-2">{{ t('pages.messages.from') }} {{ message.authors[0].fullName }}, <a style="text-decoration: underline;" :href="`mailto:${message.authors[0].email}`">{{ message.authors[0].email }}</a></span><br>
                        <span>{{ $filters.date(message.dateCreated, "DD.MM.YYYY HH:mm") }}</span>
                    </p>
                </div>
                <div class="message-recipients" v-if="recipients">
                    <UserAvatar v-for="recipient in recipients" :key="recipient.id" :user="recipient" />
                </div>
            </div>
        </v-card-title>

        <v-divider class="mt-4 mb-1"></v-divider>

        <v-card-text>

            <v-progress-linear
                v-if="loading"
                color="#24a8cb"
                height="6"
                indeterminate
                rounded
            ></v-progress-linear>

            <div v-show="replies.length > 3 && !showFullHistory" class="mt-4 px-2">
                <v-btn
                    @click="showFullHistory = true"
                    class="text-none"
                    prepend-icon="mdi-chevron-right"
                    variant="text"
                    border
                >
                    {{ t("pages.messages.showFullHistory") }}
                </v-btn>
            </div>

            <!-- Replies -->
            <v-list
                v-if="replies.length"
                subheader
                two-line
            >
                <v-list-item
                    v-for="(reply, index) in replies"
                    :key="reply.id"
                    class="message-view-reply px-0 mx-0 w-100"
                    v-show="replies.length < 4 || showFullHistory || index >= replies.length - 3"
                    :class="{'recipient': reply.authors[0].id === userId}"
                >
                    <template v-slot:default>
                        <UserAvatar :user="reply.authors[0]" class="mb-4 pt-4" />
                        <div class="message-contents" v-html="$filters.smileys(reply.description2 ? reply.description2 : reply.description)"></div>
                        <p class="message-sent-date mt-4">{{ $filters.date(reply.dateCreated, "DD.MM.YYYY HH:mm") }}</p>
                        <v-divider class="mb-5 mt-4"></v-divider>
                    </template>
                </v-list-item>
            </v-list>


            <!-- Reply form -->
            <v-form ref="replyForm" class="px-2" @submit.prevent="sendReply">
                <p class="mt-5 mb-4 px-1">{{ t("pages.messages.replyToMessage") }}</p>
                <v-textarea v-model="newReply" :label="t('pages.messages.yourReply')" />
                <div class="d-flex justify-end align-center">
                    <v-btn type="submit" :disabled="!newReply || sendingReply" :loading="sendingReply" variant="flat" color="primary">
                        {{ t("actions.reply") }}
                    </v-btn>
                </div>
            </v-form>


            <!-- Reply success snackbar -->
            <v-snackbar
                :timeout="2000"
                color="success"
                variant="elevated"
                v-model="replySuccess"
            >
                {{ $t("pages.messages.replySuccess") }}
            </v-snackbar>

        </v-card-text>
    </v-card>
</template>

<script setup>
import { ref, computed, defineProps, watch, onMounted } from "vue";
import { gql } from "graphql-request";
import { graphqlClient } from "@graphql/client";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import UserAvatar from "@components/misc/UserAvatar.vue";
import { axiosInstance } from '@/plugins/axiosPlugin';

const props = defineProps({
    message: {
        type: Object,
        required: true,
    },
});

const { t } = useI18n();
const store = useStore();
const loading = ref(false);
const showFullHistory = ref(false);
const replies = ref([]);
const userId = computed(() => store.state.user ? store.state.user.id : null);
const siteId = computed(() => store.state.site.value);
const recipients = computed(() => {
    return props.message.messageRecipients.filter(recipient => recipient.id !== userId.value);
});

const newReply = ref("");
const replyForm = ref(null);
const sendingReply = ref(false);
const replySuccess = ref(false);

// Watch for changes to the message prop and reload replies
watch(() => props.message, () => {
    loadReplies();
}, { deep: true });

async function sendReply() {
    if (!replyForm.value.validate() || sendingReply.value) return;

    sendingReply.value = true;

    try {
        const response = await axiosInstance.post("/messages/reply", {
            userId: userId.value,
            messageId: props.message.id,
            reply: newReply.value,
            siteId: siteId.value
        });
        const { success } = response.data;

        if (success) {
            newReply.value = "";
            loadReplies();
            replyForm.value.reset();
            replySuccess.value = true;

            setTimeout(() => {
                replySuccess.value = false;
            }, 2000);
        }

    } catch (error) {
        console.error("Error sending reply", error);

    } finally {
        sendingReply.value = false;
    }
}

async function loadReplies() {
    if (loading.value) return;

    loading.value = true;
    replies.value = [];

    const query = gql`
        query GetUserMessageReplies($messageId: [QueryArgument]!, $messageRecipients: [QueryArgument]!) {
            entries(section: "messages", replyToMessage: $messageId, messageRecipients: $messageRecipients, siteId: ["*"], unique: true, type: "userMessageReply", orderBy: "dateCreated asc") {
                ... on userMessageReply_Entry {
                    id
                    title
                    description2
                    notificationForUser {
                        id
                    }
                    authors {
                        ... on User {
                            id
                            email
                            fullName
                            photo {
                                url(transform: "customUserAvatar")
                            }
                        }
                    }
                }
            }
        }
    `;

    const variables = {
        messageId: [props.message.id],
        messageRecipients: [userId.value],
    };

    try {
        const response = await graphqlClient.request(query, variables);
        replies.value = response.entries;
        replies.value.unshift(props.message);

        console.log('replies.value :>> ', replies.value);

    } catch (error) {
        console.error("Error loading replies", error);
    } finally {
        loading.value = false;
    }
}

onMounted(() => {
    loadReplies();
});
</script>


<style lang="scss">
.head-signature {
    
    .user-avatar-wrapper {
        flex-direction: row-reverse;
        align-items: center !important; 
        gap: 30px;        
    }
}

.message-recipients {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
}

.message-view-reply {
    display: flex;
    align-items: flex-start;
    gap: 10px;

    .user-avatar-wrapper {
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center !important; 
        margin-bottom: 5px !important;
    }
}

.message-contents {
    max-width: 80%;
}

.message-sent-date {
    opacity: 0.6;
}
</style>

