<template>
    <div class="wheel-edit-fields-wrapper">
        <h3 class="mb-5">{{ $t("pages.games.wheel.fields") }}</h3>

        <!-- Toolbar -->
        <v-toolbar density="compact" color="transparent" class="wheel-field-columns-toolbar" v-if="game.wheelFields.length > 0">
            <v-spacer></v-spacer>

            <v-btn icon @click="wheelNumberOfFieldColumns = 1" :class="{ 'active': wheelNumberOfFieldColumns === 1 }" color="#6C737A">
                <v-icon>mdi-list-box-outline</v-icon>
            </v-btn>

            <v-btn icon @click="wheelNumberOfFieldColumns = 2" :class="{ 'active': wheelNumberOfFieldColumns === 2 }" color="#6C737A">
                <v-icon>mdi-view-grid-outline</v-icon>
            </v-btn>

            <v-btn icon @click="wheelNumberOfFieldColumns = 3" :class="{ 'active': wheelNumberOfFieldColumns === 3 }" color="#6C737A">
                <v-icon>mdi-grid</v-icon>
            </v-btn>
        </v-toolbar>

        <!-- Fields -->
        <div class="wheel-edit-fields" :class="{ 'two-columns': wheelNumberOfFieldColumns === 2, 'three-columns': wheelNumberOfFieldColumns === 3 }">
            <div class="wheel-edit-field" v-for="(field, index) in game.wheelFields" :key="field.id">
                <div class="custom-label mt-0 mb-5 d-flex justify-space-between align-center">
                    <strong>{{ $t("pages.games.wheel.field") }} {{ index + 1 }}</strong>
                    <v-btn icon @click="removeField(index)" color="red" variant="tonal" :disabled="disabled">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>

                <!-- Color -->
                <div class="wheel-edit-field-color">
                    <div class="field-color">
                        <p class="custom-label mb-4">{{ $t("pages.games.wheel.fieldColor") }}</p>

                        <v-menu
                            class="color-swatch-menu"
                            v-model="menu[index]"
                            :close-on-content-click="false"
                            location="end"
                        >
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    variant="flat"
                                    :color="field.wheelColor ? field.wheelColor : '#FFFFFF'"
                                    :disabled="disabled"
                                    v-bind="props"
                                >
                                    <div class="color-preview" :style="{ backgroundColor: field.wheelColor ? field.wheelColor : '#FFFFFF' }"></div>
                                </v-btn>
                            </template>

                            <v-card min-width="300">
                                <v-list class="color-swatch-list">
                                    <v-list-item
                                        v-for="color in globalWheelColors" :key="color.id"
                                        :disabled="color.proUsersOnly && !(user && (user.userType === 'proUser' || user.userType === 'admin'))"
                                        :class="{ 'selected-color': field.wheelColor && field.wheelColor.trim() === color.color.trim(), 'add-border': color.color.trim() === '#ffffff' }"
                                    >
                                        <template v-slot:default>                                            
                                            <v-btn
                                                variant="flat"
                                                :color="color.color"
                                                @click="selectColor(field, color)"
                                            >
                                                <div class="color-preview" :style="{ backgroundColor: color.color }">
                                                    <v-icon v-if="color.proUsersOnly && !(user && (user.userType === 'proUser' || user.userType === 'admin'))">mdi-lock</v-icon>
                                                </div>
                                            </v-btn>
                                        </template>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                        </v-menu>

                    </div>
                    <div class="field-text-color">
                        <p class="custom-label">{{ $t("pages.games.wheel.textColor") }}</p>
                        <div class="switch-wrapper mt-4">
                            <p>{{ $t("pages.games.wheel.textColorWhite") }}</p>
                            <v-switch v-model="field.wheelColorOfWheelFonts" value="black" :disabled="disabled" color="#1A5B6A" class="ps-2" density="compact" hide-details>
                                <template #label>
                                    {{ $t("pages.games.wheel.textColorBlack") }}
                                </template>
                            </v-switch>
                        </div>
                    </div>
                </div>

                <v-spacer class="py-2" />

                <p class="custom-label">{{ $t("pages.games.wheel.textOnTheWheel") }}</p>
                <v-text-field bg-color="white" v-model="field.wheelTextOnTheWheel" :disabled="disabled" density="compact" variant="outlined" :rules="[v => !!v || $t('validation.required')]" :validate-on="'blur'" />

                <v-spacer class="py-2" />

                <p class="custom-label">{{ $t("pages.games.wheel.fieldTitle") }}</p>
                <v-text-field bg-color="white" v-model="field.title" :disabled="disabled" density="compact" variant="outlined" :rules="[v => !!v || $t('validation.required')]" :validate-on="'blur'" />

                <v-spacer class="py-2" />

                <p class="custom-label">{{ $t("pages.games.wheel.description") }}</p>
                <CKEditor v-model="field.description" :disabled="disabled" :key="game.siteId" />

                <v-spacer class="py-2" />

                <p class="custom-label">{{ $t("pages.games.wheel.solution") }}</p>
                <CKEditor v-model="field.solution" :disabled="disabled" :key="game.siteId" />

                <v-spacer class="py-3" />

                <v-switch v-model="field.wheelBlockFieldAfterHitting" :disabled="disabled" color="#1A5B6A" class="ps-2" density="compact" hide-details>
                    <template #label>
                        {{ $t("pages.games.wheel.blockFieldAfterHitting") }}
                    </template>
                </v-switch>

                <v-spacer class="py-1" />

                <v-switch v-model="field.wheelDontStopOnThisField" :disabled="disabled" color="#1A5B6A" class="ps-2" density="compact" hide-details>
                    <template #label>
                        {{ $t("pages.games.wheel.dontStopOnThisField") }}
                    </template>
                </v-switch>
            </div>
        </div>

        <v-spacer class="py-2" />

        <!-- Actions -->
        <div class="wheel-edit-actions mt-5 d-flex align-center justify-space-between">
            <p class="mb-0" v-if="game.wheelFields.length > 0">{{ $t("pages.games.wheel.numberOfFields") }}: {{ game.wheelFields.length }} / {{ wheelMaxNumberOfFields }}</p>
            <v-spacer v-else></v-spacer>
            <v-btn @click="addField" variant="tonal" color="#1A5B6A" :disabled="disabled || wheelMaxNumberOfFields <= game.wheelFields.length" prepend-icon="mdi-plus">{{ $t("pages.games.wheel.addField") }}</v-btn>
        </div>

        <v-spacer class="py-2" />

        <!-- Note -->
        <div class="meta-fields mt-5" v-if="user && proUserNumberOfFieldsNotShown === false && game.wheelFields.length > 0 && game.wheelFields.length < wheelMaxNumberOfFields && user.userType !== 'proUser' && user.userType !== 'admin'">
            
            <v-banner
                color="#1A5B6A "
                icon="mdi-help-circle-outline"
                lines="one"
                bg-color="#EFF5F6"
            >
                <v-banner-text>
                    {{ $t("pages.games.wheel.proUsersCanAddUpTo") }}
                </v-banner-text>

                <template v-slot:actions>
                    <v-btn @click="proUserNumberOfFieldsNotShown = true;">{{ $t("pages.games.wheel.upgradeNow") }}</v-btn>
                </template>
            </v-banner>
        </div>

    </div>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref, onMounted, watch } from "vue";
import CKEditor from "@/components/misc/CKEditor.vue";
import { gql } from "graphql-request";
import { graphqlClient } from "@graphql/client";
import { useStore } from "vuex";

const store = useStore();
const user = computed(() => store.state.user);
const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false
    }
});

const disabled = computed({
    get() {
        return props.disabled;
    }
});

const game = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit("update:modelValue", value);
    },
});

const wheelMaxNumberOfFields = ref(10);
const proUserNumberOfFieldsNotShown = ref(false);
const wheelNumberOfFieldColumns = computed({
    get() {
        return store.state.settings.wheelNumberOfFieldColumns || 1;
    },
    set(value) {
        store.dispatch('setWheelNumberOfFieldColumns', value);
    },
});

const menu = ref([]);
const globalWheelColors = ref([]);

watch(game, async () => {
    for(let field of game.value.wheelFields) {
        const index = game.value.wheelFields.findIndex(f => f.id === field.id);
        menu.value[index] = false;

        if(!field.wheelColor) {
            const color = await getFirstUnusedColor();
            field.wheelColor = color.color;
        }
    }
}, { deep: true });

async function getFirstUnusedColor() {
    await fetchColors();
    const availableColors = globalWheelColors.value.filter(color => {
        if (color.proUsersOnly && !(user.value && (user.value.userType === 'proUser' || user.value.userType === 'admin'))) {
            return false;
        }
        return !game.value.wheelFields.some(field => field.wheelColor && field.wheelColor.trim() === color.color.trim());
    });

    if (availableColors.length > 0) {
        return availableColors[0];
    }

    return globalWheelColors.value.find(color => !color.proUsersOnly);
}

async function fetchColors() {
    if(globalWheelColors.value.length > 0) return;

    const query = gql`
        query FetchWheelColors {
            globalSet(handle: "wheelColors") {
                ... on wheelColors_GlobalSet {
                    wheelColors(orderBy: "proUsersOnly asc") {
                        ... on color_Entry {
                            id
                            color
                            proUsersOnly
                        }
                    }
                }
            }
        }
    `;
    
    try {
        const response = await graphqlClient.request(query);
        const { wheelColors } = response.globalSet;
        globalWheelColors.value = wheelColors;

    } catch (error) {
        console.error(error);
    }
}

const selectColor = (field, color) => {
    if (color.proUsersOnly && !(user.value && (user.value.userType === 'proUser' || user.value.userType === 'admin'))) return;

    field.wheelColor = color.color; 

    const index = game.value.wheelFields.findIndex(f => f.id === field.id);
    menu.value[index] = false;
}

const addField = () => {
    game.value.wheelFields.push({
        title: "",
        description: "",
        solution: "",
        wheelTextOnTheWheel: "",
        wheelBlockFieldAfterHitting: true,
        wheelDontStopOnThisField: false,
        wheelColor: "",
        wheelColorOfWheelFonts: "black"
    });

    const index = game.value.wheelFields.length - 1;
    menu.value[index] = false;
}

const removeField = (index) => {
    game.value.wheelFields.splice(index, 1);
}

onMounted(() => {
    fetchColors();
});

</script>

<style lang="scss" scoped>
.wheel-edit-fields-wrapper {

    .wheel-edit-fields {
        display: grid;
        align-items: stretch;
        grid-template-columns: repeat(1, 1fr);
        gap: 32px;

        &.two-columns {
            grid-template-columns: repeat(2, 1fr);
        }

        &.three-columns {
            grid-template-columns: repeat(3, 1fr);
        }

        .wheel-edit-field {
            padding: 16px 24px 24px 24px;
            border-radius: 8px;
            background: #F3F4F5;

            .wheel-edit-field-color {
                display: flex;
                gap: 66px;
                align-items: flex-start;
            }

            .switch-wrapper {
                display: flex;
                gap: 16px;
                align-items: center;
            }
        }
    }
}

.wheel-field-columns-toolbar {

    .v-btn--icon.v-btn--size-default {
        border-radius: 0;
        border: 3px solid transparent;
        width: 35px;
        height: 35px;
        margin-right: 10px;

        &.active {
            border: 2px solid #6C737A;
            width: 35px;
            height: 35px;
        }
    }
}

.v-menu.color-swatch-menu {

    .v-overlay__content {
        padding: 0;
        box-shadow: none;
        border-radius: 8px;

        .v-card {
            box-shadow: none;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            border-radius: 8px;

            .color-swatch-list {
                display: grid;
                max-width: 500px;
                padding: 25px 28px !important;
                gap: 10px;
                grid-template-columns: repeat(10, 1fr);

                .v-list-item--density-default.v-list-item--one-line {
                    padding: 0 !important;

                    .v-btn.v-btn--density-default {
                        padding: 0;
                        min-width: 0;
                        display: flex;
                        border-radius: 8px;
                        overflow: hidden;
                        width: 38px;
                        height: 38px;
                        justify-content: center;
                        align-items: center;
                    }

                    &.add-border {

                        .v-btn.v-btn--density-default {
                            border: thin solid #C1C1C1;
                        }
                    }

                    &.selected-color {

                        .v-btn.v-btn--density-default {
                            border: 4px solid #1A5B6A;
                        }
                    }
                }
            }
        }
    }
}
</style>