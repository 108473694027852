<template>
    <div class="generic-wrapper">

        <div class="generic-wrapper__header">
            <h1 v-if="beingEdited"><span>{{ user.fullName }}</span></h1>
            <h1 v-else>{{ $t("pages.users.profileSettings.title") }}</h1>
        </div>

        <!-- Progress -->
        <v-progress-linear
            v-if="loading"
            color="#24a8cb"
            height="6"
            indeterminate
            rounded
        ></v-progress-linear>

        <div class="generic-wrapper__content">
            
             <v-form ref="form" @submit.prevent="saveChanges" name="takatuka-admin-update-user" v-if="user">

                <!-- Personal data -->
                <div class="auth-form-group">

                    <v-list-subheader>
                        {{ $t("auth.myInformation") }}
                    </v-list-subheader>
                    
                    <v-text-field
                        :label="$t('auth.firstName')"
                        :placeholder="$t('auth.john')"
                        :rules="firstNameRules"
                        v-model="firstName"
                        autocomplete="on" 
                        name="firstName"
                        type="text"
                    ></v-text-field>

                    <v-text-field
                        :label="$t('auth.lastName')"
                        :placeholder="$t('auth.doe')"
                        :rules="lastNameRules"
                        v-model="lastName"
                        autocomplete="on" 
                        name="lastName"
                        type="text"
                    ></v-text-field>

                    <v-text-field
                        :label="$t('auth.yourEmail')"
                        :placeholder="$t('auth.johnDoeEmail') + '@gmail.com'"
                        :disabled="!beingEdited"
                        :rules="emailRules"
                        v-model="email"
                        autocomplete="on" 
                        name="email"
                        type="email"
                    ></v-text-field>
                </div>

                <v-divider class="my-3"></v-divider>

                <!-- User group -->
                <div class="auth-form-group">

                    <v-list-subheader>
                        {{ $t("auth.userGroup") }}
                    </v-list-subheader>
                    
                    <v-select
                        :items="[
                            { text: $t('auth.admin'), value: 'admin' },
                            { text: $t('auth.proUser'), value: 'proUser' },
                            { text: $t('auth.user'), value: 'user' },
                        ]"
                        :disabled="!beingEdited"
                        :rules="[(v) => !!v || $t('auth.userGroupIsRequired')]"
                        v-model="userType"
                        item-title="text"
                        item-value="value"
                    ></v-select>
                </div>

                <v-divider class="my-3"></v-divider>

                <!-- Avatar -->
                <div class="auth-form-group" v-if="user">
                    
                    <v-list-subheader>
                        {{ $t("auth.avatar") }}
                    </v-list-subheader>

                    <p class="text-muted mb-1 mt-4" v-if="user.photo">{{ $t("pages.users.profileSettings.currentAvatar") }}</p>
                    <div class="avatar current-avatar" v-if="user.photo">
                        <img :src="user.photo.url" :alt="user.photo.alt">
                    </div>

                    <p class="text-muted mb-2 mt-5">{{ $t("auth.selectAvatar") }}</p>
                    <div class="list-avatars mb-5">
                        <div class="avatar" :class="{ 'selected': selectedAvatar && avatar.id === selectedAvatar.id }" v-for="avatar in avatars" :key="avatar.id">
                            <img :src="avatar.url" :alt="avatar.alt" @click="selectedAvatar = avatar; customAvatar = null">

                            <v-btn v-if="selectedAvatar && avatar.id === selectedAvatar.id" icon @click="selectedAvatar = null" size="small">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </div>
                    </div>

                    <v-file-input
                        :label="$t('auth.orUploadAvatar')"
                        :rules="[v => v.length === 0 || v[0].size < 10485760 || $t('validation.maxFileSize', { size: '10Mb' })]"
                        v-model="customAvatar"
                        prepend-icon="mdi-camera"
                        name="profileImage"
                        accept=".svg, .jpg, .png, .gif"
                        @change="selectedAvatar = null"
                        show-size
                    ></v-file-input>
                </div>

                <v-divider class="my-3"></v-divider>

                <!-- Organization and address -->
                <div class="auth-form-group mb-0">

                    <v-list-subheader>
                        {{ $t("auth.organization") }}
                    </v-list-subheader>

                    <v-text-field
                        :label="$t('auth.organizationName')"
                        :placeholder="$t('auth.yourOrganization')"
                        v-model="organizationName"
                        :rules="organizationNameRules"
                        autocomplete="on" 
                        type="text"
                        name="organizationName"
                        required
                    ></v-text-field>

                      <v-text-field
                        :label="$t('auth.yourPosition')"
                        :placeholder="$t('auth.yourPosition')"
                        v-model="position"
                        :rules="positionRules"
                        autocomplete="on" 
                        type="text"
                        name="position"
                        required
                    ></v-text-field>

                    <v-autocomplete
                        :label="$t('auth.country')"
                        :items="countries"
                        v-model="address.countryCode"
                        item-value="code"
                        item-title="name"
                        autocomplete="on" 
                        :rules="countryRules"
                        name="country"
                        required
                    ></v-autocomplete>

                    <v-autocomplete
                        v-if="address && address.countryCode === 'US'"
                        :label="$t('auth.state')"
                        :items="states"
                        v-model="address.administrativeArea"
                        item-value="code"
                        autocomplete="on"
                        item-title="name"
                        :rules="stateRules"
                        name="state"
                        required
                    ></v-autocomplete>

                    <v-text-field
                        :label="$t('auth.city')"
                        :placeholder="$t('auth.city')"
                        v-model="address.locality"
                        type="text"
                        autocomplete="on" 
                        :rules="cityRules"
                        name="city"
                        required
                    ></v-text-field>

                    <v-text-field
                        :label="$t('auth.address')"
                        :placeholder="$t('auth.address')"
                        v-model="address.addressLine1"
                        type="text"
                        autocomplete="on"
                        name="address"
                        :rules="addressRules"
                        required
                    ></v-text-field>

                    <v-text-field
                        :label="$t('auth.zipCode')"
                        :placeholder="$t('auth.zipCode')"
                        v-model="address.postalCode"
                        type="text"
                        autocomplete="on"
                        name="zipCode"
                        :rules="zipCodeRules"
                        required
                    ></v-text-field>
                </div>

                <v-divider class="my-3"></v-divider>

                <!-- Messaging -->
                <div class="legal">

                    <v-list-subheader>
                        {{ $t("pages.users.profileSettings.messaging") }}
                    </v-list-subheader>

                    <v-checkbox v-model="messagingAllowed" :rules="messagingAllowedRules" name="messagingAllowed">
                        <template v-slot:label>
                            <div>
                                {{ $t("pages.users.profileSettings.messagingAllowed") }}
                            </div>
                        </template>
                    </v-checkbox>
                </div>

                <v-divider class="my-3"></v-divider>

                <!-- Password reset -->
                <div class="auth-form-group">

                    <v-list-subheader>
                        {{ $t("auth.passwordReset") }}
                    </v-list-subheader>
                
                    <v-text-field
                        :label="$t('auth.yourCurrentPassword')"
                        :rules="passwordRules"
                        v-model="password"
                        autocomplete="off"
                        type="password"
                    ></v-text-field>

                    <v-text-field
                        :label="$t('auth.yourNewPassword')"
                        :rules="newPasswordRules"
                        v-model="newPassword"
                        autocomplete="off"
                        type="password"
                    ></v-text-field>

                    <v-text-field
                        :label="$t('auth.confirmPassword')"
                        :rules="confirmPasswordRules"
                        v-model="confirmPassword"
                        autocomplete="off"
                        type="password"
                    ></v-text-field>
                </div>

                <v-divider class="my-5"></v-divider>

                <!-- Form actions -->
                <div class="generic-wrapper__actions" v-if="loggedInUser">
                    <v-fade-transition mode="out-in">
                        <v-btn
                            color="red"
                            :text="$t('pages.users.profileSettings.deleteAccount')"
                            class="text-none"
                            variant="flat"
                            :disabled="formSubmitted"
                            @click="deleteAccountDialog = true"
                            v-if="loggedInUser && user.id !== loggedInUser.id"
                            flat
                        ></v-btn>
                    </v-fade-transition>

                    <v-fade-transition mode="out-in">
                        <v-btn
                            color="orange"
                            :text="$t('pages.users.profileSettings.deactivateAccount')"
                            class="text-none"
                            variant="flat"
                            :disabled="formSubmitted"
                            @click="deactivateAccountDialog = true"
                            v-if="loggedInUser && user.id !== loggedInUser.id && user.status === 'active'"
                            flat
                        ></v-btn>
                        <v-btn
                            color="green"
                            :text="$t('pages.users.profileSettings.activateAccount')"
                            class="text-none"
                            variant="flat"
                            :disabled="formSubmitted"
                            @click="activateAccount"
                            v-if="loggedInUser && user.id !== loggedInUser.id && user.status !== 'active'"
                            flat
                        ></v-btn>
                    </v-fade-transition>

                    <v-fade-transition mode="out-in">
                        <v-btn
                            :color="'primary'"
                            :text="$t('actions.saveChanges')"
                            class="text-none generic-save-button"
                            variant="flat"
                            type="submit"
                            :loading="formSubmitted"
                            :disabled="formSubmitted"
                            flat
                        ></v-btn>
                    </v-fade-transition>
                </div>
            </v-form>
        </div>
    </div>

    <!-- Delete confirmation dialog -->
    <v-dialog v-model="deleteAccountDialog" max-width="400" persistent>
        <v-card
            prepend-icon="mdi-delete"
            :title="$t('actions.confirmDelete')"
        >
            <template v-slot:text>
                <p>
                    {{ $t('pages.users.list.deleteConfirmation') }}
                    <br>
                    <strong style="margin-top: 10px;display: block;">{{ user.fullName }}</strong>
                </p>
            </template>

            <template v-slot:actions>
                <v-spacer></v-spacer>

                <v-btn @click="deleteAccountDialog = false" variant="flat" color="default">
                    {{ $t("actions.cancel") }}
                </v-btn>

                <v-btn @click="deleteAccount" variant="flat" color="red">
                    {{ $t("actions.delete") }}
                </v-btn>
            </template>
        </v-card>
    </v-dialog>

    <!-- Deactivate confirmation dialog -->
    <v-dialog v-model="deactivateAccountDialog" max-width="400" persistent>
        <v-card
            prepend-icon="mdi-account-off"
            :title="$t('pages.users.profileSettings.confirmDeactivate')"
        >
            <template v-slot:text>
                <p>
                    {{ $t('pages.users.profileSettings.deactivateAccountConfirmation') }}
                    <br>
                    <strong style="margin-top: 10px;display: block;">{{ user.fullName }}</strong>
                </p>
            </template>

            <template v-slot:actions>
                <v-spacer></v-spacer>

                <v-btn @click="deactivateAccountDialog = false" variant="flat" color="default">
                    {{ $t("actions.cancel") }}
                </v-btn>

                <v-btn @click="deactivateAccount" variant="flat" color="orange">
                    {{ $t("pages.users.profileSettings.deactivateAccount") }}
                </v-btn>
            </template>
        </v-card>
    </v-dialog>

    <!-- Snackbar -->
    <v-snackbar v-model="userDeletedSuccessfully" :timeout="2000" color="success" variant="elevated">
        {{ $t("pages.users.list.userDeletedSuccessfully") }}
    </v-snackbar>

    <v-snackbar v-model="userDeactivatedSuccessfully" :timeout="2000" color="success" variant="elevated">
        {{ $t("pages.users.profileSettings.accountDeactivated") }}
    </v-snackbar>

    <v-snackbar v-model="userActivatedSuccessfully" :timeout="2000" color="success" variant="elevated">
        {{ $t("pages.users.profileSettings.accountActivated") }}
    </v-snackbar>

    <v-snackbar :timeout="2000" color="success" variant="elevated" v-model="snackbar">
        {{ $t("pages.users.profileSettings.accountUpdated") }}
    </v-snackbar>
</template>

<script setup>
// @component { name: 'UserProfileSettings' }
import countries from '@/data/countries.json';
import states from '@/data/states.json';

import { axiosInstance } from '@/plugins/axiosPlugin';
import { useStore } from "vuex";
import { ref, computed, defineProps } from "vue";
import { gql } from "graphql-request";
import { graphqlClient } from "@graphql/client";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const { t } = useI18n();
const store = useStore();
const router = useRouter();

const form = ref(null);
const snackbar = ref(false);
const loading = ref(false);
const email = ref("");
const password = ref("");
const newPassword = ref("");
const confirmPassword = ref("");
const firstName = ref("");
const lastName = ref("");
const zipCode = ref("");
const userType = ref("");
const organizationName = ref("");
const position = ref("");
const country = ref("");
const state = ref("");
const city = ref("");
const dialog = ref(false);
const deleteAccountDialog = ref(false);
const deactivateAccountDialog = ref(false);
const userDeletedSuccessfully = ref(false);
const userDeactivatedSuccessfully = ref(false);
const userActivatedSuccessfully = ref(false);
const gdpr = ref(false);
const avatars = ref([]);
const customAvatar = ref(null);
const selectedAvatar = ref(null);
const requestErrors = ref([]);
const formSubmitted = ref(false);
const messagingAllowed = ref(false);
const address = ref({});
const user = ref({});

const props = defineProps({
    userId: {
        type: String,
        required: false,
    },
});

const beingEdited = computed(() => props.userId && props.userId !== (store.state.user ? store.state.user.id : null));

const loggedInUser = computed(() => store.state.user);

const userId = computed(() => props.userId || (loggedInUser.value ? loggedInUser.value.id : null));

const emailRules = [
    (v) => !!v || t("auth.emailIsRequired"),
    (v) => /.+@.+\..+/.test(v) || t("auth.emailMustBeValid"),
];

const passwordRules = [
];

const newPasswordRules = [
    (v) => !password.value || !!v || t("auth.passwordIsRequired"),
];

const confirmPasswordRules = [
    (v) => !password.value || !!v || t("auth.confirmPasswordIsRequired"),
    (v) => !password.value || v === newPassword.value || t("auth.passwordsMustMatch"),
];

const firstNameRules = [
    (v) => !!v || t("auth.firstNameIsRequired"),
];

const lastNameRules = [
    (v) => !!v || t("auth.lastNameIsRequired"),
];

const addressRules = [
];

const zipCodeRules = [
];

const positionRules = [
];

const organizationNameRules = [
];

const countryRules = [
];

const cityRules = [
];

const stateRules = [
];

const messagingAllowedRules = [
];

async function deleteAccount() {
    formSubmitted.value = true;

    try {
        const response = await axiosInstance.delete(`user/delete`, { data: { userId: user.value.id } });
        const { success, errors } = response.data;

        if (success) {
            userDeletedSuccessfully.value = true;
            
            setTimeout(() => {
                router.push({ name: 'UsersList' });
            }, 2000);

        } else {
            console.log(errors);
        }

    } catch (error) {
        console.log(error);

    } finally {
        deleteAccountDialog.value = false;
        formSubmitted.value = false;
    }
}

async function activateAccount() {
    formSubmitted.value = true;

    try {
        const response = await axiosInstance.post(`user/activate`, { userId: user.value.id });
        const { success, errors } = response.data;

        if (success) {
            userActivatedSuccessfully.value = true;
            user.value.status = 'active';

        } else {
            console.log(errors);
        }

    } catch (error) {
        console.log(error);

    } finally {
        formSubmitted.value = false;
    }
}

async function deactivateAccount() {
    formSubmitted.value = true;

    try {
        const response = await axiosInstance.post(`user/deactivate`, { userId: user.value.id });
        const { success, errors } = response.data;

        if (success) {
            userDeactivatedSuccessfully.value = true;
            user.value.status = 'inactive';

        } else {
            console.log(errors);
        }

    } catch (error) {
        console.log(error);

    } finally {
        deactivateAccountDialog.value = false;
        formSubmitted.value = false;
    }
}

async function saveChanges() {
    const { valid } = await form.value.validate();

    if (!valid || formSubmitted.value) {
        return;
    }

    loading.value = true;
    formSubmitted.value = true;
    
    try {
        const response = await axiosInstance.post('user/update', {
                siteId: store.state.site.value,
                user: {
                    userId: userId.value,
                    password: password.value,
                    newPassword: newPassword.value,
                    confirmPassword: confirmPassword.value,
                    firstName: firstName.value,
                    lastName: lastName.value,
                    email: email.value,
                    userType: userType.value,
                    organization: organizationName.value,
                    position: position.value,
                    country: country.value,
                    state: state.value,
                    city: city.value,
                    address: address.value,
                    zipCode: zipCode.value,
                    avatar: customAvatar.value,
                    avatarId: selectedAvatar.value ? selectedAvatar.value.id : (avatars.value.length > 0 ? avatars.value[0].id : null),
                    gdpr: gdpr.value,
                    messagingAllowed: messagingAllowed.value,
                }
            }, 
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
        const { success, errors } = response.data;

        if(!success) {
            requestErrors.value = typeof errors === 'string' ? [errors] : Object.values(errors).flat();
            dialog.value = true;
            return;
        }

        getUserData();

        snackbar.value = true;

    } catch (error) {
        console.error("Error registering:", error);
        dialog.value = true;

    } finally {
        formSubmitted.value = false;
        loading.value = false;
    }
}

async function getAvatars() {
    const query = gql`
        query Avatars {
            assets(volumeId: "1", predefinedAvatar: true) {
                ... on userAvatars_Asset {
                    id
                    url(transform: "customUserAvatar")
                    alt
                }
            }
        }
    `;

    const response = await graphqlClient.request(query);
    avatars.value = response.assets;
}

getAvatars();

async function getUserAddress() {
    const query = gql`
        query GetUserAddress($ownerId: [QueryArgument]!) {
            address(ownerId: $ownerId) {
                ... on Address {
                    id
                    addressLine1
                    administrativeArea
                    countryCode
                    locality
                    postalCode
                }
            }
        }
    `;

    const variables = {
        ownerId: userId.value,
    };

    const response = await graphqlClient.request(query, variables);
    
    if(!response.address) {
        address.value = {
            addressLine1: '',
            administrativeArea: '',
            countryCode: '',
            locality: '',
            postalCode: '',
        };
    } else {
        address.value = response.address;
    }
}

getUserAddress();

async function getUserData() {
    loading.value = true;

    const query = gql`
        query GetUserData($id: [QueryArgument]!) {
            user(id: $id) {
                ... on User {
                    id
                    email
                    firstName
                    lastName
                    fullName
                    messagingAllowed
                    status
                    userType
                    photo {
                        id
                        url(transform: "customUserAvatar")
                        alt
                    }
                    organization
                    position
                }
            }
        }
    `;

    const variables = {
        id: userId.value,
    };

    try {
        const response = await graphqlClient.request(query, variables);
        console.log('response.user :>> ', response.user);

        if(response.user) {
            user.value = response.user;
            email.value = response.user.email;
            firstName.value = response.user.firstName;
            lastName.value = response.user.lastName;
            userType.value = response.user.userType;
            organizationName.value = response.user.organization;
            position.value = response.user.position;
            selectedAvatar.value = response.user.photo;
            messagingAllowed.value = response.user.messagingAllowed;

            if(loggedInUser.value && response.user.id === loggedInUser.value.id) {
                store.dispatch('setUser', response.user);
            }
        }

    } catch (error) {
        console.error("Error fetching user:", error);
    } finally {
        loading.value = false;
    }
}

getUserData();

</script>

<style lang="scss" scoped>
@import "@/styles/pages/users/edit.scss";
</style>
