<template>
    <div class="library-module" :class="{ 'popup-library': props.popup }">

        <div class="library-module-wrapper" :class="{'single-column': props.selectedCategory }">

            <!-- Sidebar -->
            <div class="library-module-sidebar" v-show="!props.selectedCategory">

                <v-tabs
                    v-model="selectedCategoryGroup"
                    align-tabs="center"
                    bg-color="#144753"
                    stacked
                >
                    <v-tab
                        v-for="group in assetsGroups"
                        :key="group.handle"
                        :value="group.handle"
                        :disabled="loadingCategories"
                    >
                        <v-icon :icon="group.icon"></v-icon>
                        {{ $t('pages.library.groups.' + group.handle) }}
                    </v-tab>
                </v-tabs>


                <v-tabs-window v-model="selectedCategoryGroup">
                    <v-tabs-window-item
                        v-for="group in assetsGroups"
                        :value="group.handle"
                        :key="group.id"
                    >
                        <v-progress-linear
                            v-if="loadingCategories"
                            color="#24a8cb"
                            height="6"
                            indeterminate
                            rounded
                        ></v-progress-linear>

                        <v-treeview
                            v-model:selected="selectedCategories"
                            :select-strategy="'classic'"
                            :items="assetCategories"
                            color="white"
                            class="py-0"
                            bg-color="#144753"
                            item-value="id"
                            variant="tonal"
                            selectable
                        ></v-treeview>

                        <div class="d-flex mt-2 px-4 ms-5" v-if="selectedCategories.filter(id => id !== 0).length > 0">
                            <v-btn @click="selectedCategories = [0];" variant="text" color="white" class="ms-1" :disabled="loadingCategories || loadingAssets">
                                <v-icon class="me-4">mdi-close</v-icon>
                                <span>{{ $t('actions.clearSelection') }}</span>
                            </v-btn>
                        </div>

                    </v-tabs-window-item>
                </v-tabs-window>

                <!-- Selected assets -->
                <div class="selected-assets-wrapper mt-0" v-if="selectedAssets.length > 0">
                    <v-divider></v-divider>

                    <p class="title pt-4 mt-3">
                        {{ $t('pages.library.selected') }} ({{ selectedAssets.length }}<span v-if="props.selectedLimit">/{{ props.selectedLimit }}</span>)
                    </p>

                    <div class="selected-assets-inner-wrapper mb-5">
                        <LibraryAsset 
                            v-for="asset in selectedFullAssets" 
                            :asset="asset" 
                            hide-info 
                            unclickable 
                            :key="asset.id" 
                            cover 
                            removable 
                            :selected-asset="true" 
                            @selected="selectLibraryAsset" 
                        />
                    </div>

                    <v-progress-linear
                        v-if="loadingFullAssets"
                        color="blue-grey"
                        height="6"
                        indeterminate
                        rounded
                    ></v-progress-linear>

                    <div class="selected-assets-inner-wrapper mt-4 px-5 justify-center d-flex" v-if="selectedFullAssets.length > 0">
                        <v-btn @click="selectedAssets = []; selectedFullAssets = []; getAssets();" variant="text" color="white" class="ms-1" :disabled="loadingCategories || loadingAssets">
                            <v-icon class="me-4">mdi-close</v-icon>
                            <span>{{ $t('actions.clearSelection') }}</span>
                        </v-btn>
                    </div>
                </div>

            </div>

            <!-- Assets view -->
            <div class="library-module-content">
                <v-card flat>
                    <v-card-title>
                        <div class="d-flex align-center">

                            <!-- Search -->
                            <v-form ref="searchForm" @submit.prevent="getAssets()" class="w-100 d-flex align-center pe-5">
                                <v-text-field
                                    v-model="search"
                                    density="compact"
                                    :label="$t('actions.search')"
                                    prepend-inner-icon="mdi-magnify"
                                    variant="solo-filled"
                                    :disabled="loadingAssets || loadingCategories"
                                    :loading="searchingAssets"
                                    flat
                                    hide-details
                                    single-line
                                ></v-text-field>

                                <v-btn 
                                    class="ms-3"
                                    variant="tonal" 
                                    color="primary" 
                                    type="submit" 
                                    :loading="searchingAssets" 
                                    :disabled="loadingAssets || loadingCategories || search.length === 0"
                                >
                                    {{ $t('actions.search') }}
                                </v-btn>

                                <v-btn 
                                    class="ms-3"
                                    :class="{ 'opacity-0': !appliedSearch }"
                                    variant="tonal" 
                                    color="default"
                                    @click="search = ''; getAssets();"
                                    :loading="searchingAssets" 
                                    :disabled="loadingAssets || loadingCategories || !appliedSearch"
                                >
                                    {{ $t('actions.clearSearch') }}
                                </v-btn>
                            </v-form>

                            <v-spacer></v-spacer>

                            <v-tooltip :text="$t('actions.delete')">
                                <template v-slot:activator="{ props }">
                                    <v-btn 
                                        icon 
                                        variant="text"
                                        color="red"
                                        v-bind="props" 
                                        class="me-2"
                                        @click="deleteSingleAsset = false; confirmingDeletion = true;" 
                                        :disabled="loadingAssets || loadingCategories || showUploadNew || uploadingNewAsset || selectedAssets.length === 0 || deletingAssets" 
                                        :loading="deletingAssets"
                                        v-if="user && user.userType === 'admin'"
                                    >
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </template>
                            </v-tooltip>

                            <v-tooltip :text="$t('actions.refresh')">
                                <template v-slot:activator="{ props }">
                                    <v-btn 
                                        icon 
                                        v-bind="props" 
                                        class="me-2"
                                        @click="getAssets();" 
                                        :disabled="loadingAssets || loadingCategories || showUploadNew || uploadingNewAsset" 
                                    >
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                </template>
                            </v-tooltip>

                            <v-tooltip :text="$t('actions.upload')">
                                <template v-slot:activator="{ props }">
                                    <v-btn 
                                        icon 
                                        v-bind="props" 
                                        @click="showUploadNew = true;" 
                                        :disabled="loadingAssets || loadingCategories || uploadingNewAsset"
                                    >
                                        <v-icon>mdi-upload</v-icon>
                                    </v-btn>
                                </template>
                            </v-tooltip>

                            <v-tooltip :text="$t('actions.close')" v-if="props.popup">
                                <template v-slot:activator="{ props }">
                                    <v-btn 
                                        icon 
                                        v-bind="props" 
                                        @click="closeLibrary" 
                                    >
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </template>
                            </v-tooltip>

                        </div>

                        <v-divider class="mt-3 mb-4"></v-divider>

                        <v-progress-linear
                            v-if="loadingAssets"
                            color="#24a8cb"
                            height="6"
                            indeterminate
                            rounded
                        ></v-progress-linear>
                    </v-card-title>

                    <v-card-text>

                        <!-- Assets -->
                        <div class="assets-wrapper">
                            <div class="assets-inner-wrapper" v-if="!loadingAssets" :class="{ 'empty-state': loadedAssets.length === 0 }">
                                <div class="asset-item" v-for="asset in loadedAssets" :key="asset.id">
                                    <LibraryAsset 
                                        :asset="asset" 
                                        :selected-asset="computedSelectedAssets.includes(asset.id)" 
                                        @selected="selectLibraryAsset" 
                                        @updated="getAssets" 
                                        enable-editing 
                                        selectable 
                                        @playSound="playSound" 
                                        cover
                                    />
                                </div>
                            </div>

                            <!-- Empty state -->
                            <div class="empty-state" v-if="loadedAssets.length === 0 && !loadingAssets">
                                <v-empty-state
                                    icon="mdi-magnify"
                                    :text="$t('pages.library.emptyState.text')"
                                    :title="$t('pages.library.emptyState.title')"
                                    :disabled="loadingCategories || loadingAssets"
                                ></v-empty-state>
                            </div>
                        </div>

                        <!-- Pagination -->
                        <v-pagination
                            rounded="circle"
                            v-if="totalAssets > limit && !loadingAssets"
                            v-model="page"
                            :length="Math.ceil(totalAssets / limit)"
                            :total-visible="5"
                            class="mt-5 pt-2"
                        ></v-pagination>

                    </v-card-text>

                    <v-card-actions v-show="!props.autoselect">
                        <v-spacer></v-spacer>
                        <v-btn 
                            @click="confirmSelection"
                            v-if="selectedAssets.length > 0 && !props.unselectable"
                            :loading="confirmingSelection"
                            prepend-icon="mdi-check"
                            color="primary"
                            variant="flat"
                            class="pe-4"
                        >{{ $t('actions.select') }} ({{ selectedAssets.length }}<span v-if="props.selectedLimit">/{{ props.selectedLimit }}</span>)</v-btn>
                    </v-card-actions>
                </v-card>

            </div>

        </div>

        <!-- Upload new asset dialog -->
        <v-dialog max-width="600" v-model="showUploadNew">
            <template v-slot:default>
                <v-form ref="uploadForm" @submit.prevent="uploadNewAssets">
                    <v-card>
                        <v-card-title>
                            {{ $t('actions.upload') }}
                        </v-card-title>
                        <v-card-text>
                            <v-file-input
                                v-if="selectedCategoryGroup === 'imageGroups'"
                                v-model="newAssets.files"
                                :label="$t('pages.library.upload.selectImages')"
                                accept="image/*"
                                prepend-icon="mdi-image-multiple"
                                :rules="[v => !!v || $t('validation.required'), v => !v || Array.from(v).every(file => file.size < 10485760) || $t('validation.maxFileSize', { size: '10Mb' })]"
                                multiple
                            ></v-file-input>
                            <v-file-input
                                v-if="selectedCategoryGroup === 'soundGroups'"
                                v-model="newAssets.files"
                                :label="$t('pages.library.upload.selectSounds')"
                                accept="audio/*"
                                prepend-icon="mdi-volume-high"
                                :rules="[v => !!v || $t('validation.required'), v => !v || Array.from(v).every(file => file.size < 10485760) || $t('validation.maxFileSize', { size: '10Mb' })]"
                                multiple
                            ></v-file-input>

                            <div class="asset-item" v-for="(item, index) in newAssetsItems" :key="index"> 
                                <div class="new-image-wrapper px-5 mx-5 mb-4 pt-5">
                                    <v-img 
                                        :src="item.url"
                                        v-if="selectedCategoryGroup === 'imageGroups'"
                                        class="mb-2"
                                        max-width="160"
                                    ></v-img>
                                </div>
                                <v-text-field
                                    v-model="item.title"
                                    :label="$t('pages.library.upload.title')"
                                    prepend-icon="mdi-text"
                                    required
                                ></v-text-field>
                                <v-textarea
                                    v-model="item.alt"
                                    :label="$t('pages.library.upload.description')"
                                    prepend-icon="mdi-text"
                                ></v-textarea>

                                <v-divider class="my-5"></v-divider>
                            </div>

                            <v-select
                                v-if="newAssetsItems.length > 0 && selectedCategoryGroup === 'imageGroups'"
                                v-model="selectedCategoriesTree"
                                :items="imageCategories"
                                item-value="id"
                                item-title="title"
                                :label="$t('pages.library.imageCategories')"
                                chips
                                multiple
                            ></v-select>

                            <v-select
                                v-if="newAssetsItems.length > 0 && selectedCategoryGroup === 'soundGroups'"
                                v-model="selectedCategoriesTree"
                                :items="soundCategories"
                                item-value="id"
                                item-title="title"
                                :label="$t('pages.library.soundCategories')"
                                chips
                                multiple
                            ></v-select>

                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                @click="showUploadNew = false"
                                :disabled="uploadingNewAsset"
                                class="me-2"
                            >{{ $t('actions.cancel') }}</v-btn>
                            <v-btn
                                color="primary"
                                :loading="uploadingNewAsset"
                                :disabled="uploadingNewAsset"
                                variant="flat"
                                type="submit"
                            >{{ $t('actions.upload') }}</v-btn>
                        </v-card-actions>
                    </v-card>

                </v-form>
            </template>
        </v-dialog>

        <!-- Confirm deletion dialog -->
        <v-dialog max-width="600" v-model="confirmingDeletion">
            <template v-slot:default>
                <v-card>
                    <v-card-title>
                        {{ $t('pages.library.confirmDeletion') }}
                    </v-card-title>
                    <v-card-text>
                        <span v-if="!deleteSingleAsset && selectedCategoryGroup === 'imageGroups'">{{ $t('pages.library.confirmDeletionMessageImages') }}</span>
                        <span v-if="!deleteSingleAsset && selectedCategoryGroup === 'soundGroups'">{{ $t('pages.library.confirmDeletionMessageSounds') }}</span>
                        <span v-if="deleteSingleAsset && selectedCategoryGroup === 'imageGroups'">{{ $t('pages.library.confirmDeletionMessageSingleAssetImages') }}</span>
                        <span v-if="deleteSingleAsset && selectedCategoryGroup === 'soundGroups'">{{ $t('pages.library.confirmDeletionMessageSingleAssetSounds') }}</span>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="confirmingDeletion = false;" variant="flat" class="me-2">{{ $t('actions.cancel') }}</v-btn>
                        <v-btn @click="deleteAssets();" variant="flat" color="red">{{ $t('actions.delete') }}</v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>

    </div>
</template>

<script setup>
import { ref, watch, computed, getCurrentInstance, defineProps, onBeforeUnmount, onMounted } from "vue";
import { gql } from "graphql-request";
import { graphqlClient } from "@graphql/client";
import { useStore } from "vuex";
import { axiosInstance } from '@/plugins/axiosPlugin';
import { useI18n } from 'vue-i18n';
import LibraryAsset from '@/components/misc/LibraryAsset.vue';

const props = defineProps({
    imagesOnly: {
        type: Boolean,
        default: false
    },
    soundsOnly: {
        type: Boolean,
        default: false
    },
    selectedLimit: {
        type: Number,
        default: null
    },
    selectedCategory: {
        type: String,
        default: null
    },
    returnObjects: {
        type: Boolean,
        default: false
    },
    popup: {
        type: Boolean,
        default: false
    },
    unselectable: {
        type: Boolean,
        default: false
    },
    autoselect: {
        type: Boolean,
        default: false
    }
});

const store = useStore();
const { emit } = getCurrentInstance();
const { t } = useI18n();
const siteId = computed(() => store.state.site.value);
const user = computed(() => store.state.user);

var groups = [];

if (!props.soundsOnly) {
    groups.push({
        id: 3,
        handle: "imageGroups",
        title: "Images",
        icon: "mdi-image"
    });
}

if (!props.imagesOnly) {
    groups.push({
        id: 4,
        handle: "soundGroups",
        title: "Sounds",
        icon: "mdi-volume-high"
    });
}

const assetsGroups = ref(groups);
const selectedCategoryGroup = ref(assetsGroups.value[0].handle);
const loadingCategories = ref(false);
const loadingAssets = ref(false);
const appliedSearch = ref(false);
const assetCategories = ref([]);
const loadedAssets = ref([]);
const selectedAssets = ref([]);
const selectedFullAssets = ref([]);
const loadingFullAssets = ref(false);
const selectedCategories = ref([]);
const search = ref('');
const searchForm = ref(null);
const searchingAssets = ref(false);
const showUploadNew = ref(false);
const uploadingNewAsset = ref(false);
const confirmingSelection = ref(false);
const deleteSingleAsset = ref(false);
const limit = ref(12);
const page = ref(1);
const totalAssets = ref(0);
const hasNextPage = ref(false);
const hasPreviousPage = ref(false);
const uploadForm = ref(null);
const newAssets = ref({
    files: null
});
const newAssetsItems = ref([]);
const showEditAsset = ref(false);
const confirmingDeletion = ref(false);
const deletingAssets = ref(false);
const editAsset = ref(null);
const computedSelectedAssets = computed(() => {
    return [...selectedAssets.value];
});
const imageCategories = ref([]);
const soundCategories = ref([]);
const selectedCategoriesTree = ref([]);

watch(selectedCategories, (newVal, oldVal) => {
    if (newVal.length > 1 && newVal.includes(0)) {
        selectedCategories.value = selectedCategories.value.filter(id => id !== 0);
    }

    if (newVal.length === 0 || (newVal.includes(0) && !oldVal.includes(0))) {
        selectedCategories.value = [0];
    }

    selectedCategoriesTree.value = remapSelectedCategories();
});

const remapSelectedCategories = function() {
    if(selectedCategories.value.filter(id => id !== 0).length === 0) {
        return null;
    }

    var categories = [];

    if(selectedCategoryGroup.value === "imageGroups") {
        categories = imageCategories.value.filter(category => selectedCategories.value.includes(category.id));
        categories.forEach(category => {
            if (category.parent) {
                const parentCategory = imageCategories.value.find(parent => parent.id === category.parent.id);
                if (parentCategory && !categories.some(cat => cat.id === parentCategory.id)) {
                    categories.unshift(parentCategory);
                }
            }
        });

    } else if(selectedCategoryGroup.value === "soundGroups") {
        categories = soundCategories.value.filter(category => selectedCategories.value.includes(category.id));
        categories.forEach(category => {
            if (category.parent) {
                const parentCategory = soundCategories.value.find(parent => parent.id === category.parent.id);
                if (parentCategory && !categories.some(cat => cat.id === parentCategory.id)) {
                    categories.unshift(parentCategory);
                }
            }
        });
    }

    return categories.map(category => category.id);
}

watch(newAssets, (newValue) => {
    newAssetsItems.value = [];

    if(!newValue.files) {
        return;
    }

    for (let i = 0; i < newValue.files.length; i++) {
        newAssetsItems.value.push({
            file: newValue.files[i],
            url: URL.createObjectURL(newValue.files[i]),
            placeholder: newValue.files[i].name,
            title: "",
            alt: ""
        });

        if (!newValue.title && newValue.files && newValue.files.length > 0) {
            newValue.title = newValue.files[i].name;
        }
    }
}, { deep: true });

watch(selectedCategories, () => {
    if(!loadingCategories.value) {
        page.value = 1;
        getAssets();
    }
});

watch(selectedCategoryGroup, () => {
    page.value = 1;
    getCategories();
});

watch(page, (newValue, oldValue) => {
    if (newValue !== oldValue) {
        getAssets();
    }
});

watch(siteId, () => {
    getCategories();
});

watch(selectedAssets, () => {
    if(props.autoselect) {
        confirmSelection();
    }
}, { deep: true })

function clearFilters() {
    search.value = "";

    if(!props.selectedCategory) {
        selectedCategories.value = [];
    }
    
    selectedAssets.value = [];
    selectedFullAssets.value = [];
    page.value = 1;
    confirmingDeletion.value = false;
    showEditAsset.value = false;
    editAsset.value = null;
    deleteSingleAsset.value = null;
    getAssets();
}

function playSound(currentPlayingAsset) {
    loadedAssets.value.forEach(asset => {
        if (asset.id !== currentPlayingAsset.id && asset.audio && asset.playing) {
            asset.audio.pause();
            asset.playing = false;
            clearInterval(asset.intervalId);
        }
    });
}

async function selectLibraryAsset(assetId) {
    const index = selectedAssets.value.indexOf(assetId);
    
    if (index === -1) {
        if (props.selectedLimit && selectedAssets.value.length >= props.selectedLimit) {
            selectedAssets.value.shift();
        }
        selectedAssets.value.push(assetId);

        console.log('selectedAssets.value :>> ', selectedAssets.value);
        
    } else {
        selectedAssets.value.splice(index, 1);
    }

    if(selectedAssets.value.length > 0) {
        selectedFullAssets.value = await fetchSelectedAssets();
    }
}

async function deleteAssets() {
    deletingAssets.value = true;

    try {
        const response = await axiosInstance.post("/assets/delete", 
            {
                assets: deleteSingleAsset.value ? [deleteSingleAsset.value.id] : selectedAssets.value
            }
        );

        const { success } = response.data;

        if(success) {
            await getAssets();
            clearFilters();
        }

    } catch (error) {
        console.error("Error deleting assets", error);

    } finally {
        deletingAssets.value = false;
    }
}

async function fetchSelectedAssets() {
    loadingFullAssets.value = true;

    const query = gql`
        query GetAssets($id: [QueryArgument]!, $siteId: [QueryArgument]!) {
            assets(id: $id, siteId: $siteId) {
                id
                title
                alt
                volumeId
                uploader {
                    id
                    photo {
                        url(transform: "customUserAvatar")
                    }
                    fullName
                }
                ... on images_Asset {
                    id
                    imageCategories {
                        id
                        title
                    }
                    url(transform: "assetsLibraryFull")
                }
                ... on sounds_Asset {
                    id
                    soundCategories {
                        id
                        title
                    }
                    url
                }
            }
        }
    `;

    const variables = {
        id: selectedAssets.value,
        siteId: [siteId.value]
    };

    try {
        const response = await graphqlClient.request(query, variables);
        const { assets } = response;

        return assets;
    } catch (error) {
        console.error("Error fetching selected assets", error);

    } finally {
        loadingFullAssets.value = false;
    }
}

async function uploadNewAssets() {
    if(!uploadForm.value.validate()) {
        return;
    }

    uploadingNewAsset.value = true;

    try {
        const items = newAssetsItems.value.map((item, index) => ({
            index,
            file: item.file,
            title: item.title,
            alt: item.alt
        }));

        const response = await axiosInstance.post("/assets/upload", 
            {
                items,
                userId: user.value.id,
                siteId: siteId.value,
                volumeId: selectedCategoryGroup.value === "imageGroups" ? 3 : 4,
                categories: selectedCategoriesTree.value
            }, 
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );

        const { success } = response.data;

        if(success) {
            await getAssets();

            newAssets.value = {
                files: null
            };
            newAssetsItems.value = [];
            showUploadNew.value = false;
        }

    } catch (error) {
        console.error("Error uploading new assets", error);

    } finally {
        uploadingNewAsset.value = false;
    }
}

function closeLibrary() {
    emit('close');
}

async function confirmSelection() {
    confirmingSelection.value = true;

    if(props.returnObjects) {
        const selectedFullAssets = await fetchSelectedAssets();
        emit('selected', selectedFullAssets);

    } else {
        emit('selected', selectedAssets.value);
    }

    confirmingSelection.value = false;
}

async function getAssetsCount() {
    const query = gql`
        query GetAssetsCount($volumeId: [QueryArgument]!, $imageCategories: [QueryArgument], $soundCategories: [QueryArgument], $search: String) {
            assetCount(volumeId: $volumeId, imageCategories: $imageCategories, soundCategories: $soundCategories, siteId: "*", unique: true, search: $search)
        }
    `;

    const variables = {
        volumeId: [selectedCategoryGroup.value === "imageGroups" ? 3 : 4],
        imageCategories: selectedCategoryGroup.value === "imageGroups" ? remapSelectedCategories() : null,
        soundCategories: selectedCategoryGroup.value === "soundGroups" ? remapSelectedCategories() : null,
        search: search.value
    };

    const response = await graphqlClient.request(query, variables);
    return response.assetCount;
}

async function getAssets() {
    loadedAssets.value = [];
    loadingAssets.value = true;
    appliedSearch.value = search.value.length > 0;

    const totalAssetsCount = await getAssetsCount();

    const query = gql`
        query GetAssets($offset: Int, $limit: Int, $volumeId: [QueryArgument]!, $siteId: [QueryArgument]!, $imageCategories: [QueryArgument], $soundCategories: [QueryArgument], $search: String) {
            assets(offset: $offset, limit: $limit, volumeId: $volumeId, imageCategories: $imageCategories, soundCategories: $soundCategories, siteId: $siteId, unique: true, search: $search, orderBy: "dateCreated desc") {
                id
                title
                alt
                volumeId
                uploader {
                    id
                    fullName
                }
                ... on images_Asset {
                    id
                    imageCategories {
                        id
                        title
                    }
                    url(transform: "assetsLibraryThumbnail")
                }
                ... on sounds_Asset {
                    id
                    soundCategories {
                        id
                        title
                    }
                    url
                }
            }
        }
    `;

    const variables = {
        limit: limit.value,
        offset: (page.value - 1) * limit.value,
        volumeId: [selectedCategoryGroup.value === "imageGroups" ? 3 : 4],
        imageCategories: selectedCategoryGroup.value === "imageGroups" ? (props.selectedCategory ? assetCategories.value[assetCategories.value.length - 1].id : remapSelectedCategories()) : null,
        soundCategories: selectedCategoryGroup.value === "soundGroups" ? remapSelectedCategories() : null,
        search: search.value.length > 0 ? search.value : null,
        siteId: [siteId.value]
    };

    try {
        const response = await graphqlClient.request(query, variables);
        const { assets } = response;

        loadedAssets.value = selectedCategoryGroup.value === "imageGroups" ? assets : assets.map(asset => {
            return {
                ...asset,
                playTime: 0,
                playing: false
            }
        });

        totalAssets.value = totalAssetsCount;
        hasNextPage.value = (page.value * limit.value) < totalAssets.value;
        hasPreviousPage.value = page.value > 1;

    } catch (error) {
        console.error("Error loading assets", error);
    } finally {
        loadingAssets.value = false;
    }
}

async function getCategories() {
    loadingCategories.value = true;
    loadingAssets.value = true;
    selectedCategories.value = [];
    assetCategories.value = [];

    const query = gql`
        query GetCategories($siteId: [QueryArgument]!, $selectedCategoryGroup: [String]!, $title: [String]) {
            categories(group: $selectedCategoryGroup, orderBy: "dateCreated asc", level: 1, siteId: $siteId, title: $title) {
                groupHandle
                parent {
                    id
                }
                ... on imageGroups_Category {
                    id
                    title
                    children {
                        title
                        id
                    }
                }
                ... on soundGroups_Category {
                    id
                    title
                    children {
                        title
                        id
                    }
                }
            }
        }
    `;

    const variables = {
        siteId: [siteId.value],
        selectedCategoryGroup: [selectedCategoryGroup.value],
        title: props.selectedCategory ? props.selectedCategory : null
    };

    try {
        const response = await graphqlClient.request(query, variables);
        const { categories } = response;

        assetCategories.value = categories.map(category => {
            return {
                ...category,
                children: category.children.length > 0 ? category.children : null
            }
        });

        assetCategories.value.sort((a, b) => {
            if (a.children === null && b.children !== null) {
                return 1;
            } else if (a.children !== null && b.children === null) {
                return -1;
            } else {
                return 0;
            }
        });

        if (selectedCategoryGroup.value === "imageGroups") {
            assetCategories.value.unshift({
                groupHandle: selectedCategoryGroup.value,
                id: 0,
                title: t("pages.library.allImages"),
                children: null
            });

            selectedCategories.value = [0];

        } else {
            assetCategories.value.unshift({
                groupHandle: selectedCategoryGroup.value,
                id: 0,
                title: t("pages.library.allSounds"),
                children: null
            });
            selectedCategories.value = [0];
        }

        if(props.selectedCategory) {
            selectedCategories.value.push(assetCategories.value.find(category => category.title === props.selectedCategory).id);
        }

        getAssets();

    } catch (error) {
        console.error("Error loading categories", error);
    } finally {
        loadingCategories.value = false;
    }
}

async function fetchImageCategories() {
    if(imageCategories.value.length > 0) {
        return;
    }

    const query = gql`
        query GetImageCategories($siteId: [QueryArgument]!, $title: [String]) {
            categories(group: "imageGroups", siteId: $siteId, title: $title) {
                groupHandle
                parent {
                    id
                }
                ... on imageGroups_Category {
                    id
                    title
                }
            }
        }
    `;

    const variables = {
        siteId: [siteId.value],
        title: props.selectedCategory ? props.selectedCategory : null
    };

    try {
        const response = await graphqlClient.request(query, variables);
        const { categories } = response;
        imageCategories.value = categories;
    } catch (error) {
        console.error("Error fetching image categories", error);
    }
}

async function fetchSoundCategories() {
    if(soundCategories.value.length > 0) {
        return;
    }

    const query = gql`
        query GetSoundCategories($siteId: [QueryArgument]!, $title: [String]) {
            categories(group: "soundGroups", siteId: $siteId, title: $title) {
                groupHandle
                parent {
                    id
                }
                ... on soundGroups_Category {
                    id
                    title
                }
            }
        }
    `;

    const variables = {
        siteId: [siteId.value],
        title: props.selectedCategory ? props.selectedCategory : null
    };

    try {
        const response = await graphqlClient.request(query, variables);
        const { categories } = response;
        soundCategories.value = categories;
    } catch (error) {
        console.error("Error fetching sound categories", error);
    }
}

onBeforeUnmount(() => {
    for (const asset of loadedAssets.value) {
        if (asset.audio && asset.playing) {
            asset.audio.pause();
            asset.playing = false;
            clearInterval(asset.intervalId);
        }
    }
});

onMounted(() => {
    getCategories();
    fetchImageCategories();
    fetchSoundCategories();
});

</script>


<style lang="scss">
.library-module {

    &.popup-library {
        margin: 0 auto;
        max-width: 1600px;
        width: 100%;
    }

    .library-module-wrapper {
        background-color: white;
        display: grid;
        grid-template-columns: 300px 1fr;
        gap: 20px;
        width: 100%;
        max-width: 1900px;

        &.single-column {
            grid-template-columns: 1fr;
        }
    }

    .library-module-sidebar {
        background-color: #144753;

        .selected-assets-wrapper {
            padding: 15px;
            margin-top: 10px;

            .selected-assets-inner-wrapper {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 10px;

                > .v-card.image-asset {
                    aspect-ratio: 1/1;
                }
            }

            .title {
                font-size: 15px;
                line-height: 1.2;
                color: white;
                text-align: center;
                margin-bottom: 20px;
            }
        }
    }

    .library-module-content {
        min-height: 715px;
    }

    .assets-wrapper {
        min-height: 550px;
        position: relative;

        .empty-state {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -75%);
        }

        .assets-inner-wrapper {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
            max-height: 65dvh;
            overflow: auto;
            padding-right: 20px;
            padding-bottom: 20px;

            @media(min-width: 991px) {
                grid-template-columns: repeat(3, 1fr);
            }

            @media(min-width: 1250px) {
                grid-template-columns: repeat(4, 1fr);
            }

            @media(min-width: 1600px) {
                grid-template-columns: repeat(5, 1fr);
            }

            @media(min-width: 1920px) {
                grid-template-columns: repeat(6, 1fr);
            }

            &::-webkit-scrollbar {
                width: 8px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #a5dceb;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-track {
                background: transparent;
            }

            .asset-item {
                width: 100%;
            }
        }
    }
}
</style>