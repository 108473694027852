<template>

    <!-- Editor -->
    <div class="custom-ck-editor">
        <div v-if="editorReady" ref="editorRef"></div>
    </div>
    
    <!-- Library Module -->
    <v-dialog v-model="showLibrary" persistent>
        <LibraryModule 
            v-if="showLibrary" 
            @selected="insertPhoto" 
            @close="showLibrary = false" 
            return-objects 
            images-only
            popup
        />
    </v-dialog>

</template>

<script setup>
import { defineProps, defineEmits, computed, onMounted, onBeforeUnmount, ref, nextTick, watch } from "vue";
import { useStore } from "vuex";
import CustomImagePlugin from '@/plugins/customImagePlugin';
import LibraryModule from '@/components/modules/LibraryModule.vue';
import throttle from 'lodash.throttle';

import { 
    ClassicEditor, 
    Essentials, 
    Bold, 
    Italic, 
    Font, 
    Paragraph, 
    Image, 
    ImageResizeEditing, 
    ImageResizeHandles, 
    ImageResizeButtons, 
    ImageToolbar, 
    ImageCaption, 
    ImageStyle,
    Heading,
    Underline,
    Strikethrough,
    Alignment,
    List,
    Link
} from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';

import enTranslations from '@nodemodules/ckeditor5/dist/translations/en.js';
import slTranslations from '@nodemodules/ckeditor5/dist/translations/sl.js';

const store = useStore();
const site = computed(() => store.state.site);
const emit = defineEmits(["update:modelValue"]);
const props = defineProps({
    modelValue: {
        type: [String, null],
        required: true
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false
    }
});

const disabled = computed({
    get() {
        return props.disabled;
    }
});

const showLibrary = ref(false);
const modelValue = computed(() => props.modelValue);

const translationsMap = {
    en: enTranslations,
    sl: slTranslations,
};

const currentTranslations = computed(() => {
    return translationsMap[site.value.language] || enTranslations;
});

var editorInstance = null;
const editorRef = ref(null);
const editorReady = ref(false);

const initializeEditor = () => {
    if (editorInstance) {
        editorInstance.destroy()
            .then(() => {
                editorInstance.value = null;
                createEditor();
            })
            .catch(error => {
                console.error('Error destroying editor instance:', error);
                createEditor();
            });
    } else {
        createEditor();  
    }
};

const createEditor = async () => {
    ClassicEditor
        .create(editorRef.value, {
            language: site.value.language,
            translations: currentTranslations.value,
            plugins: [ 
                Essentials, 
                Bold, 
                Italic, 
                Font, 
                Paragraph, 
                CustomImagePlugin, 
                Image, 
                ImageToolbar, 
                ImageCaption, 
                ImageStyle, 
                ImageResizeEditing, 
                ImageResizeHandles, 
                ImageResizeButtons,
                Heading,
                Underline,
                Strikethrough,
                Alignment,
                List,
                Link
            ],
            link: {
                toolbar: [
                    'createLink', 'unlink', '|', 'linkDialog'
                ]
            },
            toolbar: {
                shouldNotGroupWhenFull: true,
                items: [
                    'heading', '|',
                    'bold', 'italic', 'underline', 'strikethrough', '|',
                    'alignment', '|',
                    'bulletedList', 'numberedList', '|',
                    'link', 'customImage'
                ]
            },
            heading: {
                options: [
                    { model: 'paragraph', title: 'Normal text', class: 'ck-heading_paragraph' },
                    { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                    { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                    { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' }
                ]
            },
            image: {
                toolbar: [
                    'imageTextAlternative', 'toggleImageCaption', '|',
                    'imageStyle:block', 'imageStyle:alignLeft', 'imageStyle:alignRight', 'imageStyle:inline',
                    '|', 'resizeImage'
                ],
                resizeOptions: [
                    {
                        name: 'resizeImage:original',
                        value: null,
                        label: 'Original',
                        icon: 'original'
                    },
                    {
                        name: 'resizeImage:25',
                        value: '25',
                        label: '25%',
                        icon: 'small'
                    },
                    {
                        name: 'resizeImage:50',
                        value: '50',
                        label: '50%',
                        icon: 'medium'
                    },
                    {
                        name: 'resizeImage:75',
                        value: '75',
                        label: '75%',
                        icon: 'large'
                    },
                    {
                        name: 'resizeImage:100',
                        value: '100',
                        label: '100%',
                        icon: 'original'
                    }
                ],
                resizeUnit: '%'
            }

        })
        .then(editor => {
            editorInstance = editor;

            const observer = new ResizeObserver(throttle(() => {
                editor.ui.update();
            }, 200));

            observer.observe(editor.ui.view.element);
            
            // Check if read-only
            if(disabled.value) {
                editor.enableReadOnlyMode('disabled');
            } else {
                editor.disableReadOnlyMode('disabled');
            }
            
            // Listen for changes in the editor's content
            editor.model.document.on('change:data', () => {
                const cleanedModelValue = cleanString(modelValue.value || '');
                const cleanedCurrentData = cleanString(editor.getData());

                if (cleanedModelValue !== cleanedCurrentData && !(cleanedModelValue === null && cleanedCurrentData === '')) { 
                    emit("update:modelValue", editor.getData());
                }
            });

            // Set initial value
            editor.setData(modelValue.value || "");

            // Listening to the event to open the image library
            editor.on('custom-image-library:open', () => {
                showLibrary.value = true;
            });
        })
        .catch(error => {
            console.error('Error creating editor instance:', error);
        });
};

const cleanString = (str = '') => {
    return str.replace(/&nbsp;/g, '') // Remove all &nbsp;
              .replace(/\s+/g, '');   // Remove all other whitespace (spaces, tabs, newlines)
}

watch(disabled, async () => {
    if (editorInstance) {
        if(disabled.value) {
            editorInstance.enableReadOnlyMode('disabled');
        } else {
            editorInstance.disableReadOnlyMode('disabled');
        }
    }
}, { deep: true });

const insertPhoto = (photos) => {
    if (editorInstance) {
        for (const photo of photos) {
            editorInstance.model.change(writer => {

                // Create the image element
                const imageElement = writer.createElement('imageBlock', {
                    src: photo.url,
                    alt: photo.title
                });

                // Add caption to the image
                const captionElement = writer.createElement('caption');
                writer.appendText(photo.alt, captionElement);
                writer.append(captionElement, imageElement);

                // Get the document root and set the insert position to the end
                const insertPosition = writer.createPositionAt(editorInstance.model.document.getRoot(), 'end');

                // Insert the image element at the end of the content
                writer.insert(imageElement, insertPosition);
            });
        }

        showLibrary.value = false;
    }
};

onMounted(async () => {
    editorReady.value = true;
    await nextTick();
    initializeEditor();
});

onBeforeUnmount(async () => {
    editorReady.value = false;
    await nextTick();
    if (editorInstance) {
        editorInstance.destroy();
        editorInstance = null;
    }
});

</script>

<style lang="scss">
.custom-ck-editor {

    .ck-content {

        ol,
        ul {
            padding-left: 25px;
        }
    }

    .ck.ck-editor .ck-editor__top .ck-sticky-panel .ck-toolbar {
        padding: 12px;

        .ck.ck-toolbar__separator {
            margin: 0 12px;
        }
    }

    .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content,
    .ck.ck-editor .ck-editor__top .ck-sticky-panel .ck-toolbar {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        .ck.ck-dropdown .ck-button.ck-dropdown__button {
            border: thin solid #C2C7CC;
            border-radius: 8px;
        }
    }

    .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable, 
    .ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        border-top: none;
    }

    .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
        padding: 0 15px;

        .ck.ck-editor__editable_inline > *:first-child {
            margin-top: 0 !important;
        }

        &.ck-focused {
            border-color: #ccced1 !important;
            box-shadow: none !important;
        }
    }
}
</style>