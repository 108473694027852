import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

import MainLayout from "@/components/layout/main/MainLayout.vue";
import AuthLayout from "@/components/layout/auth/AuthLayout.vue";
import AdminDashboard from "@/components/pages/AdminDashboard.vue";
import GamesList from "@/components/pages/games/GamesList.vue";
import GameEdit from "@/components/pages/games/GameEdit.vue";
import GameCreate from "@/components/pages/games/GameCreate.vue";
import UserLogin from "@/components/pages/auth/UserLogin.vue";
import UserDiscussion from "@/components/pages/discussion/UserDiscussion.vue";
import UserMessages from "@/components/pages/messages/UserMessages.vue";
import GameStatistics from "@/components/pages/statistics/GameStatistics.vue";
import AssetsLibrary from "@/components/pages/library/AssetsLibrary.vue";
import UsersList from "@/components/pages/users/UsersList.vue";
import UserProfileSettings from "@/components/pages/users/UserProfileSettings.vue";
import UserEdit from "@/components/pages/users/UserEdit.vue";
import UserProfile from "@/components/pages/users/UserProfile.vue";
import UserHelp from "@/components/pages/help/UserHelp.vue";
import UserForgotPassword from "@/components/pages/auth/UserForgotPassword.vue";
import UserRegister from "@/components/pages/auth/UserRegister.vue";

const routes = [
    {
        path: "/",
        component: MainLayout,
        children: [
            {
                path: "dashboard",
                name: "AdminDashboard",
                component: AdminDashboard,
            },
            {
                path: "discussion",
                name: "UserDiscussion",
                component: UserDiscussion,
            },
            {
                path: "messages",
                name: "UserMessages",
                component: UserMessages,
            },
            {
                path: "statistics",
                name: "GameStatistics",
                component: GameStatistics,
            },
            {
                path: "games",
                name: "GamesList",
                component: GamesList,
                props: () => ({ gameType: "myGames" }),
            },
            {
                path: "game/:id/edit",
                name: "GameEdit",
                component: GameEdit,
            },
            {
                path: "taka-tuka-games",
                name: "GamesListTakaTuka",
                component: GamesList,
                props: () => ({ gameType: "takatukaGames" }),
            },
            {
                path: "tat-games",
                name: "GamesListTAT",
                component: GamesList,
                props: () => ({ gameType: "tatGames" }),
            },
            {
                path: "create-a-new-game",
                name: "GameCreate",
                component: GameCreate,
            },
            {
                path: "assets-library",
                name: "AssetsLibrary",
                component: AssetsLibrary,
            },
            {
                path: "users",
                name: "UsersList",
                component: UsersList,
            },
            {
                path: "profile-settings",
                name: "UserProfileSettings",
                component: UserProfileSettings,
            },
            {
                path: "user/:id",
                name: "UserProfile",
                component: UserProfile,
            },
            {
                path: "users/:id/edit",
                name: "UserEdit",
                component: UserEdit,
                props: route => ({ userId: route.params.id }),
            },
            {
                path: "help",
                name: "UserHelp",
                component: UserHelp,
            },
        ],
        meta: { requiresAuth: true },
    },
    {
        path: "/auth",
        component: AuthLayout,
        children: [
            {
                path: "login",
                name: "UserLogin",
                component: UserLogin,
            },
            {
                path: "register",
                name: "UserRegister",
                component: UserRegister,
            },
            {
                path: "forgot-password",
                name: "UserForgotPassword",
                component: UserForgotPassword,
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const isAuthenticated = store.getters.isAuthenticated;

    if (requiresAuth && !isAuthenticated) {
        next({ name: 'UserLogin' });
    } else {
        next();
    }
});

export default router;
