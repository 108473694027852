<template>
    <div class="game-create">
        <h1>{{ $t('pages.games.create.title') }}</h1>

        <h2>{{ $t('pages.games.create.selectGameType') }}</h2>

        <div class="game-create__game-types">
            <div class="game-create__game-type" v-for="gameType in gameTypes" :key="gameType.id" @click="selectGameType(gameType)" :class="{ 'disabled': selectedGameType, 'selected': selectedGameType && gameType.id === selectedGameType.id }">
                <img :src="require(`@/assets/${gameType.image}`)" :alt="gameType.name">
                <p>{{ $t('pages.games.gameTypes.' + gameType.type) }}</p>

                <v-progress-linear
                    v-if="gameType.loading"
                    color="#24a8cb"
                    height="6"
                    indeterminate
                    rounded
                ></v-progress-linear>
            </div>
        </div>
    </div>
</template>

<script setup>
// @component { name: 'GameCreate' }
import { useStore } from "vuex";
import { onMounted, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { axiosInstance } from '@/plugins/axiosPlugin';

const { t } = useI18n();
const store = useStore();
const router = useRouter();
const selectedGameType = ref(null);
const user = computed(() => store.state.user);

const gameTypes = [
    { id: 1, image: 'svg/game-types/wheel.svg', type: 'wheel', loading: false },
    { id: 2, image: 'images/game-types/mystery-item.png', type: 'mysteryItem', loading: false },
    { id: 3, image: 'svg/game-types/shapes.svg', type: 'shapes', loading: false },
    { id: 4, image: 'svg/game-types/written-sources.svg', type: 'writtenSources', loading: false },
];

onMounted(() => {
    console.log('store.state.site :>> ', store.state.site);
});

const selectGameType = (gameType) => {
    console.log('gameType :>> ', gameType);
    
    if(selectedGameType.value) {
        return;
    }

    selectedGameType.value = gameType;

    createNewGame();
}

async function createNewGame() {
    selectedGameType.value.loading = true;

    try {
        const title = t('pages.games.create.newGameTitle');

        const response = await axiosInstance.post('games/create-new-game', {
            authorId: user.value ? user.value.id : null,
            gameType: selectedGameType.value.type,
            title: title
        });

        console.log('response :>> ', response);

        selectedGameType.value.loading = false;

        const gameId = response.data.game.id;
        router.push({ name: 'GameEdit', params: { id: gameId } });

    } catch (error) {
        console.error("Error creating new game:", error);
        
    } finally {
        selectedGameType.value = null;
    }    
}

</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

h1 {
    margin-bottom: 27px;
}

h2 {
    color: var(--Grayscale-900, #202224);

    /* Text/T2 M Medium */
    font-family: $font-seravek;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 27.2px */
}

.game-create__game-types {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin-top: 16px;

    @media(min-width: 991px) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media(min-width: 1600px) {
        grid-template-columns: repeat(6, 1fr);
    }

    .game-create__game-type {
        background-color: white;
        box-shadow: 0px var(--Depth-s, 2px) var(--Depth-m, 4px) 0px rgba(0, 0, 0, 0.08);
        border-radius: var(--Radius-xl, 16px);
        transition: background-color 0.1s linear;
        cursor: pointer;
        padding: 16px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 16px;

        &:hover,
        &.selected {
            background-color: var(--Turquoise-700, #1A5B6A);

            p {
                color: white;
            }
        }

        &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }

        img, p {
            display: block;
        }

        img {

        }

        p {
            width: 100%;
            text-align: center;
            transition: color 0.1s linear;
            font-weight: 600;
            font-size: 17px;
        }
    }
}
</style>
