<template>
    <header>

        <div class="language-selector">
            <v-select
                :label="$t('language')"
                :items="store.state.sites"
                :density="'compact'"
                v-model="store.state.site"
            >
                <template v-slot:item="{ props, item }">
                    <v-list-item v-bind="props" :subtitle="item.raw.full"></v-list-item>
                </template>
            </v-select>
        </div>

        <div class="logo-wrapper">
            <img :src="require('@/assets/images/logo.png')" alt="" />
        </div>
    </header>
</template>

<script>
// @component { name: 'AuthHeader' }
import { useStore } from "vuex";

export default {
    setup() {
        const store = useStore();

        return {
            store,
        };
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
    @import "@/styles/layout/auth/header.scss";
</style>

