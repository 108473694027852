<template>
    <div class="game-settings">
        <h3 class="py-2">Settings</h3>

        <v-divider class="my-2" />

        <v-switch
            v-model="game.enabled"
            :label="$t('pages.games.edit.active')"
            color="#1A5B6A"
            class="reverse-row"
            hide-details
            inset
            :disabled="disabled"
        ></v-switch>

        <v-divider class="my-2" />

        <v-switch
            v-model="game.publicGame"
            :label="$t('pages.games.edit.publiclyAvailable')"
            color="#1A5B6A"
            class="reverse-row"
            hide-details
            inset
            :disabled="disabled"
        ></v-switch>

        <v-divider class="my-2" />

        <div class="d-flex align-center justify-space-between pt-3 pb-1">
            <p>{{ $t('pages.games.edit.gameCategory') }}</p>
            <div class="category-list">
                <v-chip 
                    :color="game.gameCategory.some(cat => parseInt(cat.id) === parseInt(category.id)) ? '#1A5B6A' : '#e3e3e3'" 
                    v-for="category in gameCategories" 
                    :key="category.id"
                    :disabled="disabled"
                    variant="flat"
                    @click="toggleCategory(category)"
                >
                    {{ $t('pages.games.categories.' + category.gameTypeHandle) }}
                </v-chip>
            </div>
        </div>
    
        <div class="pt-3 pb-2" v-if="user && game.originalGameAuthor[0] && game.originalGameAuthor[0].id !== user.id">
            <v-divider class="mt-2 mb-4" />
            <div class="d-flex align-center justify-space-between">
                <p>{{ $t('pages.games.edit.originalGameAuthor') }}</p>
                <UserAvatar :user="game.originalGameAuthor[0]" reverse />
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, computed, emit, ref, onMounted } from 'vue';
import { gql } from "graphql-request";
import { graphqlClient } from "@graphql/client";
import UserAvatar from "@components/misc/UserAvatar.vue";
import { useStore } from "vuex";

const store = useStore();
const user = computed(() => store.state.user);

const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false
    }
});

const disabled = computed({
    get() {
        return props.disabled;
    }
});

const game = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit("update:modelValue", value);
    },
});

const gameCategories = ref([]);

const fetchGameCategories = async () => {
    const query = gql`
        query GetGameCategories {
            categories(group: "gameCategory") {
                ... on gameCategory_Category {
                    id
                    gameTypeHandle
                    title
                }
            }
        }
    `;

    try {
        const response = await graphqlClient.request(query);
        gameCategories.value = response.categories;
    } catch (error) {
        console.error(error);
    }
}

const toggleCategory = (category) => {
    if (game.value.gameCategory.some(cat => parseInt(cat.id) === parseInt(category.id))) {
        if (game.value.gameCategory.length > 1) {
            game.value.gameCategory = game.value.gameCategory.filter(cat => parseInt(cat.id) !== parseInt(category.id));
        }
    } else {
        game.value.gameCategory.push(category);
    }
}

onMounted(() => {
    fetchGameCategories();
});
</script>

<style lang="scss">
.game-settings {
    padding: 16px 24px;
    border-radius: var(--Radius-xl, 16px);
    border: var(--Stroke-Border, 1px) solid var(--Grayscale-100, #DADDE0);
    background: var(--Grayscale-0, #FFF);

    .category-list {
        gap: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}
</style>