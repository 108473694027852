<template>
    <div class="main-layout">
        <div class="content">
            <MainSidebar />
            <main>
                <MainHeader />
                <div class="main-view-wrapper">
                    <router-view />
                </div>
                <MainFooter />
            </main>
        </div>
    </div>
</template>

<script setup>
import { useStore } from "vuex";
import { onUnmounted, computed } from "vue";
import MainHeader from "./MainHeader.vue";
import MainSidebar from "./MainSidebar.vue";
import MainFooter from "./MainFooter.vue";

import { gql } from "graphql-request";
import { graphqlClient } from "@graphql/client";

const store = useStore();

const refreshToken = computed(() => store.state.refreshToken);


// Check session
const checkSession = async () => {
    if(!store.state.token || !store.state.refreshToken) {
        store.dispatch('logout');
        return;
    }

    const query = gql`
        mutation RefreshToken($refreshToken: String!) {
            refreshToken(refreshToken: $refreshToken) {
                jwt
                refreshToken
            }
        }
    `;

    const variables = {
        refreshToken: refreshToken.value
    };

    try {
        const response = await graphqlClient.request(query, variables);
        const { jwt, refreshToken } = response.refreshToken;

        if(jwt && refreshToken) { 
            store.dispatch("setToken", jwt);
            store.dispatch("setRefreshToken", refreshToken);
        } else {
            store.dispatch('logout');
        }

    } catch (error) {
        console.error("Error refreshing token:", error);
    }
};

checkSession();

setInterval(async () => {
    // await checkSession();
}, 3000);

// Keyboard shortcut for save button
const handleKeyPress = (event) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 's') {
        event.preventDefault();
        const saveButton = document.querySelector('.generic-save-button');
        if (saveButton) {
            saveButton.click();
        }
    }
};

window.addEventListener('keydown', handleKeyPress);

onUnmounted(() => {
    window.removeEventListener('keydown', handleKeyPress);
});
</script>

<style lang="scss">
@import "@/styles/layout/main/main.scss";
</style>
