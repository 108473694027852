// store/index.js
import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
    state: {
        token: localStorage.getItem("accessToken") || null,
        refreshToken: localStorage.getItem("refreshToken") || null,
        user: null,
        site: null,
        sites: [
            {
                title: "EN",
                value: 1,
                language: "en",
                full: "English",
            },
            {
                title: "SL",
                value: 2,
                language: "sl",
                full: "Slovenian",
            },
        ],
        settings: {
            wheelNumberOfFieldColumns: 1,
            mysteryItemNumberOfFieldColumns: 1,
        },
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        setToken(state, token) {
            state.token = token;
        },
        setRefreshToken(state, refreshToken) {
            state.refreshToken = refreshToken;
        },
        setSite(state, site) {
            state.site = site;
        },
        logout(state) {
            state.user = null;
            state.token = "";
            state.refreshToken = "";
        },
        setWheelNumberOfFieldColumns(state, numberOfColumns) {
            state.settings.wheelNumberOfFieldColumns = numberOfColumns;
        },
        setMysteryItemNumberOfFieldColumns(state, numberOfColumns) {
            state.settings.mysteryItemNumberOfFieldColumns = numberOfColumns;
        },
    },
    actions: {
        setUser({ commit }, user) {
            commit("setUser", user);
        },
        setToken({ commit }, token) {
            commit("setToken", token);
        },
        setRefreshToken({ commit }, refreshToken) {
            commit("setRefreshToken", refreshToken);
        },
        logout({ commit }) {
            commit("logout");
        },
        setSite({ commit }, site) {
            commit("setSite", site);
        },
        setWheelNumberOfFieldColumns({ commit }, numberOfColumns) {
            commit("setWheelNumberOfFieldColumns", numberOfColumns);
        },
        setMysteryItemNumberOfFieldColumns({ commit }, numberOfColumns) {
            commit("setMysteryItemNumberOfFieldColumns", numberOfColumns);
        },
    },
    getters: {
        isAuthenticated: (state) => !!state.user,
        getUser: (state) => state.user,
        getToken: (state) => state.token,
        getRefreshToken: (state) => state.refreshToken,
        getSite: (state) => state.site,
        getUnsavedChanges: (state) => state.unsavedChanges,
        getWheelNumberOfFieldColumns: (state) => state.settings.wheelNumberOfFieldColumns,
        getMysteryItemNumberOfFieldColumns: (state) => state.settings.mysteryItemNumberOfFieldColumns,
    },
    plugins: [
        createPersistedState({
            storage: window.localStorage,
        }),
    ],
});
