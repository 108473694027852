<template>
    <footer>
        <img :src="require('@/assets/images/logo-auth.png')" alt="TakaTuka" />
        <img :src="require('@/assets/images/erasmus.png')" alt="Erasmus" />
    </footer>
</template>

<script>
// @component { name: 'AuthFooter' }
</script>

<style lang="scss" scoped>
@import "@/styles/layout/auth/footer.scss";
</style>
