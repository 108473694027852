<template>
    <div class="auth-wrapper">
        <div class="auth-form user-register">
            <v-form ref="form" @submit.prevent="register" name="takatuka-admin-register">

                <!-- Personal data -->
                <div class="auth-form-group">
                    <h1 class="text-left">{{ $t("auth.register") }}</h1>

                    <v-text-field
                        :label="$t('auth.firstName')"
                        :placeholder="$t('auth.john')"
                        :rules="firstNameRules"
                        v-model="firstName"
                        autocomplete="on" 
                        name="firstName"
                        type="text"
                    ></v-text-field>

                    <v-text-field
                        :label="$t('auth.lastName')"
                        :placeholder="$t('auth.doe')"
                        :rules="lastNameRules"
                        v-model="lastName"
                        autocomplete="on" 
                        name="lastName"
                        type="text"
                    ></v-text-field>

                    <v-text-field
                        :label="$t('auth.yourEmail')"
                        :placeholder="$t('auth.johnDoeEmail') + '@gmail.com'"
                        :rules="emailRules"
                        v-model="email"
                        autocomplete="on" 
                        name="email"
                        type="email"
                    ></v-text-field>

                    <v-text-field
                        :label="$t('auth.yourPassword')"
                        :rules="passwordRules"
                        v-model="password"
                        type="password"
                        name="password"
                    ></v-text-field>

                    <v-text-field
                        :label="$t('auth.confirmPassword')"
                        :rules="confirmPasswordRules"
                        v-model="confirmPassword"
                        type="password"
                        name="confirmPassword"
                    ></v-text-field>
                </div>

                <!-- Avatar -->
                <div class="auth-form-group">
                    <h2>{{ $t("auth.avatar") }}</h2>

                    <p class="text-muted mb-3">{{ $t("auth.selectAvatar") }}</p>
                    <div class="list-avatars mb-5">
                        <div class="avatar" :class="{ 'selected': selectedAvatar && avatar.id === selectedAvatar.id }" v-for="avatar in avatars" :key="avatar.id">
                            <img :src="avatar.url" :alt="avatar.alt" @click="selectedAvatar = avatar; customAvatar = null">

                            <v-btn v-if="selectedAvatar && avatar.id === selectedAvatar.id" icon @click="selectedAvatar = null" size="small">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </div>
                    </div>

                    <v-file-input
                        :label="$t('auth.orUploadAvatar')"
                        :rules="[v => v.length === 0 || v[0].size < 10485760 || $t('validation.maxFileSize', { size: '10Mb' })]"
                        v-model="customAvatar"
                        prepend-icon="mdi-camera"
                        name="profileImage"
                        accept=".svg, .jpg, .png, .gif"
                        @change="selectedAvatar = null"
                        show-size
                    ></v-file-input>
                </div>

                <!-- Organization and address -->
                <div class="auth-form-group mb-0">
                    <h2>{{ $t("auth.organization") }}</h2>

                      <v-text-field
                        :label="$t('auth.organizationName')"
                        :placeholder="$t('auth.yourOrganization')"
                        v-model="organizationName"
                        :rules="organizationNameRules"
                        autocomplete="on" 
                        type="text"
                        name="organizationName"
                        required
                    ></v-text-field>

                      <v-text-field
                        :label="$t('auth.yourPosition')"
                        :placeholder="$t('auth.yourPosition')"
                        v-model="position"
                        :rules="positionRules"
                        autocomplete="on" 
                        type="text"
                        name="position"
                        required
                    ></v-text-field>

                    <v-autocomplete
                        :label="$t('auth.country')"
                        :items="countries"
                        v-model="country"
                        item-value="code"
                        item-title="name"
                        autocomplete="on" 
                        :rules="countryRules"
                        name="country"
                        required
                    ></v-autocomplete>

                    <v-autocomplete
                        v-if="country && (country.code === 'US' || country === 'US')"
                        :label="$t('auth.state')"
                        :items="states"
                        v-model="state"
                        item-value="code"
                        autocomplete="on"
                        item-title="name"
                        :rules="stateRules"
                        name="state"
                        required
                    ></v-autocomplete>

                    <v-text-field
                        :label="$t('auth.city')"
                        :placeholder="$t('auth.city')"
                        v-model="city"
                        type="text"
                        autocomplete="on" 
                        :rules="cityRules"
                        name="city"
                        required
                    ></v-text-field>

                     <v-text-field
                        :label="$t('auth.address')"
                        :placeholder="$t('auth.address')"
                        v-model="address"
                        type="text"
                        autocomplete="on"
                        name="address"
                        :rules="addressRules"
                        required
                    ></v-text-field>

                    <v-text-field
                        :label="$t('auth.zipCode')"
                        :placeholder="$t('auth.zipCode')"
                        v-model="zipCode"
                        type="text"
                        autocomplete="on"
                        name="zipCode"
                        :rules="zipCodeRules"
                        required
                    ></v-text-field>
                </div>

                <!-- Legal -->
                <div class="legal">
                    <v-checkbox v-model="gdpr" :rules="gdprRules" name="gdpr">
                        <template v-slot:label>
                            <div>
                                {{ $t("auth.legalGdpr") }} <a href="#" class="read-more-link" @click.prevent="showLegal = true">{{ $t("auth.readMore") }}.</a>
                            </div>
                        </template>
                    </v-checkbox>
                </div>

                <!-- Form actions -->
                <div class="form-actions">
                    <v-fade-transition mode="out-in">
                        <v-btn
                            :color="'primary'"
                            :text="$t('auth.register')"
                            class="text-none"
                            variant="flat"
                            type="submit"
                            :loading="formSubmitted"
                            :disabled="formSubmitted"
                            flat
                        ></v-btn>
                    </v-fade-transition>
                </div>

                <!-- Extra actions -->
                <div class="extra-actions">
                    <p class="mt-4">{{ $t("auth.alreadyHaveAccount") }} <br><router-link :to="{ name: 'UserLogin' }" class="text-primary">{{ $t("auth.backToLogin") }}</router-link></p>
                </div>
            </v-form>
        </div>


        <v-dialog v-model="dialog" max-width="500" :class="['register-failed-dialog']">
            <template v-slot:default>
                <v-card :title="$t('auth.registerFailed')">
                    <v-card-text>
                        <ul v-if="registerErrors">
                            <li v-for="error in registerErrors" :key="error">
                                {{ error }}
                            </li>
                        </ul>
                    </v-card-text>

                    <v-card-actions>
                        <v-btn color="primary" @click="closeDialog">{{ $t("actions.close") }}</v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>



        <v-dialog v-model="showLegal" max-width="800">
            <template v-slot:default>
                <v-card :title="$t('auth.legalGdprTitle')">
                    <template v-slot:text>
                        <div v-html="legalText"></div>
                    </template>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                            :text="$t('actions.disagree')"
                            variant="text"
                            @click="showLegal = false; gdpr = false"
                        ></v-btn>

                        <v-btn
                            color="surface-variant"
                            :text="$t('actions.agree')"
                            variant="flat"
                            @click="showLegal = false; gdpr = true"
                        ></v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>

    </div>
</template>


<script setup>
// @component { name: 'UserRegister' }
import countries from '@/data/countries.json';
import states from '@/data/states.json';

import { axiosInstance } from '@/plugins/axiosPlugin';
import { useStore } from "vuex";
import { ref, watch } from "vue";
import { useRouter } from "vue-router";
import { gql } from "graphql-request";
import { graphqlClient } from "@graphql/client";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const store = useStore();
const router = useRouter();

const form = ref(null);
const email = ref("");
const password = ref("");
const confirmPassword = ref("");
const firstName = ref("");
const lastName = ref("");
const address = ref("");
const zipCode = ref("");
const organizationName = ref("");
const position = ref("");
const country = ref("");
const state = ref("");
const city = ref("");
const legalText = ref("");
const dialog = ref(false);
const gdpr = ref(false);
const avatars = ref([]);
const customAvatar = ref(null);
const selectedAvatar = ref(null);
const registerErrors = ref([]);
const showLegal = ref(false);
const formSubmitted = ref(false);

const emailRules = [
    (v) => !!v || t("auth.emailIsRequired"),
    (v) => /.+@.+\..+/.test(v) || t("auth.emailMustBeValid"),
];

const passwordRules = [
    (v) => !!v || t("auth.passwordIsRequired"),
];

const confirmPasswordRules = [
    (v) => !!v || t("auth.confirmPasswordIsRequired"),
    (v) => v === password.value || t("auth.passwordsMustMatch"),
];

const firstNameRules = [
    (v) => !!v || t("auth.firstNameIsRequired"),
];

const lastNameRules = [
    (v) => !!v || t("auth.lastNameIsRequired"),
];

const addressRules = [
];

const zipCodeRules = [
];

const positionRules = [
];

const organizationNameRules = [
];

const countryRules = [
];

const cityRules = [
];

const stateRules = [
];

const gdprRules = [
    (v) => !!v || t("auth.legalGdpr"),
];

if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(async (position) => {
        const response = await fetch(`https://geocode.xyz/${position.coords.latitude},${position.coords.longitude}?geoit=json`);
        const data = await response.json();
        const userCountry = countries.find(country => country.name === data.country);
        country.value = userCountry ? userCountry : 'SI';
    }, () => {
        country.value = 'SI';
    });
} else {
    country.value = 'SI';
}

async function register() {
    const { valid } = await form.value.validate();

    if (!valid || formSubmitted.value) {
        return;
    }

    formSubmitted.value = true;

    try {
        const response = await axiosInstance.post('user/register', {
                siteId: store.state.site.value,
                user: {
                    email: email.value,
                    password: password.value,
                    firstName: firstName.value,
                    lastName: lastName.value,
                    organization: organizationName.value,
                    position: position.value,
                    label: t("auth.addressLabel"),
                    country: country.value,
                    state: state.value,
                    city: city.value,
                    address: address.value,
                    zipCode: zipCode.value,
                    avatar: customAvatar.value,
                    avatarId: selectedAvatar.value ? selectedAvatar.value.id : (avatars.value.length > 0 ? avatars.value[0].id : null),
                    userSelectedLanguage: store.state.site.full ?? 'English',
                    gdpr: gdpr.value
                }
            }, 
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
        const { success, errors } = response.data;

        console.log('success :>> ', success);
        console.log('errors :>> ', errors);

        if(!success) {
            registerErrors.value = typeof errors === 'string' ? [errors] : Object.values(errors).flat();
            dialog.value = true;
            return;
        }

        const query = gql`
            mutation Authenticate($email: String!, $password: String!) {
                authenticate(email: $email, password: $password) {
                    jwt
                    jwtExpiresAt
                    refreshToken
                    refreshTokenExpiresAt
                    user {
                        id
                        fullName
                        preferences
                        photo {
                            id
                            url(transform: "customUserAvatar")
                        }
                        ... on User {
                            messagingAllowed
                            userType
                        }
                    }
                }
            }
        `;

        const variables = {
            email: email.value,
            password: password.value,
        };

        const authResponse = await graphqlClient.request(query, variables);
        const { jwt, user, refreshToken } = authResponse.authenticate;

        console.log('jwt :>> ', jwt);
        console.log('user :>> ', user);
        console.log('refreshToken :>> ', refreshToken);

        store.dispatch("login", user);
        store.dispatch("setToken", jwt);
        store.dispatch("setRefreshToken", refreshToken);

    } catch (error) {
        console.error("Error registering:", error);

    } finally {
        formSubmitted.value = false;
        router.push("/dashboard");
    }
}

const closeDialog = () => {
    dialog.value = false;
}

async function getAvatars() {
    const query = gql`
        query Avatars {
            assets(volumeId: "1", predefinedAvatar: true) {
                ... on userAvatars_Asset {
                    id
                    url(transform: "customUserAvatar")
                    alt
                }
            }
        }
    `;

    const response = await graphqlClient.request(query);
    avatars.value = response.assets;
}

getAvatars();

async function getLegalText() {
    const query = gql`
        query LegalText($siteId: [QueryArgument]!) {
            globalSet(siteId: $siteId) {
                ... on legalText_GlobalSet {
                    id
                    description
                }
            }
        }
    `;

    const response = await graphqlClient.request(query, { siteId: [store.state.site.value] });
    legalText.value = response.globalSet.description;
}

getLegalText();

watch(store.state.site, () => {
    getLegalText();
});

</script>

<style lang="scss">
@import "@/styles/pages/auth/register.scss";
</style>