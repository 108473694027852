// axiosPlugin.js
import axios from "axios";
const baseURL = process.env.VUE_APP_CMS_BASE_URL;

export const axiosInstance = axios.create({
    baseURL: `${baseURL}/actions/games-module/`
});

export default {
    install(app) {
        app.config.globalProperties.$axios = axiosInstance;
    },
};
