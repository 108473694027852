<template>
    <div class="user-avatar-wrapper" :class="{ 'reverse': props.reverse, 'clickable': props.clickable }" v-if="props.user" @click="showUserProfile" :disabled="props.clickable">
        <div class="image-wrapper">
            <img
                :src="props.user.photo.url"
                :alt="props.user.fullName"
                class="d-block w-100"
            />
        </div>
        <strong v-if="!props.avatarOnly">{{ props.user.fullName }}</strong>
    </div>
</template>

<script setup>
import { defineProps, computed } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

const props = defineProps({
    user: {
        type: Object,
        required: true
    },
    reverse: {
        type: Boolean,
        default: false
    },
    width: {
        type: String,
        default: "52px"
    },
    clickable: {
        type: Boolean,
        default: false
    },
    avatarOnly: {
        type: Boolean,
        default: false
    }
});

const user = computed(() => props.user);

const showUserProfile = () => {
    if (props.clickable && user.value) {
        router.push(`/users/${user.value.id}`);
    }
}

</script>

<style lang="scss" scoped>
    .user-avatar-wrapper {
        display: flex;
        align-items: center;
        gap: 10px;
        flex-direction: row;
        justify-content: flex-start;
        text-align: left;

        &.reverse {
            flex-direction: row-reverse;
            justify-content: flex-end;
            text-align: right;
        }

        &.clickable {
            cursor: pointer;

            &:hover {

                .image-wrapper {
                    
                    img {
                        transform: scale(1.3);
                    }
                }
            }
        }

        .image-wrapper {
            width: v-bind(width);
            height: v-bind(width);
            border-radius: 50%;
            overflow: hidden;
            flex-shrink: 0;
            
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 50%;
                transition: transform 0.15s ease-in-out;
                transform: scale(1);
            }
        }

        strong {
            line-height: 1.1;
        }
    }
</style>