<template>
    <div class="generic-wrapper">

        <div class="generic-wrapper__header">
            <h1>{{ $t("pages.userHelp.title") }}</h1>
        </div>

        <!-- Progress -->
        <v-progress-linear
            v-if="loading"
            color="#24a8cb"
            height="6"
            indeterminate
            rounded
        ></v-progress-linear>

        <!-- Categories -->
        <div class="generic-wrapper__space">                    
            <v-expansion-panels class="my-4" variant="inset">
                <CategoryExpansionPanel
                    v-for="category in categories"
                    :key="category.id"
                    :category="category"
                />
            </v-expansion-panels>
        </div>

    </div>
</template>

<script setup>
import { useStore } from "vuex";
import { ref, watch, computed } from "vue";
import { gql } from "graphql-request";
import { graphqlClient } from "@graphql/client";
import CategoryExpansionPanel from '@/components/pages/help/CategoryExpansionPanel.vue';

const store = useStore();
const categories = ref([]);
const loading = ref(false);
const siteId = computed(() => store.state.site.value);

watch(
    () => store.state.site,
    () => {
        fetchCategories();
    },
    { deep: true }
);

function mapCategory(category) {
    return {
        id: category.id,
        title: category.title,
        children: category.children ? category.children.map(mapCategory) : []
    };
}

async function fetchEntries(category) {
    const query = gql`
        query FetchFaqItems(
            $categoryId: [QueryArgument]!
            $siteId: [QueryArgument]!
        ) {
            entries(section: "faq", relatedToCategories: { id: $categoryId, siteId: $siteId }) {
                ... on faq_Entry {
                    id
                    title
                    description
                }
            }
        }
    `;

    const variables = {
        siteId: [siteId.value],
        categoryId: [category.id],
    };

    try {
        const response = await graphqlClient.request(query, variables);
        category.items = response.entries;

    } catch (error) {
        console.error("Error fetching faq items:", error);

    }
}

async function fetchEntriesRecursively(category) {
    await fetchEntries(category);

    if (category.children && category.children.length > 0) {
        for (const child of category.children) {
            await fetchEntriesRecursively(child);
        }
    }
}

async function fetchCategories() {
    if (loading.value) return;

    loading.value = true;

    const query = gql`
        query GetCategories($siteId: [QueryArgument]!) {
            categories(hasDescendants: true, siteId: $siteId, group: "faqCategories") {
                ... on faqCategories_Category {
                    id
                    title
                    children {
                        id
                        title
                    }
                }
            }
        }
    `;

    const variables = {
        siteId: [siteId.value]
    };

    try {
        const response = await graphqlClient.request(query, variables);
        categories.value = response.categories.map(mapCategory);
        for (const category of categories.value) {
            await fetchEntriesRecursively(category);
        }

    } catch (error) {
        console.error("Error fetching categories:", error);

    } finally {
        loading.value = false;
    }
}

fetchCategories();

</script>

<style lang="scss" scoped>
</style>
