<template>

    <!-- New Private Message -->
    <v-dialog v-model="newMessageDialog" max-width="800">
        <v-form ref="newMessageDialogForm" @submit.prevent="createNewMessage">
            <v-card
                prepend-icon="mdi-pencil"
                :title="$t('pages.messages.newMessage')"
            >
                <v-card-text>
                    <v-row dense>
                        <v-col cols="12">
                            <v-autocomplete
                                v-model="recipient"
                                :disabled="creatingNewMessage || loadingUsers"
                                :loading="loadingUsers"
                                :items="allUsers"
                                :rules="[(v) => !!v || $t('pages.messages.newMessageRecipientRequired')]"
                                color="#1A5B6A"
                                item-title="fullName"
                                item-value="id"
                                :label="$t('pages.messages.newMessageRecipient')"
                                multiple
                            >
                                <template v-slot:chip="{ props, item }">
                                    <v-chip
                                        v-if="item.raw.photo"
                                        v-bind="props"  
                                        :prepend-avatar="item.raw.photo.url"
                                        :text="item.raw.fullName"
                                    ></v-chip>
                                </template>

                                <template v-slot:item="{ props, item }">
                                    <v-list-item
                                        v-if="item.raw.photo"
                                        v-bind="props"
                                        :prepend-avatar="item.raw.photo.url"
                                        :title="item.raw.fullName + ' - ' + item.raw.email"
                                    ></v-list-item>
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12">
                            <v-text-field
                                :label="$t('pages.messages.newMessageSubject')"
                                v-model="newMessage.title"
                                :rules="[(v) => !!v || $t('pages.messages.newMessageSubjectRequired')]"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12">
                            <v-textarea
                                :label="$t('pages.messages.newMessageText')"
                                v-model="newMessage.description"
                                :rules="[(v) => !!v || $t('pages.messages.newMessageTextRequired')]"
                                autofocus
                            ></v-textarea>

                            <v-switch v-model="sendCopyToEmail" :disabled="true || creatingNewMessage" color="#1A5B6A" class="ps-2 mt-3" density="compact" hide-details>
                                <template #label>
                                    {{ $t("pages.messages.newMessageSendCopyToEmail") }}
                                </template>
                            </v-switch>

                            <v-divider class="mt-4"></v-divider>
                        </v-col>
                    </v-row>

                        
                </v-card-text>


                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        :text="$t('actions.close')"
                        variant="flat"
                        @click="closeDialog"
                    ></v-btn>

                    <v-btn
                        color="primary"
                        :text="$t('actions.send')"
                        variant="flat"
                        :loading="creatingNewMessage"
                        :disabled="creatingNewMessage"
                        type="submit"
                    ></v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>

    <!-- Message sent success snackbar -->
    <v-snackbar
        :timeout="2000"
        color="success"
        variant="elevated"
        v-model="messageSentSuccessfully"
    >
        {{ $t("pages.messages.messageSentSuccess") }}
    </v-snackbar>

</template>

<script setup>
import { ref, computed, defineProps, defineEmits, watch, onMounted } from "vue";
import { axiosInstance } from '@/plugins/axiosPlugin';
import { gql } from "graphql-request";
import { graphqlClient } from "@graphql/client";
import { useStore } from 'vuex';

const store = useStore();
const user = computed(() => store.state.user);
const site = computed(() => store.state.site);
const props = defineProps({
    show: {
        type: Boolean,
        required: true,
    },
    recipient: {
        type: [Number, String],
        required: false,
    },
});

const recipient = ref(props.recipient);
const newMessageDialog = computed(() => props.show);
const newMessage = ref({
    title: "",
    description: "",
    recipientId: recipient.value
});

const newMessageDialogForm = ref(null);
const creatingNewMessage = ref(false);
const messageSentSuccessfully = ref(false);
const allUsers = ref([]);
const loadingUsers = ref(false);
const sendCopyToEmail = ref(false);
const emit = defineEmits(['messageSent', 'close']);

watch(recipient, () => {
    newMessage.value.recipientId = recipient.value;
}, { deep: true });

async function createNewMessage() {

    if (creatingNewMessage.value) {
        return;
    }

    const { valid } = await newMessageDialogForm.value.validate();

    if (!valid) {
        return;
    }

    creatingNewMessage.value = true;

    try {
        const response = await axiosInstance.post("/messages/create", {
            title: newMessage.value.title,
            description: newMessage.value.description,
            userId: user.value.id,
            recipientId: newMessage.value.recipientId,
            siteId: site.value.value,
        });

        const { success } = response.data;

        if(success) {
            messageSentSuccessfully.value = true;
            newMessageDialogForm.value.reset();
            emit('messageSent');

            setTimeout(() => {
                messageSentSuccessfully.value = false;
            }, 2000);
        }

    } catch (error) {
        console.error("Error creating new message", error);

    } finally {
        creatingNewMessage.value = false;
    }
}

async function loadAllUsers() {
    if (loadingUsers.value || allUsers.value.length > 0) {
        return;
    }

    loadingUsers.value = true;

    const query = gql`
        query GetAllUsers {
            users(messagingAllowed: true) {
                ... on User {
                    id
                    email
                    fullName
                    photo {
                        url(transform: "customUserAvatar")
                    }
                }
            }
        }
    `;

    try {
        const response = await graphqlClient.request(query);
        allUsers.value = response.users.filter(item => item.id !== user.value.id);
    } catch (error) {
        console.error("Error loading all users", error);
    } finally {
        loadingUsers.value = false;
    }
}

function closeDialog() {
    newMessageDialog.value = false;
    emit('close');
}

onMounted(() => {
    loadAllUsers();
});
</script>