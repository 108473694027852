<template>
    <footer>
        
    </footer>
</template>

<script>
// @component { name: 'MainFooter' }
</script>

<style scoped>
</style>
