import moment from 'moment';
import { useStore } from 'vuex';

export function formatDate(value, format = 'l') {
    const store = useStore();

    if(store && store.state && store.state.site && store.state.site.language) {
        const language = store.state.site.language;
        return moment(value).locale(language).format(format);
    }
    
    return moment(value).format(format);
}

export function convertSmileysToEmojis(text) {
    const smileyMap = {
        ":)": "😊",
        ":(": "😞",
        ":D": "😃",
        ";)": "😉",
        ":P": "😛",
        ":'(": "😢",
        ":O": "😮",
        ":|": "😐",
        "XD": "😆",
        "B)": "😎",
        "&lt;3": "❤️",
        "<3": "❤️",
    };

    return text.replace(/(:\)|:\(|:D|;\)|:P|:'\(|:O|:\||XD|B\)|&lt;3|<3)/g, match => smileyMap[match] || match).split('\n').join('<br>');
}