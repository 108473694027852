<template>

    <div class="generic-wrapper">

        <div class="generic-wrapper__header">
            <h1>{{ $t('pages.games.categories.' + gameCategory.name) }}</h1>
        </div>


        <div class="generic-wrapper__content">

            <!-- Toolbar -->
            <v-data-table
                :items="games"
                :headers="headers"
                items-per-page="50"
                v-model="selected"
                class="generic-datatable"
                return-object
                show-select
            >

                <!-- Toolbar for selected items -->
                <template v-slot:top>

                    <!-- Progress -->
                    <v-progress-linear
                        v-if="loading"
                        color="#24a8cb"
                        height="6"
                        indeterminate
                        rounded
                    ></v-progress-linear>

                    <v-card color="grey-lighten-4" rounded="0" flat>
                        <v-toolbar prominent :elevation="2" color="#F3F4F5">
                            
                            <v-btn icon @click="confirmDelete" :disabled="selected.length === 0">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>

                            <v-spacer></v-spacer>

                            <div class="d-flex align-center me-3">
                                <v-select
                                    bg-color="#f3f4f5"
                                    v-model="gameTypes"
                                    :items="allGameTypes"
                                    density="compact"
                                    color="#144753"
                                    chips
                                    multiple
                                    hide-details
                                >
                                    <template v-slot:chip="{ item }">
                                        <v-chip color="#144753">{{ item.title }}</v-chip>
                                    </template>
                                </v-select>
                            </div>

                            <v-tooltip
                                v-if="searchQuery.length > 0 || showMyGames"
                                :text="$t('actions.clearFilters')"
                            >
                                <template v-slot:activator="{ props }">
                                    <v-btn
                                        variant="flat"
                                        v-bind="props"
                                        @click="searchQuery = ''; showMyGames = false; getGames()"
                                        :disabled="loading"
                                    >
                                        {{ $t("actions.clearFilters") }}
                                    </v-btn>
                                </template>
                            </v-tooltip>

                            <v-tooltip :text="$t('pages.games.searchGames')">
                                <template v-slot:activator="{ props }">
                                    <v-btn
                                        icon
                                        v-bind="props"
                                        @click="searchDialog = true"
                                        :disabled="loading"
                                    >
                                        <v-icon>mdi-magnify</v-icon>
                                    </v-btn>
                                </template>
                            </v-tooltip>

                            <v-tooltip :text="$t('pages.games.showMyGames')">
                                <template v-slot:activator="{ props }">
                                    <v-btn icon v-bind="props" @click="showMyGames = !showMyGames; getGames();" :disabled="loading">
                                        <v-icon :color="showMyGames ? 'cyan-lighten-1' : 'default'">{{ showMyGames ? 'mdi-account-edit' : 'mdi-account-edit-outline' }}</v-icon>
                                    </v-btn>
                                </template>
                            </v-tooltip>

                            <v-tooltip :text="$t('actions.refresh')">
                                <template v-slot:activator="{ props }">
                                    <v-btn
                                        icon
                                        v-bind="props"
                                        @click="getGames();"
                                        :disabled="loading"
                                    >
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                </template>
                            </v-tooltip>
                        
                        </v-toolbar>
                    </v-card>

                    <v-spacer></v-spacer>
                </template>

                <!-- Item date -->
                <template #[`item.id`]="{ item }">
                    {{ item.id }}
                </template>

                <!-- Item date -->
                <template #[`item.date`]="{ item }">
                    {{ $filters.date(item.date) }}
                </template>

                <!-- Item name -->
                <template #[`item.name`]="{ item }">
                    <a :href="item.gameUrl" target="_blank" class="text-primary" v-if="item.status !== 'disabled'">{{ item.name }}</a>
                    <span v-else>{{ item.name }}</span>
                </template>

                <!-- Item type -->
                <template #[`item.type`]="{ item }">
                    <span>{{ $t(`pages.games.gameTypes.${item.type}`) }}</span>
                </template>

                <!-- Item author -->
                <template #[`item.author`]="{ item }">
                    <div class="py-3">
                        <UserAvatar :user="item.author" :width="'35px'" clickable />
                    </div>
                </template>

                <!-- Item language -->
                <template #[`item.language`]="{ item }">
                    <v-chip class="text-uppercase">
                        {{ store.state.sites.find(site => site.value === item.language).title }}
                    </v-chip>
                </template>

                <!-- Number of Plays -->
                <template #[`item.plays`]="{ item }">
                    {{ item.plays || 0 }}
                </template>

                <!-- Game status -->
                <template #[`item.status`]="{ item }">
                    <v-icon :color="item.status === 'disabled' ? '#d1d1d1' : 'green'" variant="tonal">{{ item.status === 'disabled' ? 'mdi-close-circle' : 'mdi-check-circle' }}</v-icon>
                </template>

                <!-- Item actions -->
                <template #[`item.actions`]="{ item }">

                    <v-menu>
                        <template v-slot:activator="{ props }">
                            <v-btn icon="mdi-dots-vertical" v-bind="props"></v-btn>
                        </template>

                        <v-list width="150">
                            <v-list-item @click="playGame(item)" base-color="primary" v-if="item.status !== 'disabled'">
                                <v-list-item-title>
                                    <v-icon class="me-2" size="small"
                                        >mdi-controller-classic</v-icon
                                    >
                                    {{ $t("actions.play") }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="editGame(item)" v-if="user && (user.id === item.author.id || user.userType === 'admin')">
                                <v-list-item-title>
                                    <v-icon class="me-2" size="small"
                                        >mdi-pencil</v-icon
                                    >
                                    {{ $t("actions.edit") }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="duplicatingGameItem = item; duplicateGameDialog = true;">
                                <v-list-item-title>
                                    <v-icon class="me-2" size="small"
                                        >mdi-content-copy</v-icon
                                    >
                                    {{ $t("actions.duplicate") }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="confirmDelete(item)" :disabled="deletingGames" v-if="user && (user.id === item.author.id || user.userType === 'admin')">
                                <v-list-item-title>
                                    <p style="color: red;">
                                    <v-icon class="me-2" size="small" 
                                        >mdi-delete</v-icon
                                    >
                                    {{ $t("actions.delete") }}
                                    </p>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>

            </v-data-table>

            
            <!-- Search -->
            <v-dialog v-model="searchDialog" max-width="600">
                <v-form ref="searchForm" @submit.prevent="getGames(searchQuery)">
                    <v-card
                        prepend-icon="mdi-magnify"
                        :title="$t('pages.games.searchGames')"
                    >
                        <v-card-text>
                            <v-row dense>
                                <v-col cols="12">
                                    <v-text-field
                                        :label="$t('pages.games.searchGamesInput')"
                                        v-model="searchQuery"
                                        required
                                        autofocus
                                        :rules="[(v) => !!v || $t('validation.required')]"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>

                            <v-btn
                                :text="$t('actions.close')"
                                variant="flat"
                                @click="searchForm.reset(); searchDialog = false; searchQuery = '';"
                            ></v-btn>

                            <v-btn
                                color="#1A5B6A"
                                :text="$t('actions.search')"
                                variant="flat"
                                :loading="searching || loading"
                                :disabled="searching || loading"
                                type="submit"
                            ></v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-dialog>


            <!-- Delete confirmation dialog -->
            <v-dialog v-model="showDeleteConfirmationBanner" max-width="400">
                <v-card
                    :title="$t('actions.confirmDelete')"
                >
                    <template v-slot:text>
                        <p v-if="deletingItem !== null">
                            {{ $t('actions.deleteConfirmation') }}
                            <br>
                            <strong style="margin-top: 10px;display: block;">{{ deletingItem.name }}</strong>
                        </p>

                        <div v-else>
                            <p>{{ $t('actions.deleteConfirmationSelected') }}</p>
                            <v-list density="compact">
                                <v-list-item
                                    v-for="(item, i) in selected"
                                    :key="i"
                                    :value="item"
                                    color="primary"
                                >
                                    <v-list-item-title><strong v-text="item.name"></strong></v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </div>
                    </template>

                    <template v-slot:actions>
                        <v-spacer></v-spacer>

                        <v-btn @click="showDeleteConfirmationBanner = false" variant="tonal" :disabled="deletingGames">
                            {{ $t("actions.cancel") }}
                        </v-btn>

                        <v-btn @click="deleteGame" variant="flat" color="red" :disabled="deletingGames" :loading="deletingGames">
                            {{ $t("actions.delete") }}
                        </v-btn>
                    </template>
                </v-card>
            </v-dialog>


            <!-- Duplicate game dialog -->
            <v-dialog v-model="duplicateGameDialog" max-width="500">
                <v-card :title="$t('pages.games.edit.duplicateGame')">
                    <v-card-text>{{ $t("pages.games.edit.duplicateGameConfirmation") }}</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn variant="tonal" @click="duplicateGameDialog = false">{{ $t("actions.cancel") }}</v-btn>
                        <v-btn color="success" variant="flat" @click="duplicateGame" :disabled="duplicatingGame" :loading="duplicatingGame">{{ $t("actions.confirm") }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>


            <!-- Game duplicated successfully snackbar -->
            <v-snackbar v-model="gameDuplicatedSuccessfully" :timeout="4000" color="success" variant="elevated">
                {{ $t("pages.games.edit.gameDuplicated") }}
            </v-snackbar>


            <!-- gamesDeletedSuccessfully snackbar -->
            <v-snackbar v-model="gamesDeletedSuccessfully" :timeout="4000" color="success" variant="elevated">
                <span v-if="deletingMultipleGames">
                    {{ $t("pages.games.list.gamesDeletedSuccessfully") }}
                </span>
                <span v-else>
                    {{ $t("pages.games.list.gameDeletedSuccessfully") }}
                </span>
            </v-snackbar>
        </div>
    </div>

</template>

<script setup>
import { useStore } from "vuex";
import { ref, watch, onMounted, computed, reactive } from "vue";
import { gql } from "graphql-request";
import { graphqlClient } from "@graphql/client";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import UserAvatar from "@/components/misc/UserAvatar.vue";
import { axiosInstance } from "@/plugins/axiosPlugin";

const { t } = useI18n();
const store = useStore();
const router = useRouter();

const user = computed(() => store.state.user);
const site = computed(() => store.state.site);
const sites = computed(() => store.state.sites);
const games = ref([]);
const showDeleteConfirmationBanner = ref(false);
const deletingItem = ref(null);
const headers = computed(() => [
    { title: "ID", key: "id" },
    { title: t("dataTable.date"), key: "date", sortable: false },
    { title: t("dataTable.name"), key: "name", sortable: false },
    { title: t("dataTable.type"), key: "type", sortable: false },
    { title: t("dataTable.author"), key: "author", sortable: false },
    // { title: t("dataTable.language"), key: "language" },
    { title: t("dataTable.numberOfPlays"), key: "plays", align: "center" },
    { title: t("dataTable.gameStatus"), key: "status", align: "center", sortable: false },
    {
        title: t("dataTable.actions"),
        key: "actions",
        align: "end",
        sortable: false,
    },
]);
const allGameTypes = ref([{
    title: t("pages.games.gameTypes.wheel"),
    value: "wheel",
}, {
    title: t("pages.games.gameTypes.mysteryItem"),
    value: "mysteryItem",
}, {
    title: t("pages.games.gameTypes.shapes"),
    value: "shapes",
}, {
    title: t("pages.games.gameTypes.writtenSources"),
    value: "writtenSources",
}]);
const searchDialog = ref(false);
const selected = ref([]);
const loading = ref(false);
const searching = ref(false);
const gameTypes = ref(["wheel", "mysteryItem", "shapes", "writtenSources"]);
const showMyGames = ref(false);
const page = ref(1);
const limit = ref(50);
const totalGames = ref(0);
const selectedSitesObject = reactive({});
const searchQuery = ref("");
const deletingMultipleGames = ref(false);
const deletingGames = ref(false);
const duplicatingGameItem = ref(null);
const duplicateGameDialog = ref(false);
const gameDuplicatedSuccessfully = ref(false);
const duplicatingGame = ref(false);
const gamesDeletedSuccessfully = ref(false);
const defaultSites = store.state.sites.filter(site => site.language === "en" || site.language === store.state.site.language);
defaultSites.forEach((site) => {
    selectedSitesObject[site.value] = site.value;
});

const selectedSites = computed({
    get() {
        return Object.keys(selectedSitesObject).filter(key => selectedSitesObject[key]);
    },
    set(newValue) {
        Object.keys(selectedSitesObject).forEach(key => {
            selectedSitesObject[key] = newValue.includes(key);
        });
    }
});

const duplicateGameTitlePostfix = computed(() => {
    const postfixes = {};
    sites.value.forEach(site => {
        postfixes[site.value] = t("pages.games.edit.duplicateGameTitlePostfix", {}, { locale: site.language });
    });
    return postfixes;
});

// const currentSite = computed(() => store.state.site);

const gameCategory = computed(() => {
    if (router.currentRoute.value.name === "GamesListTakaTuka") {
        return {
            id: 4,
            name: "takatukaGames",
        };
    } else if (router.currentRoute.value.name === "GamesListTAT") {
        return {
            id: 5,
            name: "tatGames",
        };
    } else {
        return {
            id: 3,
            name: "myGames",
        };
    }
});

watch(
    () => store.state.site,
    () => {
        getGames();
    },
    { deep: true }
);

watch(
    () => router.currentRoute.value,
    () => {
        getGames();
    }
);

watch(gameTypes, (newValue, oldValue) => {
    if(newValue.length === 0) {
        gameTypes.value = oldValue;
    }

    getGames();
});

async function getGames(searchTitle = "") {
    loading.value = true;
    games.value = [];
    totalGames.value = 0;
    searching.value = false;

    if(searchTitle.length > 0) {
        searching.value = true;
    }

    const totalGamesCount = await getEntryCount(
        searchTitle,
        selectedSites.value,
        gameCategory.value.id
    );

    const query = gql`
        query GetPaginatedGames($limit: Int!, $offset: Int!, $searchTitle: String, $siteId: [QueryArgument], $authorId: [QueryArgument], $gameCategory: [QueryArgument], $type: [String]) {
            entries(
                limit: $limit,
                offset: $offset,
                search: $searchTitle,
                section: "games",
                siteId: $siteId,
                authorId: $authorId,
                status: null,
                gameCategory: $gameCategory,
                type: $type,
            ) {
                id
                postDate
                title
                url
                uri
                slug
                typeHandle
                siteId
                status
                authors {
                    id
                    ... on User {
                        id
                        email
                        fullName
                        photo {
                            url(transform: "customUserAvatar")
                        }
                    }
                }
                ... on wheel_Entry {
                    id
                    publicGame
                    numberOfPlays
                }
                ... on mysteryItem_Entry {
                    id
                    numberOfPlays
                }
                ... on shapes_Entry {
                    id
                    numberOfPlays
                }
                ... on writtenSources_Entry {
                    id
                    numberOfPlays
                }
            }
        }
    `;

    const variables = {
        limit: limit.value,
        offset: (page.value - 1) * limit.value,
        searchTitle: searchTitle ? `*${searchTitle}*` : "",
        siteId: [site.value.value],
        authorId: showMyGames.value ? [user.value.id] : null,
        gameCategory: [gameCategory.value.id],
        type: gameTypes.value.length > 0 ? gameTypes.value : null,
    };

    try {
        const response = await graphqlClient.request(query, variables);

        games.value = response.entries.filter(entry => entry.publicGame || entry.authors.some(author => author.id === (user.value ? user.value.id : null))).map((entry) => ({
            id: entry.id,
            date: entry.postDate,
            name: entry.title,
            slug: entry.slug,
            url: entry.url,
            type: entry.typeHandle,
            language: entry.siteId,
            plays: entry.numberOfPlays,
            status: entry.status,
            author: entry.authors.length > 0 ? entry.authors[0] : null,
            gameUrl: entry.url.replace(process.env.VUE_APP_CMS_BASE_URL, process.env.VUE_APP_GAMES_BASE_URL).replace(entry.uri, (entry.siteId === 1 ? 'en/' : '') + entry.siteId + "/" + entry.uri),
        }));
        totalGames.value = totalGamesCount;
        console.log('games :>> ', games.value);

    } catch (error) {
        console.error("Error fetching games:", error);
    } finally {
        loading.value = false;
        searching.value = false;
        searchDialog.value = false;
    }
}

function confirmDelete(item) {
    if (item.id) {
        selected.value = [];
        deletingItem.value = item;

    } else {
        deletingItem.value = null;
    }
    
    showDeleteConfirmationBanner.value = true;
}

function editGame(item) {
    router.push(`/game/${item.id}/edit`);
}

async function deleteGame() {
    deletingGames.value = true;
    deletingMultipleGames.value = deletingItem.value ? false : true;

    try {
        const response = await axiosInstance.post("games/delete-game", {
            games: deletingItem.value ? [deletingItem.value.id] : selected.value.map(game => game.id)
        });
        const { success } = response.data;

        if(success) {
            gamesDeletedSuccessfully.value = true;
            await getGames();
        }

    } catch (error) {
        console.error("Error deleting game:", error);
    } finally {
        showDeleteConfirmationBanner.value = false;
        selected.value = deletingItem.value ? selected.value : [];
        deletingItem.value = null;
        deletingGames.value = false;
    }
}


async function duplicateGame() {
    if(!duplicatingGameItem.value) {
        return;
    }
    
    duplicatingGame.value = true;
    
     try {
        const response = await axiosInstance.post("games/duplicate-game", {
            gameId: duplicatingGameItem.value.id,
            siteId: duplicatingGameItem.value.siteId,
            userId: user.value.id,
            titlePostfix: duplicateGameTitlePostfix.value || t("pages.games.edit.duplicateGameTitlePostfix"),
        });
        const { success, gameId } = response.data;

        if(success) {
            gameDuplicatedSuccessfully.value = true;
           
            setTimeout(() => {
                router.push(`/game/${gameId}/edit`);
            }, 750);
        }

    } catch (error) {
        console.error("Error updating game:", error);
    } finally {
        duplicatingGameItem.value = null;
        duplicatingGame.value = false;
        duplicateGameDialog.value = false;
    }
}

async function getEntryCount(searchTitle = "") {
    const query = gql`
        query GetGamesCount($searchTitle: String, $siteId: [QueryArgument], $authorId: [QueryArgument], $gameCategory: [QueryArgument]) {
            entryCount(section: "games", search: $searchTitle, siteId: $siteId, authorId: $authorId, gameCategory: $gameCategory)
        }
    `;

    const variables = {
        searchTitle: searchTitle ? `*${searchTitle}*` : "",
        siteId: [site.value.value],
        authorId: showMyGames.value ? [user.value.id] : null,
        gameCategory: [gameCategory.value.id],
    };

    const response = await graphqlClient.request(query, variables);
    return response.entryCount;
}

function playGame(item) {
    window.open(item.gameUrl, '_blank');
}

onMounted(() => {
    getGames();
});
</script>

<style lang="scss" scoped>
</style>