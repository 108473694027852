// graphql/client.js
import { GraphQLClient } from 'graphql-request';
import { jwtDecode } from 'jwt-decode';
import store from '@/store';

const baseURL = process.env.VUE_APP_CMS_BASE_URL;
const endpoint = `${baseURL}/api`;

export const graphqlClient = new GraphQLClient(endpoint, {
    headers: {
        Authorization: `${store.getters.getToken}`
    }
});

const refreshToken = async () => {
    const token = store.getters.getRefreshToken;
    
    if (!token) return null;
    
    try {
      const response = await fetch('/refresh-token', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ refreshToken: token }),
      });
      
      if (response.ok) {
        const data = await response.json();
        store.dispatch('setToken', data.accessToken);
        return data.accessToken;
      }
    } catch (error) {
      console.error('Error refreshing token:', error);
    }
    
    return null;
};

export const checkTokenExpiry = async () => {
const token = store.getters.getToken;

if (token) {
    const { exp } = jwtDecode(token);
    const expiryTime = exp * 1000;
    
    if (expiryTime - Date.now() < 5 * 60 * 1000) {
    await refreshToken();
    }
}
};
