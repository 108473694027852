<template>
    <div class="mystery-item-fields-wrapper">
        <h3 class="mb-5">{{ $t("pages.games.mysteryItem.mainImage") }}</h3>

        <!-- Library Module -->
        <LibraryModule 
            @selected="selectMysteryItemMainImage" 
            @close="displayLibraryForMainImage = false" 
            return-objects 
            images-only
            autoselect
            :selected-category="'Mystery item'"
            :selected-limit="1"
        />

        <v-spacer class="py-2" />

        <h3 class="mb-5">{{ $t("pages.games.mysteryItem.fields") }}</h3>

        <!-- Toolbar -->
        <v-toolbar density="compact" color="transparent" class="mystery-item-field-columns-toolbar" v-if="game.mysteryItemFields.length > 0">
            <v-spacer></v-spacer>

            <v-btn icon @click="mysteryItemNumberOfFieldColumns = 1" :class="{ 'active': mysteryItemNumberOfFieldColumns === 1 }" color="#6C737A">
                <v-icon>mdi-list-box-outline</v-icon>
            </v-btn>

            <v-btn icon @click="mysteryItemNumberOfFieldColumns = 2" :class="{ 'active': mysteryItemNumberOfFieldColumns === 2 }" color="#6C737A">
                <v-icon>mdi-view-grid-outline</v-icon>
            </v-btn>

            <v-btn icon @click="mysteryItemNumberOfFieldColumns = 3" :class="{ 'active': mysteryItemNumberOfFieldColumns === 3 }" color="#6C737A">
                <v-icon>mdi-grid</v-icon>
            </v-btn>
        </v-toolbar>

        <!-- Fields -->
        <div class="mystery-item-edit-fields" :class="{ 'two-columns': mysteryItemNumberOfFieldColumns === 2, 'three-columns': mysteryItemNumberOfFieldColumns === 3 }">
            <div class="mystery-item-edit-field" v-for="(field, index) in game.mysteryItemFields" :key="field.id">
                <div class="custom-label mt-0 mb-5 d-flex justify-space-between align-center">
                    <strong>{{ $t("pages.games.mysteryItem.field") }} {{ index + 1 }}</strong>
                    <v-btn icon @click="removeField(index)" color="red" variant="tonal" :disabled="disabled">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>

                <!-- Image -->
                <p class="custom-label mb-3">{{ $t("pages.games.mysteryItem.selectOrUploadImage") }}</p>
                <div class="mystery-item-image" @click="field.displayLibrary = true">
                    <div v-if="field.mysteryItemImage[0]" class="image-wrapper">
                        <img :src="field.mysteryItemImage[0].url" :alt="field.mysteryItemImage[0].title" />
                    </div>
                    <div v-else class="empty-image-placeholder">
                        <v-icon size="x-large" color="#1A5B6A">mdi-image</v-icon>
                    </div>
                </div>

                <v-spacer class="py-2" />

                <p class="custom-label">{{ $t("pages.games.mysteryItem.fieldTitle") }}</p>
                <v-text-field bg-color="white" v-model="field.title" :disabled="disabled" density="compact" variant="outlined" :rules="[v => !!v || $t('validation.required')]" :validate-on="'blur'" />

                <p class="custom-label">{{ $t("pages.games.mysteryItem.description") }}</p>
                <CKEditor v-model="field.description" :disabled="disabled" :key="game.siteId" />

                <!-- Library Module -->
                <v-dialog v-model="field.displayLibrary" persistent>
                    <LibraryModule 
                        v-if="field.displayLibrary" 
                        @selected="selectMysteryItemImage(field, $event)" 
                        @close="field.displayLibrary = false" 
                        return-objects 
                        images-only
                        popup
                        :selected-category="'Mystery item'"
                        :selected-limit="1"
                    />
                </v-dialog>
            </div>
        </div>

        <v-spacer class="py-2" />

        <!-- Actions -->
        <div class="mystery-item-edit-actions mt-5 d-flex align-center justify-space-between">
            <v-spacer></v-spacer>
            <v-btn @click="addField" variant="tonal" color="#1A5B6A" :disabled="disabled" prepend-icon="mdi-plus">{{ $t("pages.games.mysteryItem.addField") }}</v-btn>
        </div>

        

    </div>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref } from "vue";
import CKEditor from "@/components/misc/CKEditor.vue";
import LibraryModule from "@/components/modules/LibraryModule.vue";
import { useStore } from "vuex";

const store = useStore();
const emit = defineEmits(["update:modelValue"]);

const displayLibraryForMainImage = ref(false);
const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false
    }
});

const disabled = computed({
    get() {
        return props.disabled;
    }
});

const game = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit("update:modelValue", value);
    },
});

const mysteryItemNumberOfFieldColumns = computed({
    get() {
        return store.state.settings.mysteryItemNumberOfFieldColumns || 1;
    },
    set(value) {
        store.dispatch('setMysteryItemNumberOfFieldColumns', value);
    },
});

const addField = () => {
    game.value.mysteryItemFields.push({
        title: "",
        description: "",
        mysteryItemImage: null
    });
}

const removeField = (index) => {
    game.value.mysteryItemFields.splice(index, 1);
}
    
const selectMysteryItemImage = (field, photos) => {
    const photo = photos[0];
    field.mysteryItemImage = photo;
    field.displayLibrary = false;

    if(field.title.length === 0) {
        field.title = photo.title;
    }
};

const selectMysteryItemMainImage = (photos) => {
    const photo = photos[0];
    game.value.mysteryItemMainImage = photo ?? null;
    displayLibraryForMainImage.value = false;
}

</script>

<style lang="scss" scoped>
.empty-image-placeholder,
.image-wrapper {
    width: 200px;
    height: auto;
    aspect-ratio: 1 / 1;
    background-color: white;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    cursor: pointer;
    overflow: hidden;

    i {
        transform: scale(1.1);
        transition: transform 0.15s ease-in-out;
    }

    &:hover {

        i {
            transform: scale(1);
        }
    }

    img {
        width: 100%;
        height: 100% !important;
        object-fit: cover;
    }
}

.mystery-item-fields-wrapper {

    .mystery-item-edit-fields {
        display: grid;
        align-items: stretch;
        grid-template-columns: repeat(1, 1fr);
        gap: 32px;

        &.two-columns {
            grid-template-columns: repeat(2, 1fr);
        }

        &.three-columns {
            grid-template-columns: repeat(3, 1fr);
        }

        .mystery-item-edit-field {
            padding: 16px 24px 24px 24px;
            border-radius: 8px;
            background: #F3F4F5;

            .mystery-item-image {
                display: flex;
                justify-content: space-between;
                
                img {
                    max-width: 200px;
                    display: block;
                    height: auto;
                }
            }

            .mystery-item-edit-field-color {
                display: flex;
                gap: 66px;
                align-items: flex-start;
            }

            .switch-wrapper {
                display: flex;
                gap: 16px;
                align-items: center;
            }
        }
    }
}

.mystery-item-field-columns-toolbar {

    .v-btn--icon.v-btn--size-default {
        border-radius: 0;
        border: 3px solid transparent;
        width: 35px;
        height: 35px;
        margin-right: 10px;

        &.active {
            border: 2px solid #6C737A;
            width: 35px;
            height: 35px;
        }
    }
}

</style>