<template>
    <header>

        <div class="breadcrumbs-wrapper d-none">
            <v-breadcrumbs :items="breadcrumbs">
                <template v-slot:prepend>
                <v-icon icon="mdi-view-dashboard" size="small"></v-icon>
                </template>
            </v-breadcrumbs>
        </div>

        <v-spacer></v-spacer>

        <div class="logos">
            <img :src="require('@/assets/images/the-art-of-teaching.png')" alt="The Art of Teaching" />
            <img :src="require('@/assets/images/erasmus.png')" alt="Erasmus" />
        </div>

        <div class="language-selector-wrapper" @click="checkForUnsavedChanges">
            <v-select
                :items="store.state.sites"
                :density="'compact'"
                v-model="store.state.site"
            >
                <template v-slot:item="{ props, item }">
                    <v-list-item v-bind="props" :subtitle="item.raw.full" @click="ignoreUnsavedChangesFlag = false"></v-list-item>
                </template>
            </v-select>
        </div>

        <!-- Delete confirmation dialog -->
        <v-dialog v-model="unsavedChangesAlert" max-width="400" persistent>
            <v-card
                prepend-icon="mdi-alert"
                :title="$t('actions.unsavedChanges')"
            >
                <template v-slot:text>
                    <p>
                        {{ $t('actions.changesWillBeLost') }}
                    </p>
                </template>

                <template v-slot:actions>
                    <v-spacer></v-spacer>

                    <v-btn @click="unsavedChangesAlert = false">
                        {{ $t("actions.cancel") }}
                    </v-btn>

                    <v-btn @click="ignoreUnsavedChanges">
                        {{ $t("actions.continue") }}
                    </v-btn>
                </template>
            </v-card>
        </v-dialog>

    </header>
</template>

<script setup>
// @component { name: 'MainHeader' }

import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from "vue-i18n";
import { useRoute } from 'vue-router';        
import { axiosInstance } from '@/plugins/axiosPlugin';

const store = useStore();
const unsavedChangesAlert = ref(false);
const ignoreUnsavedChangesFlag = ref(false);
// const unsavedChanges = computed(() => store.getters.getUnsavedChanges);
const { t } = useI18n();
const route = useRoute();
const routeName = computed(() => route.name);
const site = computed(() => store.state.site);
const user = computed(() => store.state.user);
const breadcrumbs = ref([]);

const updateBreadcrumbs = (routeName) => {
    breadcrumbs.value = [
        {
            title: t('dashboard'),
            disabled: false,
            href: '/dashboard',
        },
    ];

    if (routeName === 'GamesList') {
        breadcrumbs.value.push({
            title: t('myGames'),
            disabled: true,
            href: '/games',
        });

    } else if (routeName === 'GameEdit') {
        breadcrumbs.value.push({
            title: t('createAGame'),
            disabled: false,
            href: '/create-a-new-game',
        });

        breadcrumbs.value.push({
            title: t('editGame'),
            disabled: true,
            href: `/game/${route.params.id}/edit`,
        });

    } else if (routeName === 'GameCreate') {
        breadcrumbs.value.push({
            title: t('createAGame'),
            disabled: false,
            href: '/create-a-new-game',
        });
    }
};

updateBreadcrumbs(routeName.value);

watch(routeName, (newVal) => {
    updateBreadcrumbs(newVal);
});

watch(site, () => {
    updateBreadcrumbs(routeName.value);
    storeUserPreferedSite();
});

const checkForUnsavedChanges = () => {
    /*if (unsavedChanges.value && !ignoreUnsavedChangesFlag.value) {
        unsavedChangesAlert.value = true;
    }*/

    unsavedChangesAlert.value = false;
    // unsavedChanges.value = false;
}

const ignoreUnsavedChanges = () => {
    ignoreUnsavedChangesFlag.value = true;
    unsavedChangesAlert.value = false;
}

async function storeUserPreferedSite() {
    try {
        await axiosInstance.post('user/update-language', {
                user: {
                    userId: user.value ? user.value.id : null,
                    userSelectedLanguage: site.value.full
                }
            }
        )

    } catch (error) {
        console.error(error);
    }
}

</script>

<style lang="scss" scoped>
    @import '@/styles/layout/main/header.scss';
</style>
