<template>
    <div>

        <v-label class="mb-1">{{ $t("pages.games.gameName") }}</v-label>
        
        <v-text-field
            v-model="game.title"
            :placeholder="$t('pages.games.gameName')"
            variant="outlined"
            :rules="[v => !!v || $t('validation.required')]"
            :validate-on="'blur'"
            :disabled="disabled"
        ></v-text-field>
        
        <p class="custom-label">{{ $t("pages.games.shortDescription") }}</p>
        <CKEditor :modelValue="game.shortDescription" @update:modelValue="game.shortDescription = $event" :key="game.siteId + (game.refresh ? game.refresh : '')" :disabled="disabled" />

        <p class="custom-label">{{ $t("pages.games.gamePurpose") }}</p>
        <CKEditor :modelValue="game.gamePurpose" @update:modelValue="game.gamePurpose = $event" :key="game.siteId + (game.refresh ? game.refresh : '')" :disabled="disabled" />

        <p class="custom-label">{{ $t("pages.games.instructions") }}</p> 
        <CKEditor :modelValue="game.instructions" @update:modelValue="game.instructions = $event" :key="game.siteId + (game.refresh ? game.refresh : '')" :disabled="disabled" />

        <p class="custom-label">{{ $t("pages.games.learningGoals") }}</p>
        <CKEditor :modelValue="game.learningGoals" @update:modelValue="game.learningGoals = $event" :key="game.siteId + (game.refresh ? game.refresh : '')" :disabled="disabled" />

        <p class="custom-label">{{ $t("pages.games.accessories") }}</p>
        <CKEditor :modelValue="game.accessories" @update:modelValue="game.accessories = $event" :key="game.siteId + (game.refresh ? game.refresh : '')" :disabled="disabled" />


        <p class="custom-label">{{ $t("pages.games.gameLink") }}</p>
        <v-text-field
            v-model="game.slug"
            :placeholder="$t('pages.games.gameLink')"
            variant="outlined"
            hide-details
            :disabled="disabled"
        ></v-text-field>
        <div class="mt-2 mb-5 d-flex justify-end">
            <v-chip color="#1A5B6A"><a :href="gameUrl" class="px-4" target="_blank" style="color: #1A5B6A">{{ gameUrl }}</a></v-chip>
        </div>

        
    </div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from "vue";
import CKEditor from "@/components/misc/CKEditor.vue";

const emit = defineEmits(["update:modelValue"]);
const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false
    }
});

const disabled = computed({
    get() {
        return props.disabled;
    }
});

const game = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit("update:modelValue", value);
    },
});

const gameUrl = computed(() => {
    return game.value.url.replace(process.env.VUE_APP_CMS_BASE_URL, process.env.VUE_APP_GAMES_BASE_URL).replace(game.value.uri, (game.value.siteId === 1 ? 'en/' : '') + game.value.siteId + "/" + game.value.uri);
});

</script>

<style lang="scss" scoped>
</style>
