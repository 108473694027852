<template>
    <div class="generic-wrapper">
        <div class="generic-wrapper__header">
            <h1>{{ $t("pages.discussion.title") }}</h1>
        </div>

        <div class="generic-wrapper__space columns-2">
            <div class="generic-wrapper__space__left">
                <div class="new-discussion generic-wrapper__content">
                    <h2 class="mb-3">
                        {{ $t("pages.discussion.newDiscussionTitle") }}
                    </h2>

                    <v-form
                        ref="form"
                        @submit.prevent="createDiscussion"
                        name="takatuka-admin-new-discussion"
                    >
                        <v-text-field
                            :label="$t('pages.discussion.discussionSubject')"
                            v-model="newDiscussion.title"
                            name="discussion-title"
                            :rules="[(v) => !!v || $t('pages.discussion.discussionSubjectRequired')]"
                        ></v-text-field>

                        <v-textarea
                            :label="$t('pages.discussion.whatsOnYourMind')"
                            name="discussion-textarea"
                            variant="filled"
                            auto-grow
                            v-model="newDiscussion.discussionText"
                            :rules="[(v) => !!v || $t('pages.discussion.discussionTextRequired')]"
                        ></v-textarea>

                        <div class="actions d-flex justify-space-between align-center mt-3">
                            <div class="discussion-info-wrapper mb-0" v-if="store.state.user">
                                <div class="image-wrapper">
                                    <img
                                        :src="store.state.user.photo.url"
                                        alt="User"
                                        class="d-block w-100"
                                    />
                                </div>
                                <span>{{ store.state.user.fullName }}</span>
                            </div>
                            <v-btn
                                color="primary"
                                variant="flat"
                                type="submit"
                                :loading="newDiscussion.creating"
                                :disabled="newDiscussion.creating"
                            >
                                {{ $t("actions.publish") }}
                            </v-btn>
                        </div>
                    </v-form>
                </div>

                <div class="discussions-list generic-wrapper__space">


                    <!-- Toolbar -->
                    <v-toolbar :elevation="2" color="#F3F4F5">
                        <v-toolbar-title>
                            {{ $t("pages.discussion.discussions") }}
                        </v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-tooltip
                            v-if="searchQuery.length > 0 || showFavorites || showMyDiscussions"
                            :text="$t('pages.discussion.clearFilters')"
                        >
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    variant="flat"
                                    v-bind="props"
                                    @click="searchQuery = ''; showFavorites = false; showMyDiscussions = false; getDiscussions()"
                                    :disabled="loading"
                                >
                                    {{ $t("pages.discussion.clearFilters") }}
                                </v-btn>
                            </template>
                        </v-tooltip>

                        <v-tooltip :text="$t('pages.discussion.searchDiscussions')">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    icon
                                    v-bind="props"
                                    @click="searchDialog = true"
                                    :disabled="loading"
                                >
                                    <v-icon>mdi-magnify</v-icon>
                                </v-btn>
                            </template>
                        </v-tooltip>

                        <v-tooltip :text="$t('pages.discussion.showFavorites')">
                            <template v-slot:activator="{ props }">
                                <v-btn icon v-bind="props" @click="showFavorites = !showFavorites; getDiscussions(searchQuery);" :disabled="loading">
                                    <v-icon :color="showFavorites ? 'red' : 'default'">{{ showFavorites ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon>
                                </v-btn>
                            </template>
                        </v-tooltip>

                        <v-tooltip :text="$t('pages.discussion.showMyDiscussions')">
                            <template v-slot:activator="{ props }">
                                <v-btn icon v-bind="props" @click="showMyDiscussions = !showMyDiscussions; getDiscussions(searchQuery);" :disabled="loading">
                                    <v-icon :color="showMyDiscussions ? 'cyan-lighten-1' : 'default'">{{ showMyDiscussions ? 'mdi-account-edit' : 'mdi-account-edit-outline' }}</v-icon>
                                </v-btn>
                            </template>
                        </v-tooltip>

                        <v-tooltip :text="$t('pages.discussion.refresh')">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    icon
                                    v-bind="props"
                                    @click="getDiscussions(searchQuery)"
                                    :disabled="loading"
                                >
                                    <v-icon>mdi-refresh</v-icon>
                                </v-btn>
                            </template>
                        </v-tooltip>

                        <v-menu :close-on-content-click="false">
                            <template v-slot:activator="{ props }">
                                <v-btn icon v-bind="props" :disabled="loading">
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                        
                            <v-list density="compact">
                                <v-list-item
                                    v-for="site in store.state.sites"
                                    :key="site.value"
                                    width="200"
                                >
                                    <v-list-item-title>
                                        <v-checkbox
                                            density="compact"
                                            v-model="selectedSitesObject[site.value]"
                                            :value="site.value"
                                            :label="site.full"
                                            hide-details
                                        ></v-checkbox>
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-toolbar>

                    <!-- Progress -->
                    <v-progress-linear
                        v-if="loading"
                        color="#24a8cb"
                        height="6"
                        indeterminate
                        rounded
                    ></v-progress-linear>

                    <!-- Discussions list -->
                    <v-expansion-panels>
                        <v-expansion-panel
                            v-for="item in discussions"
                            :key="item.id"
                            class="mt-4"
                        >
                            <template v-slot:title>
                                <div class="discussion-info-wrapper">
                                    <div
                                        class="image-wrapper"
                                        v-if="item.authors[0] && item.authors[0].photo"
                                    >
                                        <img
                                            :src="item.authors[0].photo.url"
                                            alt="User"
                                            class="d-block w-100"
                                        />
                                    </div>
                                    <div class="discussion-info">
                                        <p
                                            class="discussion-author mb-2"
                                            v-if="item.authors[0]"
                                        >
                                            <strong>{{ item.authors[0].fullName }}</strong>
                                            <span>
                                                {{ $t("pages.discussion.on") }}
                                                {{
                                                    $filters.date(
                                                        item.dateCreated,
                                                        "DD.MM.YYYY HH:mm"
                                                    )
                                                }}
                                            </span>
                                        </p>
                                        <p class="discussion-title">
                                            {{ $filters.smileys(item.title) }}
                                        </p>
                                    </div>
                                </div>

                                <v-spacer></v-spacer>

                                <v-icon v-if="item.favorite" color="red" class="me-2">mdi-heart</v-icon>

                                <v-chip class="me-2 text-uppercase">
                                    {{ store.state.sites.find(site => site.value === item.siteId).title }}
                                </v-chip>
                            </template>

                            <template v-slot:text>
                                <h3 class="discussion-title">
                                    {{ $filters.smileys(item.title) }}
                                </h3>

                                <v-divider class="my-4"></v-divider>

                                <div
                                    class="discussion-text"
                                    v-html="$filters.smileys(item.discussionText)"
                                ></div>

                                <v-divider class="mt-5"></v-divider>

                                <DiscussionReplies :discussion="item" />

                                <v-divider class="mt-5 mb-3"></v-divider>

                                <v-toolbar color="transparent">

                                    <v-btn
                                        variant="flat"
                                        color="red"
                                        v-if="user && (item.authors[0] && item.authors[0].id === user.id || user.userType === 'admin')"
                                        :loading="item.deleting"
                                        :disabled="item.deleting"
                                        @click="confirmDeleteDiscussion(item)"
                                    >
                                        {{ $t("actions.delete") }}
                                    </v-btn>

                                    <v-btn
                                        class="ms-3"
                                        variant="outlined"
                                        color="#24a8cb"
                                        prepend-icon="mdi-email-fast-outline"
                                        v-if="user && item.authors[0] && item.authors[0].id !== user.id"
                                        :disabled="newMessageDialog"
                                        @click="newMessageDialog = true;"
                                    >
                                        {{ $t("pages.discussion.sendPrivateMessage") }}
                                    </v-btn>
           
                                    <!-- New Message -->
                                    <NewPrivateMessage :show="newMessageDialog" :recipient="item.authors[0].id" @message-sent="newMessageDialog = false;" @close="newMessageDialog = false;" />

                                    <v-spacer></v-spacer>

                                    <v-tooltip :text="$t('pages.discussion.editDiscussion')" v-if="user && item.authors[0] && item.authors[0].id === user.id">
                                        <template v-slot:activator="{ props }">
                                            <v-btn icon v-bind="props" @click="editingDiscussion = { ...item }; editDiscussionDialog = true">
                                                <v-icon>mdi-file-edit-outline</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-tooltip>

                                    <v-tooltip :text="$t('pages.discussion.toggleFavorite')">
                                        <template v-slot:activator="{ props }">
                                            <v-btn 
                                                icon 
                                                v-bind="props" 
                                                @click="toggleFavorite(item)" 
                                                :color="item.favorite ? 'red' : 'default'" 
                                                :loading="togglingFavorite" 
                                                :disabled="togglingFavorite"
                                            >
                                                <v-icon>{{ item.favorite ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-tooltip>
                                </v-toolbar>

                            </template>

                        </v-expansion-panel>
                    </v-expansion-panels>

                    <!-- Pagination -->
                    <v-pagination
                        rounded="circle"
                        v-if="totalDiscussions > limit"
                        v-model="page"
                        :length="Math.ceil(totalDiscussions / limit)"
                        :total-visible="5"
                        class="my-4"
                    ></v-pagination>

                    <!-- Empty state -->
                    <v-empty-state
                        class="my-5"
                        v-if="discussions.length === 0 && !loading"
                        icon="mdi-magnify"
                        :text="$t('pages.discussion.emptyStateText')"
                        :title="$t('pages.discussion.emptyStateTitle')"
                    >
                        <template v-slot:actions>
                            <v-btn color="primary" variant="flat" @click="searchQuery = ''; getDiscussions()">
                                {{ $t("actions.refresh") }}
                            </v-btn>
                        </template>
                    </v-empty-state>

                </div>
            </div>

            <!-- Ad banner -->
            <div class="generic-wrapper__space__right">
                <img
                    :src="require('@/assets/images/ad.jpg')"
                    alt="Ad banner"
                    class="position-sticky top-0 d-block w-100"
                />
            </div>

            <!-- Snackbars -->
            <div class="snackbars">
                <v-snackbar
                    :timeout="2000"
                    color="success"
                    variant="elevated"
                    v-model="newDiscussion.created"
                >
                    {{ $t("pages.discussion.newDiscussionCreated") }}
                </v-snackbar>

                <v-snackbar
                    :timeout="2000"
                    color="success"
                    variant="elevated"
                    v-model="discussionDeleted"
                >
                    {{ $t("pages.discussion.discussionDeleted") }}
                </v-snackbar>

                <v-snackbar
                    :timeout="2000"
                    color="success"
                    variant="elevated"
                    v-model="discussionEdited"
                >
                    {{ $t("pages.discussion.discussionUpdated") }}
                </v-snackbar>
            </div>

            <!-- Search -->
            <v-dialog v-model="searchDialog" max-width="600" persistent>
                <v-form ref="searchForm" @submit.prevent="searchDiscussions">
                    <v-card
                        prepend-icon="mdi-magnify"
                        :title="$t('pages.discussion.searchDiscussions')"
                    >
                        <v-card-text>
                            <v-row dense>
                                <v-col cols="12">
                                    <v-text-field
                                        :label="$t('pages.discussion.searchDiscussionsInput')"
                                        v-model="searchQuery"
                                        required
                                        autofocus
                                        :rules="[(v) => !!v || $t('pages.discussion.searchDiscussionsInputRequired')]"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>

                            <v-btn
                                :text="$t('actions.close')"
                                variant="flat"
                                @click="searchForm.reset(); searchDialog = false; searchQuery = '';"
                            ></v-btn>

                            <v-btn
                                color="primary"
                                :text="$t('actions.search')"
                                variant="flat"
                                :loading="searching || loading"
                                :disabled="searching || loading"
                                type="submit"
                            ></v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-dialog>

            <!-- Edit Discussion -->
            <v-dialog v-model="editDiscussionDialog" max-width="600">
                <v-form ref="editDiscussionForm" @submit.prevent="editDiscussion" v-if="editingDiscussion">
                    <v-card
                        prepend-icon="mdi-file-edit-outline"
                        :title="$t('pages.discussion.editDiscussion')"
                    >
                        <v-card-text>
                            <v-row dense>
                                <v-col cols="12">
                                    <v-text-field
                                        :label="$t('pages.discussion.discussionSubject')"
                                        v-model="editingDiscussion.title"
                                        :rules="[(v) => !!v || $t('pages.discussion.discussionSubjectRequired')]"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="12">
                                    <v-textarea
                                        :label="$t('pages.discussion.discussionText')"
                                        v-model="editingDiscussion.discussionText"
                                        :rules="[(v) => !!v || $t('pages.discussion.discussionTextRequired')]"
                                        autofocus
                                    ></v-textarea>
                                    <v-divider class="mt-4"></v-divider>
                                </v-col>
                            </v-row>

                                
                        </v-card-text>


                        <v-card-actions>
                            <v-spacer></v-spacer>

                            <v-btn
                                :text="$t('actions.close')"
                                variant="flat"
                                @click="editDiscussionDialog = false"
                            ></v-btn>

                            <v-btn
                                color="primary"
                                :text="$t('actions.save')"
                                variant="flat"
                                :loading="editingDiscussion.editing"
                                :disabled="editingDiscussion.editing"
                                type="submit"
                            ></v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-dialog>

            <!-- Confirm Delete Discussion -->
            <v-dialog v-model="confirmDeletingDiscussion" max-width="500">
                <v-card>
                    <v-card-title class="headline">
                        {{ $t("pages.discussion.confirmDeleteTitle") }}
                    </v-card-title>
                    <v-card-text>
                        {{ $t("pages.discussion.confirmDeleteText") }}
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            variant="flat"
                            @click="confirmDeletingDiscussion = false"
                        >
                            {{ $t("actions.cancel") }}
                        </v-btn>
                        <v-btn
                            color="red"
                            variant="flat"
                            @click="deleteDiscussion(deletingDiscussion)"
                        >
                            {{ $t("actions.delete") }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>


<script setup>
// @component { name: 'UserDiscussion' }

import { ref, watch, computed, reactive } from "vue";
import { useStore } from "vuex";
import { axiosInstance } from "@/plugins/axiosPlugin";
import { gql } from "graphql-request";
import { graphqlClient } from "@graphql/client";
import DiscussionReplies from "@/components/pages/discussion/DiscussionReplies.vue";
import NewPrivateMessage from "@/components/modules/NewPrivateMessage.vue";
import { clearNotifications } from '@/utils/clearNotifications';

const store = useStore();

const site = computed(() => store.state.site);
const loading = ref(false);
const form = ref(null);
const searchForm = ref(null);
const editDiscussionForm = ref(null);
const formSubmitted = ref(false);
const discussionDeleted = ref(false);
const searchQuery = ref("");
const searchDialog = ref(false);
const searching = ref(false);
const page = ref(1);
const limit = ref(50);
const totalDiscussions = ref(0);
const hasNextPage = ref(false);
const hasPreviousPage = ref(false);
const editDiscussionDialog = ref(false);
const editingDiscussion = ref(null);
const discussionEdited = ref(false);
const confirmDeletingDiscussion = ref(false);
const togglingFavorite = ref(false);
const showFavorites = ref(false);
const showMyDiscussions = ref(false);
const newMessageDialog = ref(false);
const deletingDiscussion = ref(null);

const selectedSitesObject = reactive({});
const defaultSites = store.state.sites.filter(site => site.language === "en" || site.language === store.state.site.language);
defaultSites.forEach((site) => {
    selectedSitesObject[site.value] = site.value;
});

const selectedSites = computed({
    get() {
        return Object.keys(selectedSitesObject).filter(key => selectedSitesObject[key]);
    },
    set(newValue) {
        Object.keys(selectedSitesObject).forEach(key => {
            selectedSitesObject[key] = newValue.includes(key);
        });
    }
});

const currentSite = computed(() => store.state.site);
const user = computed(() => store.state.user);

const newDiscussion = reactive({
    title: "",
    discussionText: "",
    siteId: currentSite.value.value,
    authorId: user.value.id,
    creating: false,
    created: false,
});

const discussions = ref([]);
const favoriteDiscussions = ref([]);

watch(site, (newValue, oldValue) => {
    if (newValue !== oldValue) {
        getDiscussions();
    }
});

watch(favoriteDiscussions, (newValue, oldValue) => {
    if (newValue !== oldValue) {
        discussions.value.forEach((discussion) => {
            discussion.favorite = newValue.some(
                (fav) => fav.id === discussion.id
            );
        });
    }
});

watch(page, (newValue, oldValue) => {
    if (newValue !== oldValue) {
        getDiscussions();
    }
});

watch(selectedSites, (newValue, oldValue) => {
    if (newValue !== oldValue) {
        getDiscussions();
    }
});

async function editDiscussion() {
    const { valid } = await editDiscussionForm.value.validate();

    if (!valid || formSubmitted.value) {
        return;
    }

    discussionEdited.value = false;
    editingDiscussion.value.editing = true;

    try {
        const response = await axiosInstance.post("discussion/update", {
            siteId: currentSite.value.value,
            ...editingDiscussion.value,
        });
        const { success, errors } = response.data;

        if (success) {
            editDiscussionDialog.value = false;
            editingDiscussion.value = null;
            discussionEdited.value = true;

            getDiscussions();
        } else {
            console.log(errors);
        }

    } catch (error) {
        console.log(error);
        editingDiscussion.value.editing = false;
    }
}

async function deleteDiscussion(discussion) {
    discussion.deleting = true;

    try {
        const response = await axiosInstance.post("discussion/delete", {
            discussionId: discussion.id,
        });

        const { success, errors } = response.data;

        if (success) {
            discussionDeleted.value = true;
        } else {
            console.log(errors);
        }
    } catch (error) {
        console.log(error);
    } finally {
        discussion.deleting = false;
        getDiscussions();
        confirmDeletingDiscussion.value = false;
    }
}

async function confirmDeleteDiscussion(discussion) {
    deletingDiscussion.value = discussion;
    confirmDeletingDiscussion.value = true;
}

async function toggleFavorite(discussion) {
    togglingFavorite.value = true;

    const isFavorite = favoriteDiscussions.value.some(
        (fav) => fav.id === discussion.id
    );

    try {
        const response = await axiosInstance.post("discussion/toggle-favorite", {
            discussionId: discussion.id,
            userId: user.value.id,
            isFavorite: !isFavorite,
        });
        const { success, errors } = response.data;

        if (success) {
            await getUserFavoriteDiscussions();

        } else {
            console.log(errors);
        }

    } catch (error) {
        console.error("Error toggling favorite discussion:", error);
    } finally {
        togglingFavorite.value = false;
    }
}

async function getUserFavoriteDiscussions() {
    const query = gql`
        query GetUserData($id: [QueryArgument]!) {
            user(id: $id) {
                ... on User {
                    id
                    favoriteDiscussions(siteId: "*") {
                        id
                    }
                }
            }
        }
    `;

    const variables = {
        id: user.value.id,
    };

    try {
        const response = await graphqlClient.request(query, variables);
        favoriteDiscussions.value = response.user.favoriteDiscussions;

        console.log('response.user.favoriteDiscussions :>> ', response.user.favoriteDiscussions);

    } catch (error) {
        console.error("Error fetching favorite discussions:", error);
    }
}

async function searchDiscussions() {
    const { valid } = await searchForm.value.validate();

    if (!valid) {
        return;
    }

    searching.value = true;
    await getDiscussions(searchQuery.value);
    searching.value = false;
    searchDialog.value = false;
}

async function getEntryCount(searchTitle = "", siteId = [], authorId = null, showFavorites = false) {
    const query = gql`
        query GetEntryCount($searchTitle: String, $siteId: [QueryArgument], $authorId: [QueryArgument], $relatedToUsers: [UserCriteriaInput]) {
            entryCount(
                section: "discussions",
                search: $searchTitle,
                status: "enabled",
                siteId: $siteId,
                authorId: $authorId,
                relatedToUsers: $relatedToUsers
            )
        }
    `;

    const variables = {
        searchTitle: searchTitle ? `*${searchTitle}*` : "",
        siteId: siteId,
        authorId: authorId,
        relatedToUsers: showFavorites ? [{ id: user.value.id }] : null,
    };

    const response = await graphqlClient.request(query, variables);
    return response.entryCount;
}

async function getDiscussions(searchTitle = "") {
    loading.value = true;
    discussions.value = [];
    totalDiscussions.value = 0;

    const totalDiscussionsCount = await getEntryCount(
        searchTitle,
        selectedSites.value,
        showMyDiscussions.value ? user.value.id : null,
        showFavorites.value
    );

    const query = gql`
        query GetPaginatedEntries($limit: Int!, $offset: Int!, $searchTitle: String, $siteId: [QueryArgument], $authorId: [QueryArgument], $relatedToUsers: [UserCriteriaInput]) {
            entries(limit: $limit, offset: $offset, search: $searchTitle, status: "enabled", section: "discussions", siteId: $siteId, authorId: $authorId, relatedToUsers: $relatedToUsers) {
                dateCreated
                authors {
                    photo {
                        url(transform: "customUserAvatar")
                    }
                    fullName
                    id
                    email
                }
                ... on discussion_Entry {
                    id
                    title
                    discussionText
                    siteId
                }
            }
        }
    `;

    const variables = {
        limit: limit.value,
        offset: (page.value - 1) * limit.value,
        searchTitle: searchTitle ? `*${searchTitle}*` : "",
        siteId: selectedSites.value,
        authorId: showMyDiscussions.value ? user.value.id : null,
        relatedToUsers: showFavorites.value ? [{ id: user.value.id }] : null,
    };

    try {
        const response = await graphqlClient.request(query, variables);
        discussions.value = response.entries.map((item) => ({
            ...item,
            replying: false,
            replyText: "",
            favorite: false,
            deleting: false,
        }));
        totalDiscussions.value = totalDiscussionsCount;
        hasNextPage.value = (page.value * limit.value) < totalDiscussions.value;
        hasPreviousPage.value = page.value > 1;

        getUserFavoriteDiscussions();
        
    } catch (error) {
        console.error("Error fetching discussions:", error);

    } finally {
        loading.value = false;
    }
}

getDiscussions();

async function createDiscussion() {
    const { valid } = await form.value.validate();

    if (!valid || formSubmitted.value) {
        return;
    }

    newDiscussion.creating = true;
    newDiscussion.created = false;
    newDiscussion.siteId = currentSite.value.value;
    formSubmitted.value = true;

    try {
        const response = await axiosInstance.post("discussion/create", {
            ...newDiscussion,
        });
        const { success, errors } = response.data;

        if (success) {
            formSubmitted.value = false;
            form.value.reset();

            Object.assign(newDiscussion, {
                title: "",
                discussionText: "",
                siteId: currentSite.value.value,
                authorId: user.value.id,
                creating: false,
                created: true,
            });

            setTimeout(() => {
                newDiscussion.created = false;
            }, 2000);

            getDiscussions();
        } else {
            console.log(errors);
        }
    } catch (error) {
        console.log(error);
    } finally {
        newDiscussion.creating = false;
    }
}

clearNotifications("newDiscussion");

</script>

<style lang="scss" scoped>
.discussion-info-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    margin-bottom: 8px;
    padding-top: 4px;
    min-height: 50px;

    .image-wrapper {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .discussion-info {
        .discussion-author {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 4px;
        }

        .discussion-title {
            font-size: 17px;
            font-family: "Montserrat", sans-serif;
        }
    }
}

.discussions-list {
    margin-top: 70px;
}

.actions-and-reply {
    .reply-form {
    }

    .reply-actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 12px;
    }
}
</style>
