<template>

    <div class="generic-wrapper">

        <div class="generic-wrapper__header">
            <h1>{{ $t('pages.users.list.title') }}</h1>
        </div>


        <div class="generic-wrapper__space">


            <v-card flat>
                <v-card-title class="d-flex align-center pe-2">

                    <v-text-field
                        v-model="search"
                        density="compact"
                        :label="$t('actions.search')"
                        prepend-inner-icon="mdi-magnify"
                        variant="solo-filled"
                        flat
                        hide-details
                        single-line
                    ></v-text-field>
                    <v-spacer></v-spacer>
                </v-card-title>

            </v-card>

            <v-divider></v-divider>

            <v-data-table
                :items="users"
                :search="search"
                :headers="headers"
                v-model="selected"
                return-object
            >

                <!-- Progress -->
                <template v-slot:top>
                    <v-progress-linear
                        v-if="loading"
                        color="#24a8cb"
                        height="6"
                        indeterminate
                        rounded
                    ></v-progress-linear>
                </template>

                <!-- Item dateCreated -->
                <template #[`item.dateCreated`]="{ item }">
                    {{ $filters.date(item.dateCreated) }}
                </template>

                <!-- Item avatar -->
                <template #[`item.avatar`]="{ item }">
                    <div class="user-avatar">
                        <img :src="item.avatar" alt="avatar">
                    </div>
                </template>

                <!-- Item fullName -->
                <template #[`item.fullName`]="{ item }">
                    <router-link :to="{ name: 'UserProfile', params: { id: item.id } }" class="text-primary d-none">{{ item.fullName }}</router-link>
                    {{ item.fullName }}
                </template>

                <!-- Item email -->
                <template #[`item.email`]="{ item }">
                    <a :href="`mailto:${item.email}`" class="text-primary">{{ item.email }}</a>
                </template>

                 <!-- Item language -->
                <template #[`item.userSelectedLanguage`]="{ item }">
                    <span>{{ item.userSelectedLanguage }}</span>
                </template>

                <!-- Item status -->
                <template #[`item.status`]="{ item }">
                    <v-icon :color="item.status === 'active' ? 'green' : 'red'">{{ item.status === 'active' ? 'mdi-check-circle' : 'mdi-close-circle' }}</v-icon>
                </template>

                <!-- Item actions -->
                <template #[`item.actions`]="{ item }">

                    <v-menu>
                        <template v-slot:activator="{ props }">
                            <v-btn icon="mdi-dots-vertical" v-bind="props"></v-btn>
                        </template>

                        <v-list>
                            <v-list-item @click="editItem(item)">
                                <v-list-item-title>
                                    <v-icon class="me-2" size="small">mdi-pencil</v-icon>
                                    {{ $t("actions.edit") }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="confirmDelete(item)" v-if="user && user.userType === 'admin' && item.userType !== 'admin'">
                                <v-list-item-title>
                                    <v-icon class="me-2" size="small">mdi-delete</v-icon>
                                    {{ $t("actions.delete") }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>

            </v-data-table>

            <!-- Delete confirmation dialog -->
            <v-dialog v-model="showDeleteConfirmationBanner" max-width="400" persistent>
                <v-card
                    prepend-icon="mdi-delete"
                    :title="$t('actions.confirmDelete')"
                >
                    <template v-slot:text>
                        <p v-if="deletingItem !== null">
                            {{ $t('pages.users.list.deleteConfirmation') }}
                            <br>
                            <strong style="margin-top: 10px;display: block;">{{ deletingItem.fullName }}</strong>
                        </p>

                        <v-list v-else density="compact">
                            <v-list-subheader>
                                {{ $t('pages.users.list.deleteConfirmationMultiple') }}
                            </v-list-subheader>

                            <v-list-item
                                v-for="(item, i) in selected"
                                :key="i"
                                :value="item"
                                color="primary"
                            >
                                <v-list-item-title><strong v-text="item.fullName"></strong></v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </template>

                    <template v-slot:actions>
                        <v-spacer></v-spacer>

                        <v-btn @click="showDeleteConfirmationBanner = false" variant="flat" color="default">
                            {{ $t("actions.cancel") }}
                        </v-btn>

                        <v-btn @click="deleteItem" variant="flat" color="red">
                            {{ $t("actions.delete") }}
                        </v-btn>
                    </template>
                </v-card>
            </v-dialog>

            <!-- Snackbar -->
            <v-snackbar v-model="userDeletedSuccessfully" color="success">
                {{ $t("pages.users.list.userDeletedSuccessfully") }}
            </v-snackbar>
        </div>
    </div>

</template>

<script setup>
import { ref, onMounted, computed, watch } from "vue";
import { gql } from "graphql-request";
import { graphqlClient } from "@graphql/client";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useStore } from 'vuex';
import { axiosInstance } from "@/plugins/axiosPlugin";

const store = useStore();
const { t } = useI18n();
const router = useRouter();
const users = ref([]);
const showDeleteConfirmationBanner = ref(false);
const userDeletedSuccessfully = ref(false);
const deletingItem = ref(null);
const headers = computed(() => [
    { 
        title: t("dataTable.userAvatar"), 
        key: "avatar",
        align: "center",
        sortable: false,
    },
    { 
        title: t("dataTable.name"), 
        key: "fullName",
    },
    { 
        title: t("dataTable.email"), 
        key: "email",
        sortable: false,
    },
    { 
        title: t("dataTable.language"), 
        key: "language",
    },
    { 
        title: t("dataTable.active"), 
        key: "status",
        align: "center",
        sortable: false,
    },
    { 
        title: t("dataTable.dateRegistered"), 
        key: "dateCreated",
        align: "center",
    },
    {
        title: t("dataTable.actions"),
        key: "actions",
        align: "center",
        sortable: false,
    },
]);
const selected = ref([]);
const loading = ref(false);
const search = ref('');
const site = computed(() => store.state.site);
const user = computed(() => store.state.user);

watch(site, () => {
    fetchUsers();
});

async function fetchUsers() {
    loading.value = true;

    const query = gql`
        query FetchUsers {
            users(orderBy: "dateCreated desc") {
                dateCreated
                fullName
                id
                email
                preferences
                status
                ... on User {
                    id
                    userSelectedLanguage
                    userType
                    photo {
                        ... on userAvatars_Asset {
                            id
                            url(transform: "customUserAvatar")
                        }
                    }
                }
                
            }
        }
    `;

    try {
        const response = await graphqlClient.request(query);
        console.log('response.users :>> ', response.users);

        users.value = response.users.map((item) => {

            return {
                id: item.id,
                dateCreated: item.dateCreated,
                fullName: item.fullName,
                email: item.email,
                status: item.status,
                language: item.userSelectedLanguage,
                avatar: item.photo.url,
                userType: item.userType,
            }
        });

        console.log('users.value :>> ', users.value);

    } catch (error) {
        console.error("Error fetching games:", error);
    } finally {
        loading.value = false;
    }
}

function confirmDelete(item) {
    if (item.id) {
        selected.value = [];
        deletingItem.value = item;

    } else {
        deletingItem.value = null;
    }
    
    showDeleteConfirmationBanner.value = true;
}

function editItem(item) {
    router.push(`/users/${item.id}/edit`);
}

async function deleteItem() {
    console.log('deletingItem :>> ', deletingItem.value);

    try {
        const response = await axiosInstance.delete(`user/delete`, { data: { userId: deletingItem.value.id } });
        const { success, errors } = response.data;

        if (success) {
            userDeletedSuccessfully.value = true;
            users.value = [];
            fetchUsers();

        } else {
            console.log(errors);
        }

    } catch (error) {
        console.log(error);

    } finally {
        selected.value = [];
        deletingItem.value = null;
        showDeleteConfirmationBanner.value = false;
    }
}

onMounted(() => {
    fetchUsers();
});
</script>

<style lang="scss" scoped>
.user-avatar {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    overflow: hidden;
    margin: 12px auto;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
    }
}
</style>
