<template>
    <UserProfileSettings :userId="props.userId" />
</template>

<script setup>
import { defineProps } from "vue";
import UserProfileSettings from "@/components/pages/users/UserProfileSettings.vue";

const props = defineProps({
    userId: {
        type: String,
        required: true,
    },
});

</script>