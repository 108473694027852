<template>
    <div class="auth-wrapper">
        <div class="auth-form reset-password-form">
            <v-form ref="form" @submit.prevent="resetPassword" autocomplete="on" name="takatuka-admin-reset-password">
                <h1>{{ $t("auth.forgottenPassword") }}</h1>

                <v-text-field
                    v-if="!passwordResetLinkSent"
                    :label="$t('auth.yourEmail')"
                    placeholder="johndoe@gmail.com"
                    v-model="email"
                    :rules="emailRules"
                    type="email"
                    autocomplete="on"
                    name="email"
                    required
                ></v-text-field>

                <div class="form-actions" v-if="!passwordResetLinkSent">
                     <v-fade-transition mode="out-in">
                        <v-btn
                            :color="'primary'"
                            :text="$t('auth.sendResetLink')"
                            class="text-none"
                            variant="flat"
                            type="submit"
                            :loading="formSubmitted"
                            :disabled="formSubmitted"
                            flat
                        ></v-btn>
                    </v-fade-transition>
                </div>

                <div class="password-reset-message" v-if="passwordResetLinkSent">
                     <v-alert
                        type="success"
                        closable
                        >
                        {{ $t("auth.passwordResetLinkSent") }}
                    </v-alert>
                </div>

                <div class="extra-actions">
                    <p class="mt-4">{{ $t("auth.dontHaveAccount") }} <br><router-link :to="{ name: 'UserRegister' }" class="text-primary">{{ $t("auth.signUp") }}</router-link></p>
                </div>
            </v-form>
        </div>

        <v-dialog v-model="dialog" max-width="500" :class="['reset-password-failed-dialog']">
            <template v-slot:default>
                <v-card :title="$t('auth.resetPasswordFailed')">
                    <v-card-text>{{ $t("auth.resetPasswordFailedMessage") }}</v-card-text>

                    <v-card-actions>
                        <v-btn color="primary" @click="dialog = false">{{ $t("actions.close") }}</v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>

    </div>
</template>


<script setup>
// @component { name: 'UserForgotPassword' }

import { useStore } from "vuex";
import { ref } from "vue";
import { axiosInstance } from '@/plugins/axiosPlugin';

const store = useStore();

const email = ref("");
const form = ref(false);
const dialog = ref(false);
const formSubmitted = ref(false);
const passwordResetLinkSent = ref(false);

const emailRules = [
    (v) => !!v || "Email is required",
    (v) => /.+@.+\..+/.test(v) || "Email must be valid",
];


async function resetPassword() {
    const { valid } = await form.value.validate();

    if (!valid || formSubmitted.value) {
        return;
    }

    formSubmitted.value = true;

    try {
        const response = await axiosInstance.post('user/reset-password', {
                siteId: store.state.site.value,
                user: {
                    email: email.value
                }
            }
        );
        const { success } = response.data;

        if(!success) {
            dialog.value = true;
            return;
        }

        passwordResetLinkSent.value = true;

    } catch (error) {
        console.error("Error registering:", error);
        dialog.value = true;

    } finally {
        formSubmitted.value = false;
    }
}

</script>

<style lang="scss">
</style>