<template>

    <p class="my-4 pt-2">
        <v-icon class="mr-2">mdi-comment-text-multiple</v-icon>
        {{ t("pages.discussion.replies") }}
        <span v-if="!loading" class="ml-1">({{ discussionReplies.length }})</span>
    </p>

    <v-progress-linear
        v-if="loading"
        color="#24a8cb"
        height="6"
        indeterminate
        rounded
    ></v-progress-linear>

    <div class="discussion-replies" v-if="userId">
        <div class="discussion-reply mb-5" v-for="reply in discussionReplies" :key="reply.id">
            <div class="discussion-info-wrapper mb-0" v-if="reply.authors[0]">
                <div class="reply-meta">
                    <div class="image-wrapper">
                        <img
                            :src="reply.authors[0].photo.url"
                            alt="User"
                            class="d-block w-100"
                        />
                    </div>
                    <div>
                        <p><strong>{{ reply.authors[0].fullName }}</strong> <span>{{ t("pages.discussion.on") + " " + $filters.date(reply.dateCreated, "DD.MM.YYYY HH:mm") }}</span></p>
                        <div class="discussion-reply-subtitle" v-html="$filters.smileys(reply.comment)"></div>
                    </div>
                </div>
                <div class="reply-actions">
                    <v-btn 
                        v-if="(reply.authors[0] && reply.authors[0].id === userId.value) || userType === 'admin'" 
                        icon 
                        @click="deletingReply = reply; confirmDeletingReply = true;"
                        :loading="deletingReply === reply"
                        :disabled="deletingReply === reply"
                    >
                        <v-icon color="red">mdi-close-circle</v-icon>
                    </v-btn>
                </div>
            </div>
        </div>
    </div>
    
    <!-- Reply form -->
    <div class="actions-and-reply">
        <v-form
            ref="replyForm"
            @submit.prevent="createReply"
        >
            <div class="reply-form">
                <v-textarea
                    :label="$t('pages.discussion.reply')"
                    variant="filled"
                    auto-grow
                    v-model="discussionItem.replyText"
                ></v-textarea>

                <div class="reply-actions d-flex justify-space-between align-center">

                    <div class="discussion-info-wrapper mb-0" v-if="store.state.user">
                        <div class="image-wrapper">
                            <img
                                :src="store.state.user.photo.url"
                                alt="User"
                                class="d-block w-100"
                            />
                        </div>
                        <strong>{{ store.state.user.fullName }}</strong>
                    </div>

                    <v-btn
                        variant="flat"
                        color="primary"
                        type="submit"
                        :loading="discussionItem.replying"
                        :disabled="discussionItem.replying"
                    >
                        {{ $t("actions.comment") }}
                    </v-btn>
                </div>
            </div>
        </v-form>

        <v-dialog dialog max-width="500" v-model="confirmDeletingReply">
            <template v-slot:default>
                <v-card :title="t('pages.discussion.confirmDeletingReply')">
                    <v-card-text>
                        {{ t("pages.discussion.deleteReplyAreYouSure") }}
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                            :text="t('actions.cancel')"
                            variant="flat"
                            @click="deletingReply = null; confirmDeletingReply = false"
                        ></v-btn>

                        <v-btn
                            :text="t('actions.delete')"
                            variant="flat"
                            color="red"
                            @click="deleteReply"
                        ></v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>

        <v-snackbar
            :timeout="2000"
            color="success"
            variant="elevated"
            v-model="replyCreated"
        >
            {{ $t("pages.discussion.replyCreated") }}
        </v-snackbar>
    </div>

</template>

<script setup>
// @component { name: 'UserDiscussion' }

import { defineProps, ref, computed } from "vue";
import { useStore } from "vuex";
import { gql } from "graphql-request";
import { graphqlClient } from "@graphql/client";
import { useI18n } from "vue-i18n";
import { axiosInstance } from "@/plugins/axiosPlugin";

const store = useStore();

const { t } = useI18n();
const props = defineProps({
    discussion: {
        type: Object,
        required: true,
    },
});

const discussionItem = ref(props.discussion);
const replyForm = ref(null);
const loading = ref(false);
const discussionReplies = ref([]);
const replyCreated = ref(false);
const confirmDeletingReply = ref(false);
const deletingReply = ref(null);
const userId = computed(() => store.state.user ? store.state.user.id : null);
const userType = computed(() => store.state.user ? store.state.user.userType : null);

async function deleteReply() {
    confirmDeletingReply.value = false;

    try {
        const response = await axiosInstance.post("discussion/delete-reply", {
            authorId: userId.value,
            replyId: deletingReply.value.id,
        });

        const { success, errors } = response.data;

        if (success) {
            getDiscussionReplies();
            
        } else {
            console.log(errors);
        }

    } catch (error) {
        console.log(error);
    }
}


async function createReply() {
    discussionItem.value.replying = true;

    try {
        const response = await axiosInstance.post("discussion/reply", {
            discussionId: discussionItem.value.id,
            authorId: userId.value,
            replyText: discussionItem.value.replyText,
        });

        const { success, errors } = response.data;

        if (success) {
            replyCreated.value = true;
            discussionItem.value.replyText = "";
            getDiscussionReplies();
            
        } else {
            console.log(errors);
        }
    } catch (error) {
        console.log(error);

    } finally {
        discussionItem.value.replying = false;
    }
}

async function getDiscussionReplies() {
    loading.value = true;

    const query = gql`
        query GetDiscussionReplies($discussionId: [QueryArgument]!) {
            entries(
                section: "comments"
                relatedTo: $discussionId
                orderBy: "dateCreated asc"
                siteId: ["*"]
            ) {
                ... on discussionComment_Entry {
                    id
                    comment
                    dateCreated
                    authors {
                        id
                        fullName
                        photo {
                            id
                            url(transform: "customUserAvatar")
                        }
                    }
                }
            }
        }
    `;

    const variables = {
        discussionId: [discussionItem.value.id],
    };

    try {
        const response = await graphqlClient.request(query, variables);
        discussionReplies.value = response.entries;

        console.log("discussionReplies.value :>> ", discussionReplies.value);

        loading.value = false;
    } catch (error) {
        console.error("Error fetching discussion replies:", error);
    }
}

getDiscussionReplies();
</script>

<style lang="scss" scoped>

.discussion-info-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    margin-bottom: 8px;
    padding-top: 4px;
    min-height: 50px;

    .reply-meta {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 12px;

        .image-wrapper {
            position: relative;
            top: 2px;
        }
    }

    .image-wrapper {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .discussion-info {
        
        .discussion-author {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 4px;
        }

        .discussion-title {
            font-size: 17px;
            font-family: 'Montserrat', sans-serif;
        }
    }
}
</style>
