<template>
    <v-expansion-panel class="mb-4 faq-panels">
        <v-expansion-panel-title>
            {{ props.category.title }}
        </v-expansion-panel-title>
        <v-expansion-panel-text>
            <div v-if="props.category.children.length > 0">
                <v-expansion-panels class="my-4" variant="inset">
                    <CategoryExpansionPanel
                        v-for="category in props.category.children"
                        :key="category.id"
                        :category="category"
                    />
                </v-expansion-panels>
            </div>
            <CategoryItems :category="props.category" />
        </v-expansion-panel-text>
    </v-expansion-panel>
</template>

<script setup>
import { defineProps } from 'vue';
import CategoryItems from '@/components/pages/help/CategoryItems.vue';

const props = defineProps({
    category: {
        type: Object,
        required: true,
    },
});

</script>

<style lang="scss" scoped>
.faq-panels {
    .v-expansion-panel-title {
        font-size: 18px;
        font-weight: 600;
    }
}
</style>