<template>
    <div class="generic-wrapper">

        <!-- Header -->
        <div class="generic-wrapper__header">
            <h1>{{ $t("pages.games.edit.title") }}</h1>
        </div>


        <!-- Loading game -->
        <v-progress-linear
            v-if="loadingGame"
            color="#24a8cb"
            height="6"
            indeterminate
            rounded
        ></v-progress-linear>


        <!-- Edit form -->
        <div class="generic-wrapper__content" v-if="clonedGame">

            <div class="content-header mb-5">
                <h2>
                    {{
                        $t("pages.games.gameTypes." + clonedGame.typeHandle)
                    }}
                </h2>
            </div>

            <v-form ref="gameForm" @submit.prevent="saveChanges">
                <div class="content-inner-wrapper">
                    <div class="games-content-wrapper">
                        
                        <GameEditText v-model="clonedGame" :disabled="gameUpdating" />

                        <v-divider class="max-divide"></v-divider>

                        <GameEditTips v-model="clonedGame" :disabled="gameUpdating" />

                        <v-divider class="max-divide"></v-divider>

                        <EditWheelFields v-model="clonedGame" :disabled="gameUpdating" v-if="clonedGame.typeHandle === 'wheel'" :formRef="gameForm" />

                        <EditMysteryItemFields v-model="clonedGame" :disabled="gameUpdating" v-if="clonedGame.typeHandle === 'mysteryItem'" :formRef="gameForm" />

                    </div>
                    <div class="games-settings-wrapper">
                        <div class="sticky-settings">
                            <div class="actions">
                                <v-btn
                                    :color="'default'"
                                    variant="tonal"
                                    :disabled="!gameEdited || gameUpdating"
                                    @click="discardDialog = true"
                                >
                                    {{ $t("actions.discard") }}
                                </v-btn>
                                <v-btn
                                    :color="'success'"
                                    variant="flat"
                                    :disabled="!gameEdited || gameUpdating"
                                    :loading="gameUpdating"
                                    class="generic-save-button"
                                    type="submit"
                                >
                                    {{ $t("actions.saveChanges") }}
                                </v-btn>
                            </div>

                            <GameEditSettings v-model="clonedGame" :disabled="gameUpdating" />
                        </div>
                    </div>
                </div>

                <v-divider class="max-divide"></v-divider>

                <div class="game-actions d-flex justify-space-between">
                    <div class="d-flex align-center actions-button-wrapper">
                        <v-btn
                            color="red"
                            variant="tonal"
                            :disabled="gameUpdating"
                            @click="deleteGameDialog = true"
                        >
                            {{ $t("pages.games.edit.deleteGame") }}
                        </v-btn>
                        <v-btn
                            variant="tonal"
                            :disabled="gameUpdating || duplicatingGame"
                            :loading="duplicatingGame"
                            @click="duplicateGameDialog = true"
                        >
                            {{ $t("pages.games.edit.duplicateGame") }}
                        </v-btn>
                    </div>
                    <v-spacer></v-spacer>
                    <div class="d-flex align-center actions-button-wrapper">
                        <v-btn
                            :color="'success'"
                            variant="flat"
                            :disabled="gameUpdating || !game.enabled"
                            @click="playGame"
                        >
                            {{ $t("actions.play") }}
                        </v-btn>
                        <v-btn
                            :color="'success'"
                            variant="flat"
                            :disabled="!gameEdited || gameUpdating"
                            :loading="gameUpdating"
                            type="submit"
                            class="generic-save-button"
                        >
                            {{ $t("actions.saveChanges") }}
                        </v-btn>
                    </div>
                </div>

            </v-form>
        </div>


        <!-- Discard changes dialog -->
        <v-dialog v-model="discardDialog" max-width="500" persistent>
            <v-card :title="$t('pages.games.edit.discardChanges')">
                <v-card-text>{{
                    $t("pages.games.edit.discardChangesConfirmation")
                }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        variant="tonal"
                        @click="discardDialog = false"
                    >
                        {{ $t("actions.cancel") }}
                    </v-btn>
                    <v-btn color="red" variant="flat" @click="discardChanges">{{
                        $t("actions.confirm")
                    }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <!-- Delete game dialog -->
        <v-dialog v-model="deleteGameDialog" max-width="500" persistent>
            <v-card :title="$t('pages.games.edit.deleteGame')">
                <v-card-text>{{ $t("pages.games.edit.deleteGameConfirmation") }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn variant="tonal" @click="deleteGameDialog = false">{{ $t("actions.cancel") }}</v-btn>
                    <v-btn color="red" variant="flat" @click="deleteGame">{{ $t("actions.confirm") }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <!-- Duplicate game dialog -->
        <v-dialog v-model="duplicateGameDialog" max-width="500" persistent>
            <v-card :title="$t('pages.games.edit.duplicateGame')">
                <v-card-text>{{ $t("pages.games.edit.duplicateGameConfirmation") }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn variant="tonal" @click="duplicateGameDialog = false">{{ $t("actions.cancel") }}</v-btn>
                    <v-btn color="success" variant="flat" @click="duplicateGame" :disabled="duplicatingGame" :loading="duplicatingGame">{{ $t("actions.confirm") }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <!-- Game updated successfully snackbar -->
        <v-snackbar v-model="gameUpdatedSuccessfully" :timeout="4000" color="success" variant="elevated">
            {{ $t("pages.games.edit.gameUpdated") }}
        </v-snackbar>


        <!-- Game duplicated successfully snackbar -->
        <v-snackbar v-model="gameDuplicatedSuccessfully" :timeout="4000" color="success" variant="elevated">
            {{ $t("pages.games.edit.gameDuplicated") }}
        </v-snackbar>


        <!-- Game deleted successfully snackbar -->
        <v-snackbar v-model="gameDeletedSuccessfully" :timeout="4000" color="success" variant="elevated">
            {{ $t("pages.games.edit.gameDeleted") }}
        </v-snackbar>


        <!-- Error dialog -->
        <v-dialog v-model="showError" max-width="500" persistent>
            <v-card :title="$t('validation.formValidationFailed')">
                <v-card-text>{{ $t("validation.formValidationFailedMessage") }}</v-card-text>
                <v-card-text>
                    <ul class="m-0 px-5">
                        <li v-for="error in errors" :key="error" class="mb-3">{{ error }}</li>
                    </ul>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="blue darken-1" text @click="showError = false">{{ $t("actions.close") }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script setup>
import { useStore } from "vuex";
import { ref, watch, onMounted, computed, getCurrentInstance } from "vue";
import { gql } from "graphql-request";
import { graphqlClient } from "@graphql/client";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';

import GameEditText from "@/components/pages/games/GameEditText.vue";
import GameEditSettings from "@/components/pages/games/GameEditSettings.vue";
import GameEditTips from "@/components/pages/games/GameEditTips.vue";
import EditWheelFields from "@/components/pages/games/wheel/EditWheelFields.vue";
import EditMysteryItemFields from "@/components/pages/games/mysteryItem/EditMysteryItemFields.vue";
import { axiosInstance } from "@/plugins/axiosPlugin";

const { t } = useI18n();
const router = useRouter();
const store = useStore();
const site = computed(() => store.state.site);
const sites = computed(() => store.state.sites);
const user = computed(() => store.state.user);
const { proxy } = getCurrentInstance();

const game = ref(null);
const gameForm = ref(null);
const clonedGame = ref(null);
const discardDialog = ref(false);
const loadingGame = ref(false);
const gameUpdating = ref(false);
const duplicatingGame = ref(false);
const deletingGame = ref(false);
const gameUpdatedSuccessfully = ref(false);
const gameDuplicatedSuccessfully = ref(false);
const gameDeletedSuccessfully = ref(false);
const errors = ref([]);
const showError = ref(false);
const gameEdited = ref(false);
const deleteGameDialog = ref(false);
const duplicateGameDialog = ref(false);

const duplicateGameTitlePostfix = computed(() => {
    const postfixes = {};
    sites.value.forEach(site => {
        postfixes[site.value] = t("pages.games.edit.duplicateGameTitlePostfix", {}, { locale: site.language });
    });
    return postfixes;
});

watch(
    site,
    () => {
        fetchGame(site.value.value);
    },
    { deep: true }
);

watch(
    clonedGame,
    () => {
        gameEdited.value = !isEqual({ ...game.value, siteId: undefined, refresh: undefined }, { ...clonedGame.value, siteId: undefined, refresh: undefined });
    },
    { deep: true }
);

async function fetchGame(siteId = null, gameId = null) {
    loadingGame.value = true;

    const query = gql`
        query FetchGame($siteId: [QueryArgument]!, $id: [QueryArgument]!) {
            entries(section: "games", siteId: $siteId, status: null, id: $id) {
                id
                title
                uri
                url
                slug
                typeHandle
                postDate
                siteId
                enabled
                ... on wheel_Entry {
                    id
                    publicGame
                    wheelShowLegend
                    shortDescription
                    learningGoals
                    instructions
                    gamePurpose
                    accessories
                    originalGameAuthor {
                        id
                        fullName
                        email
                        photo {
                            id
                            url(transform: "customUserAvatar")
                        }
                    }
                    gameCategory {
                        ... on gameCategory_Category {
                            id
                            gameTypeHandle
                            title
                        }
                    }
                    tips {
                        ... on tip_Entry {
                            id
                            tip
                            siteId
                        }
                    }
                    wheelFields {
                        ... on wheelField_Entry {
                            id
                            title
                            description
                            solution
                            wheelTextOnTheWheel
                            wheelBlockFieldAfterHitting
                            wheelDontStopOnThisField
                            wheelColor
                            wheelColorOfWheelFonts
                        }
                    }
                }
                ... on mysteryItem_Entry {
                    id
                    publicGame
                    shortDescription
                    learningGoals
                    instructions
                    gamePurpose
                    accessories
                    mysteryItemMainImage {
                        id
                        title
                        url(transform: "assetsLibraryThumbnail")
                    }
                    originalGameAuthor {
                        id
                        fullName
                        email
                        photo {
                            id
                            url(transform: "customUserAvatar")
                        }
                    }
                    gameCategory {
                        ... on gameCategory_Category {
                            id
                            gameTypeHandle
                            title
                        }
                    }
                    tips {
                        ... on tip_Entry {
                            id
                            tip
                            siteId
                        }
                    }
                    mysteryItemFields {
                        ... on mysteryItemField_Entry {
                            id
                            title
                            description
                            mysteryItemImage {
                                id
                                title
                                url(transform: "assetsLibraryThumbnail")
                            }
                        }
                    }
                }
                ... on shapes_Entry {
                    id
                }
                ... on writtenSources_Entry {
                    id
                }
            }
        }
    `;

    const variables = {
        siteId: siteId ? [siteId] : [site.value.value],
        id: gameId ? [gameId] : [router.currentRoute.value.params.id],
    };

    try {
        const response = await graphqlClient.request(query, variables);
        game.value = response.entries[0] ? response.entries[0] : null;
        clonedGame.value = cloneDeep(game.value);
        gameEdited.value = false;
        console.log("game :>> ", clonedGame.value);

    } catch (error) {
        console.error("Error fetching games:", error);
    } finally {
        loadingGame.value = false;
    }
}

async function saveChanges() {
    errors.value = [];

    // Validate the parent form
    if(!gameForm.value.validate()) {
        errors.value.push(t("validation.formValidationFailed"));
    }

    // Validate the title field
    if(clonedGame.value.title.trim() === "") {
        errors.value.push(t("validation.titleEmpty"));
    }

    // Validate the EditWheelFields component via its ref in the form
    if(clonedGame.value.wheelFields) {
        for(const field of clonedGame.value.wheelFields) {
            if(field.title === "") {
                errors.value.push(t("validation.fieldTitleEmpty"));
            }

            if(field.wheelTextOnTheWheel === "") {
                errors.value.push(t("validation.fieldTextOnTheWheelEmpty"));
            }
        }
    }

    if(clonedGame.value.mysteryItemFields) {
        for(const field of clonedGame.value.mysteryItemFields) {
            if(field.title === "") {
                errors.value.push(t("validation.fieldTitleEmpty"));
            }

            if(field.mysteryItemImage === null) {
                errors.value.push(t("validation.fieldMysteryItemImageEmpty"));
            }
        }
    }

    if(errors.value.length > 0) {
        showError.value = true;
        return;
    }

    gameUpdating.value = true;

    try {
        const response = await axiosInstance.post("games/update-game", {
            siteId: clonedGame.value.siteId,
            game: clonedGame.value,
        });
        const { success } = response.data;

        if(success) {
            await fetchGame(clonedGame.value.siteId);
            gameUpdatedSuccessfully.value = true;
            gameEdited.value = false;
        }

    } catch (error) {
        console.error("Error updating game:", error);
    } finally {
        gameUpdating.value = false;
    }
}

async function duplicateGame() {
    gameUpdating.value = true;
    duplicatingGame.value = true;
    
     try {
        const response = await axiosInstance.post("games/duplicate-game", {
            gameId: clonedGame.value.id,
            userId: user.value.id,
            titlePostfix: duplicateGameTitlePostfix.value ? duplicateGameTitlePostfix.value : t("pages.games.edit.duplicateGameTitlePostfix"),
        });
        const { success, gameId } = response.data;

        if(success) {
            router.push(`/game/${gameId}/edit`);
            await fetchGame(clonedGame.value.siteId, gameId);
            gameDuplicatedSuccessfully.value = true;
           
            setTimeout(() => {
                proxy.$scrollTo('body');
            }, 750);
        }

    } catch (error) {
        console.error("Error updating game:", error);
    } finally {
        duplicateGameDialog.value = false;
        gameUpdating.value = false;
        duplicatingGame.value = false;
    }
}

async function deleteGame() {
    gameUpdating.value = true;
    deletingGame.value = true;    

    try {
        const response = await axiosInstance.post("games/delete-game", {
            gameId: clonedGame.value.id
        });
        const { success } = response.data;

        if(success) {
            gameDeletedSuccessfully.value = true;
           
            setTimeout(() => {
                router.push(`/games`);
            }, 750);
        }

    } catch (error) {
        console.error("Error updating game:", error);
    } finally {
        deleteGameDialog.value = false;
        gameUpdating.value = false;
        deletingGame.value = false;
    }
}

function discardChanges() {
    clonedGame.value = cloneDeep(game.value);
    discardDialog.value = false;
    clonedGame.value.refresh = Date.now();
    gameEdited.value = false;
}

function playGame() {
    const gameUrl = clonedGame.value.url.replace(process.env.VUE_APP_CMS_BASE_URL, process.env.VUE_APP_GAMES_BASE_URL).replace(clonedGame.value.uri, (clonedGame.value.siteId === 1 ? 'en/' : '') + clonedGame.value.siteId + "/" + clonedGame.value.uri);
    window.open(gameUrl, '_blank');
}

onMounted(() => {
    fetchGame();
});

</script>

<style lang="scss" scoped>
@import "@/styles/pages/games/edit.scss";

.game-specific-content {
    margin-top: 60px;
}

.actions-button-wrapper {
    gap: 15px;
}
</style>
