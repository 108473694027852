<template>
    <div class="game-edit-tips-wrapper">
        <h3>{{ $t('pages.games.tips') }}</h3>
        <p class="text-muted mt-2">{{ $t('pages.games.tipsDescription') }}</p>

        <div class="tips-wrapper">
            <div class="tip mb-5" v-for="(tip, index) in game.tips" :key="tip.id">
                <div class="custom-label d-flex align-center justify-space-between">
                    {{ $t('pages.games.tip', { index: index + 1 }) }}

                    <v-btn variant="tonal" color="default" @click="removeTip(index)" :disabled="disabled"><v-icon>mdi-close</v-icon></v-btn>
                </div>

                <div class="tip-content">
                    <CKEditor :modelValue="tip.tip" @update:modelValue="tip.tip = $event" :key="game.siteId" :disabled="disabled" />
                </div>
            </div>

            <div class="actions mt-5" v-if="game.tips.length < 3">
                <v-btn variant="tonal" color="#1A5B6A" prepend-icon="mdi-plus" @click="addTip" :disabled="disabled">{{ $t('pages.games.addTip') }}</v-btn>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from "vue";
import CKEditor from "@/components/misc/CKEditor.vue";

const emit = defineEmits(["update:modelValue"]);
const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false
    }
});

const disabled = computed({
    get() {
        return props.disabled;
    }
});

const game = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit("update:modelValue", value);
    },
});

const addTip = () => {
    game.value.tips.push({
        tip: "",
    });
};

const removeTip = (index) => {
    game.value.tips.splice(index, 1);
};
</script>
