<template>
    <router-view />
</template>

<script setup>
import { useStore } from "vuex"; 
import { watch, computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useLocale } from 'vuetify'; 

const store = useStore();
const i18n = useI18n();
const { current: localeVuetify } = useLocale();
const currentSite = computed(() => store.state.site);

if (!currentSite.value) {
    const firstSite = store.state.sites[0];
    store.dispatch('setSite', firstSite);
    i18n.locale.value = firstSite.language;
    localeVuetify.value = firstSite.language;
} else {
    i18n.locale.value = currentSite.value.language;
    localeVuetify.value = currentSite.value.language;
}

watch(currentSite, (newVal) => {
    const selectedSiteObject = store.state.sites.find(site => site.value === newVal);

    if (selectedSiteObject) {
        store.dispatch('setSite', selectedSiteObject);
        i18n.locale.value = selectedSiteObject.language;
        localeVuetify.value = selectedSiteObject.language;
    }
});

onMounted(() => {
    document.title = "TakaTuka - Admin";
});
</script>

<style lang="scss">
@import "@/styles/global.scss";
</style>
